import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { PlannedAreaAnnotationTypes } from '../../interfaces/planned-area-annotation-types';
import { GapApiCommsService } from '../../services/gap-api-comms/gap-api-comms.service';

@Injectable({
  providedIn: 'root'
})
export class PlannedAreaAnnotationTypesResolverService
  implements Resolve<PlannedAreaAnnotationTypes[]>
{
  private plannedAreaAnnotationTypesSubject: BehaviorSubject<
    PlannedAreaAnnotationTypes[]
  >;

  constructor(private gapApiComms: GapApiCommsService) {
    this.plannedAreaAnnotationTypesSubject = new BehaviorSubject<
      PlannedAreaAnnotationTypes[]
    >(null);
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<PlannedAreaAnnotationTypes[]> {
    return this.gapApiComms.getPlannedAreaAnnotationTypes().pipe(
      tap(data => {
        this.plannedAreaAnnotationTypesSubject.next(data);
      }),
      catchError(error => {
        console.log(error);
        return new Observable<PlannedAreaAnnotationTypes[]>(null);
      })
    );
  }

  getSubject(): BehaviorSubject<PlannedAreaAnnotationTypes[]> {
    return this.plannedAreaAnnotationTypesSubject;
  }
}
