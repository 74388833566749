import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { MapMeasureToolsService } from '../../services/map-measure-tools/map-measure-tools.service';
import {
  MapMeasurement,
  MapRadiusMeasurementResult,
  MapTransportMeasurementResult
} from '../../interfaces/map-measurement';
import { MapObject } from '../../interfaces/map-object';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { AppRoutes } from '../../../core/enums/app-routes';
import { Router } from '@angular/router';
import { LineString, Polygon } from 'geojson';
import { MapMeasureMode } from '../../enums/map-measure-mode';
import { MapboxService } from '../../services/mapbox/mapbox.service';
import { HubCustomViewType } from '../../../hub/enums/hub-custom-view-type';
import { TransportTripModeClass } from '../../enums/transport-trip-mode-class';
import { MapMeasureTransportChartService } from '../../services/map-measure-transport-chart/map-measure-transport-chart.service';
import { TransportTripResult } from '../../interfaces/transport-trip-result';
import * as moment from 'moment';
import { SchoolDatesResolverService } from '../../../core/resolvers/school-dates-resolver/school-dates-resolver.service';
import { MapMeasureRouteObject } from '../../interfaces/map-measure-route-object';
import { environment } from '../../../../environments/environment';
import { HubRoutes } from '../../../hub/enums/hub-routes';
import { saveAs } from 'file-saver';
import { createDisplayCircle } from '../../services/mapbox/custom-modes/shared';
import { NotificationPanelService } from '../../services/notification-panel/notification-panel.service';
import { NotificationPopupType } from 'src/app/core/enums/notification-popup-type';

@Component({
  selector: 'ee-map-measure-panel',
  templateUrl: './map-measure-panel.component.html',
  styleUrls: ['./map-measure-panel.component.scss']
})
export class MapMeasurePanelComponent implements OnInit, OnChanges, OnDestroy {
  AppRoutesEnum = AppRoutes;
  HubRoutesEnum = HubRoutes;

  private chartTimeout = null;

  TransportTripModeClassEnum = TransportTripModeClass;
  MapMeasureModeEnum = MapMeasureMode;

  @Input() mapObject: MapObject;
  currentId: string = null;
  currentMeasurement: MapMeasurement = null;
  currentRadius = 0;
  visible = false;
  failed = false;
  initialised = false;
  selectedTabIndex = 0;
  radiusEditEnabled = false;
  showTransportChart = false;
  hasTransportOptionsChanged = false;
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  schoolDates: Date[] = null;
  transportDateFilter = (testDate: Date): boolean => {
    return (
      this.schoolDates &&
      testDate &&
      this.schoolDates.findIndex(
        schoolDate => schoolDate.toDateString() === testDate.toDateString()
      ) > -1
    );
  };

  constructor(
    private mapMeasureToolsService: MapMeasureToolsService,
    private mapMeasureTransportChartService: MapMeasureTransportChartService,
    private schoolDatesResolverService: SchoolDatesResolverService,
    private mapboxService: MapboxService,
    private router: Router,
    private notificationPanelService: NotificationPanelService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mapObject && this.mapObject && !this.initialised) {
      if (!this.mapObject.measurementObject) {
        this.onMeasurementReady();
      } else {
        this.onSchoolDatesReady();
        this.onMeasurePanelUpdate();
      }
      this.initialised = true;
    }
  }

  ngOnDestroy() {
    if (this.chartTimeout) {
      clearTimeout(this.chartTimeout);
    }

    this.mapMeasureToolsService.clearActiveMeasurement(this.mapObject);
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private onSchoolDatesReady() {
    this.schoolDatesResolverService
      .onSchoolDatesReady()
      .subscribe(schoolDates => {
        const today = moment().startOf('date');
        this.schoolDates = schoolDates;

        for (const date of this.schoolDates) {
          const d = moment(date);
          if (d.isSameOrAfter(today)) {
            this.mapObject.measurementObject.transportOptions.date = date;
            break;
          }
        }
      });
  }

  private onMeasurementReady() {
    this.mapObject.measurementReady.pipe(first()).subscribe(ready => {
      if (ready) {
        this.onMeasurePanelUpdate();
        this.onSchoolDatesReady();
      }
    });
  }

  private onMeasurePanelUpdate() {
    this.mapMeasureToolsService
      .onMeasurePanelUpdate(this.mapObject)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(e => {
        if (e) {
          this.visible = e.visible;
          this.currentMeasurement = e.measurement ? e.measurement : null;

          if (
            this.currentMeasurement &&
            this.currentMeasurement.mode === MapMeasureMode.MEASURE_RADIUS
          ) {
            this.currentRadius = this.currentMeasurement.result
              ? (this.currentMeasurement.result as MapRadiusMeasurementResult)
                  .radius
              : 0;
          }

          this.failed = e.hasOwnProperty('failed') ? e.failed : false;

          this.destroyTransportResultsChart();

          if (this.currentMeasurement) {
            // If current measurement has changed
            if (this.currentId !== this.currentMeasurement.id) {
              this.currentId = this.currentMeasurement.id;
              this.selectedTabIndex = 0;
              this.tabChanged({ index: 0 });
              this.radiusEditEnabled = false;
            }
            // Load transport chart for transport measurements
            if (
              this.currentMeasurement.mode ===
                MapMeasureMode.MEASURE_TRAVEL_TRANSPORT &&
              this.currentMeasurement.result &&
              (this.currentMeasurement.result as MapTransportMeasurementResult)
                .tripResult
            ) {
              this.loadTransportResultsChart(
                (
                  this.currentMeasurement
                    .result as MapTransportMeasurementResult
                ).tripResult
              );
            }
          } else {
            this.selectedTabIndex = 0;
            this.tabChanged({ index: 0 });
            this.radiusEditEnabled = false;
          }
        }
      });
  }

  private loadTransportResultsChart(result: TransportTripResult) {
    this.showTransportChart = true;
    this.chartTimeout = setTimeout(() => {
      this.mapMeasureTransportChartService.generateChart(
        'map-measure-transport-chart',
        result
      );
    });
  }

  private destroyTransportResultsChart() {
    this.mapMeasureTransportChartService.destroyChart();
    this.showTransportChart = false;
  }

  tabChanged(e: any) {
    this.mapMeasureToolsService.setMeasurePanelState(this.mapObject, e.index);
  }

  setRadius() {
    if (!this.radiusEditEnabled) {
      this.radiusEditEnabled = true;
    } else {
      this.mapMeasureToolsService.setRadius(this.mapObject, this.currentRadius);
      this.radiusEditEnabled = false;
    }
  }

  updateTransportOptions() {
    if (this.currentMeasurement && this.currentMeasurement.result) {
      this.hasTransportOptionsChanged = true;
    }
  }

  updateTransportMeasurement() {
    this.hasTransportOptionsChanged = false;
    this.mapMeasureToolsService.updateTransportMeasurement(this.mapObject);
  }

  getTitle(): string {
    let title = '';
    if (this.currentMeasurement) {
      switch (this.currentMeasurement.mode) {
        case MapMeasureMode.MEASURE_LINE:
          title = 'Line';
          break;
        case MapMeasureMode.MEASURE_POLYGON:
          title = 'Area';
          break;
        case MapMeasureMode.MEASURE_RADIUS:
          title = 'Radius';
          break;
        case MapMeasureMode.MEASURE_TRAVEL_WALKING:
          title = 'Walking';
          break;
        case MapMeasureMode.MEASURE_TRAVEL_CYCLING:
          title = 'Cycling';
          break;
        case MapMeasureMode.MEASURE_TRAVEL_DRIVING:
          title = 'Driving';
          break;
        case MapMeasureMode.MEASURE_TRAVEL_TRANSPORT:
          title = 'Transport';
          break;
      }
    }

    return title;
  }

  close() {
    this.mapMeasureToolsService.clearActiveMeasurement(this.mapObject);
  }

  delete() {
    this.mapMeasureToolsService.deleteActiveMeasurement(this.mapObject);
  }

  private getEncodedPolylineGeom(): MapMeasureRouteObject {
    let encodedPolyline = null;
    let encodedPolygon = null;
    let typeParam = null;

    if (this.currentMeasurement && this.currentMeasurement.geometry) {
      if (this.currentMeasurement.mode === MapMeasureMode.MEASURE_POLYGON) {
        encodedPolyline = this.mapboxService.convertPolygonToEncodedPolyline(
          this.currentMeasurement.geometry as Polygon
        );
        typeParam = HubCustomViewType.POLYGON;
        // if it is normal polygon, the encoded polygon is the same as encoded polyline
        encodedPolygon = encodedPolyline;
      } else {
        encodedPolyline = this.mapboxService.convertLineStringToEncodedPolyline(
          this.currentMeasurement.geometry as LineString
        );
        typeParam = HubCustomViewType.RADIUS;
        // if it is circle, the encoded polyline shows the diameter, and the encoded polygon is the real circle.
        // the full polygon geometry is too large to share across on url
        encodedPolygon = this.mapboxService.convertPolygonToEncodedPolyline(
          this.mapboxService.getCircleGeomFromRadiusEncodedPolyline(
            encodedPolyline
          )
        );
      }
    }

    return {
      encodedPolyline: encodedPolyline,
      encodedPolygon: encodedPolygon,
      viewType: typeParam
    };
  }

  private showCopiedNotificationPopup() {
    this.notificationPanelService.showNotification({
      type: NotificationPopupType.SUCCESS,
      title: 'Polygon Copied',
      message: `Encoded polygon copied to clipboard`
    });
  }

  analyseInHub() {
    const mapMeasureRouteObject = this.getEncodedPolylineGeom();

    if (mapMeasureRouteObject) {
      this.router.navigate(
        [
          AppRoutes.HUB,
          HubRoutes.CUSTOM,
          mapMeasureRouteObject.encodedPolyline
        ],
        {
          queryParams: {
            type: mapMeasureRouteObject.viewType
          }
        }
      );
    }
  }

  analyseInZhuri() {
    const mapMeasureRouteObject = this.getEncodedPolylineGeom();

    const url = `${environment.zhuriUrl}/?p=${encodeURIComponent(mapMeasureRouteObject.encodedPolyline)}&t=${encodeURIComponent(mapMeasureRouteObject.viewType)}`;

    if (mapMeasureRouteObject) {
      window.open(url, '_blank');
    }
  }

  addToGap() {
    const mapMeasureRouteObject = this.getEncodedPolylineGeom();

    if (mapMeasureRouteObject) {
      this.router.navigate([AppRoutes.GAP, 'new'], {
        queryParams: {
          geometry: mapMeasureRouteObject.encodedPolyline,
          type: mapMeasureRouteObject.viewType
        }
      });
    }
  }

  exportAsGeoJSON() {
    if (this.currentMeasurement && this.currentMeasurement.geometry) {
      const fileName =
        `EE-export - HUB - Custom Geometry` +
        ` - ${moment().format('YYYYMMDD')}.geojson`;

      const geoJson =
        this.currentMeasurement.mode == MapMeasureMode.MEASURE_RADIUS
          ? createDisplayCircle(
              {
                type: 'Feature',
                geometry: this.currentMeasurement.geometry
              },
              null
            )
          : {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: this.currentMeasurement.geometry,
                  properties: {}
                }
              ]
            };
      const blob = new Blob([JSON.stringify(geoJson)], {
        type: 'text/plain;charset=utf-8'
      });

      saveAs(blob, fileName);
    }
  }

  copyEncodedPolygon() {
    const mapMeasureRouteObject = this.getEncodedPolylineGeom();
    navigator.clipboard.writeText(mapMeasureRouteObject.encodedPolygon);
    this.showCopiedNotificationPopup();
  }
}
