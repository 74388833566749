import { Injectable } from '@angular/core';
import { MapObject } from '../../interfaces/map-object';
import { MapboxService } from '../mapbox/mapbox.service';

@Injectable({
  providedIn: 'root'
})
export class MapControlsService {
  constructor(private mapboxService: MapboxService) {}

  zoomIn(mapObject: MapObject) {
    this.mapboxService.zoomIn(mapObject);
  }

  zoomOut(mapObject: MapObject) {
    this.mapboxService.zoomOut(mapObject);
  }

  resetMapView(mapObject: MapObject) {
    this.mapboxService.resetMapView(mapObject);
  }
}
