<div #mapTooltip>
  <div class="tooltip" *ngIf="visible">
    <div
      [ngStyle]="{
        width: tooltipWidth + 'px',
        'margin-left': '-' + (tooltipWidth / 2 + 8) + 'px'
      }"
      class="tooltip-text"
    >
      <h4 class="tooltip-title" *ngIf="displayName">
        {{ displayName }}
      </h4>
      <div [innerHTML]="toolTipText | safeHtml" class="tooltip-content"></div>
    </div>
  </div>
</div>
