import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ee-make-up-mirror',
  templateUrl: './make-up-mirror.component.html',
  styleUrls: ['./make-up-mirror.component.scss']
})
export class MakeUpMirrorComponent implements OnInit, OnDestroy {
  @ViewChild('makeUpMirror', { static: true })
  makeUpMirror: ElementRef<HTMLVideoElement>;

  private streamObject: MediaStream = null;

  constructor(
    public dialogRef: MatDialogRef<MakeUpMirrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (
      navigator &&
      navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia
    ) {
      navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
        this.streamObject = stream;
        this.makeUpMirror.nativeElement.srcObject = stream;
      });
    }
  }

  ngOnDestroy() {
    if (this.streamObject) {
      this.streamObject.getTracks().forEach(track => track.stop());
      this.makeUpMirror.nativeElement.srcObject = null;
      this.streamObject = null;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
