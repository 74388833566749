<div class="map-panel" fxLayout="row" *ngIf="visible">
  <mat-tab-group class="measure-panel">
    <mat-tab label="Inspector">
      <div class="tab-container" [perfectScrollbar]>
        <!-- Coordinate data -->
        <div class="data" fxLayout="row">
          <div
            class="data-row"
            fxLayout="row"
            fxLayoutAlign="start center"
            *ngIf="georefResults?.coordinates"
          >
            <div class="label">Coordinates</div>
            <div class="values">
              <div>
                {{ georefResults.coordinates[1] | number: '1.4-4' }},
                {{ georefResults.coordinates[0] | number: '1.4-4' }}
              </div>
            </div>
          </div>
          <div *ngIf="!georefResults?.coordinates">
            Click on the map to inspect features
          </div>
          <button
            mat-icon-button
            style="margin-left: 5px"
            (click)="copyToClipboard()"
            *ngIf="georefResults?.coordinates"
            eeTooltip
            tooltipText="Copy Coordinates"
            tooltipClass="toolbar-tooltips"
            [tooltipDirection]="'left'"
          >
            <mat-icon
              fontSet="fal"
              style="color: #273d56; padding-bottom: 8px; font-size: 18px"
              fontIcon="fa-copy"
            ></mat-icon>
          </button>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Geography">
      <div class="tab-content" [perfectScrollbar]>
        <mat-accordion [multi]="true">
          <!-- LGAs -->
          <mat-expansion-panel *ngIf="georefResults?.lgas">
            <mat-expansion-panel-header
              collapsedHeight="24px"
              expandedHeight="24px"
            >
              <mat-panel-title>Local Government Areas</mat-panel-title>
              <mat-panel-description>
                {{ georefResults?.lgas ? georefResults.lgas.length : '-' }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of georefResults.lgas">
              <a
                [routerLink]="[
                  '/',
                  AppRoutesEnum.HUB,
                  HubRoutesEnum.LGA,
                  item.id
                ]"
              >
                {{ item.value | titlecase }}
              </a>
            </div>
          </mat-expansion-panel>

          <!-- Suburb -->
          <mat-expansion-panel *ngIf="georefResults?.suburbs">
            <mat-expansion-panel-header
              collapsedHeight="24px"
              expandedHeight="24px"
            >
              <mat-panel-title>Suburbs</mat-panel-title>
              <mat-panel-description>
                {{
                  georefResults?.suburbs ? georefResults.suburbs.length : '-'
                }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of georefResults.suburbs">
              <!-- <a
                [routerLink]="[
                  '/',
                  AppRoutesEnum.HUB,
                  HubRoutesEnum.CUSTOM_REF,
                  'suburbs',
                  item.id
                ]"
              > -->
              {{ item.value | titlecase }}
              <!-- </a> -->
            </div>
          </mat-expansion-panel>

          <!-- NSW Districts -->
          <mat-expansion-panel *ngIf="georefResults?.districts">
            <mat-expansion-panel-header
              collapsedHeight="24px"
              expandedHeight="24px"
            >
              <mat-panel-title>NSW Districts</mat-panel-title>
              <mat-panel-description>
                {{
                  georefResults?.districts
                    ? georefResults.districts.length
                    : '-'
                }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of georefResults.districts">
              <a
                [routerLink]="[
                  '/',
                  AppRoutesEnum.HUB,
                  HubRoutesEnum.CUSTOM_REF,
                  'nsw_districts',
                  item.id
                ]"
              >
                {{ item.value | titlecase }}
              </a>
            </div>
          </mat-expansion-panel>

          <!-- AMU Regions -->
          <mat-expansion-panel *ngIf="georefResults?.detRegions">
            <mat-expansion-panel-header
              collapsedHeight="24px"
              expandedHeight="24px"
            >
              <mat-panel-title>AMU Regions</mat-panel-title>
              <mat-panel-description>
                {{
                  georefResults?.detRegions
                    ? georefResults.detRegions.length
                    : '-'
                }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of georefResults.detRegions">
              <a
                [routerLink]="[
                  '/',
                  AppRoutesEnum.HUB,
                  HubRoutesEnum.DET,
                  item.id
                ]"
              >
                {{ item.value | titlecase }}
              </a>
            </div>
          </mat-expansion-panel>

          <!-- State Electorates -->
          <mat-expansion-panel *ngIf="georefResults?.stateElectorates">
            <mat-expansion-panel-header
              collapsedHeight="24px"
              expandedHeight="24px"
            >
              <mat-panel-title>State Electorates</mat-panel-title>
              <mat-panel-description>
                {{
                  georefResults?.stateElectorates
                    ? georefResults.stateElectorates.length
                    : '-'
                }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of georefResults.stateElectorates">
              <a
                [routerLink]="[
                  '/',
                  AppRoutesEnum.HUB,
                  HubRoutesEnum.SED,
                  item.id
                ]"
              >
                {{ item.value | titlecase }}
              </a>
            </div>
          </mat-expansion-panel>

          <!-- Federal Electorates -->
          <mat-expansion-panel *ngIf="georefResults?.federalElectorates">
            <mat-expansion-panel-header
              collapsedHeight="24px"
              expandedHeight="24px"
            >
              <mat-panel-title>Federal Electorates</mat-panel-title>
              <mat-panel-description>
                {{
                  georefResults?.federalElectorates
                    ? georefResults.federalElectorates.length
                    : '-'
                }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of georefResults.federalElectorates">
              <a
                [routerLink]="[
                  '/',
                  AppRoutesEnum.HUB,
                  HubRoutesEnum.FED,
                  item.id
                ]"
              >
                {{ item.value | titlecase }}
              </a>
            </div>
          </mat-expansion-panel>

          <!-- School Catchments -->
          <mat-expansion-panel *ngIf="georefResults?.catchments">
            <mat-expansion-panel-header
              collapsedHeight="24px"
              expandedHeight="24px"
            >
              <mat-panel-title>School Catchments</mat-panel-title>
              <mat-panel-description>
                {{
                  georefResults?.catchments
                    ? georefResults.catchments.length
                    : '-'
                }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of georefResults.catchments">
              <a
                [routerLink]="[
                  '/',
                  AppRoutesEnum.HUB,
                  HubRoutesEnum.CUSTOM_REF,
                  'school_catchments',
                  item.id
                ]"
              >
                {{ item.value | titlecase }}
              </a>
            </div>
          </mat-expansion-panel>

          <!-- School Performance Directorates -->
          <mat-expansion-panel *ngIf="georefResults?.operationalDirectorates">
            <mat-expansion-panel-header
              collapsedHeight="24px"
              expandedHeight="24px"
            >
              <mat-panel-title>Public Schools Directorates</mat-panel-title>
              <mat-panel-description>
                {{
                  georefResults?.operationalDirectorates
                    ? georefResults.operationalDirectorates.length
                    : '-'
                }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of georefResults.operationalDirectorates">
              <a
                [routerLink]="[
                  '/',
                  AppRoutesEnum.HUB,
                  HubRoutesEnum.OPERATIONAL_DIRECTORATE,
                  item.id
                ]"
              >
                {{ item.value | titlecase }}
              </a>
            </div>
          </mat-expansion-panel>

          <!-- Principal Networks -->
          <mat-expansion-panel *ngIf="georefResults?.principalNetworks">
            <mat-expansion-panel-header
              collapsedHeight="24px"
              expandedHeight="24px"
            >
              <mat-panel-title>Principal Networks</mat-panel-title>
              <mat-panel-description>
                {{
                  georefResults?.principalNetworks
                    ? georefResults.principalNetworks.length
                    : '-'
                }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of georefResults.principalNetworks">
              <a
                [routerLink]="[
                  '/',
                  AppRoutesEnum.HUB,
                  HubRoutesEnum.PRINCIPAL_NETWORK,
                  item.id
                ]"
              >
                {{ item.value | titlecase }}
              </a>
            </div>
          </mat-expansion-panel>

          <!-- DPIE Planning Regions -->
          <mat-expansion-panel *ngIf="georefResults?.dpieRegions">
            <mat-expansion-panel-header
              collapsedHeight="24px"
              expandedHeight="24px"
            >
              <mat-panel-title>DPHI Regions</mat-panel-title>
              <mat-panel-description>
                {{
                  georefResults?.dpieRegions
                    ? georefResults.dpieRegions.length
                    : '-'
                }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of georefResults.dpieRegions">
              <a
                [routerLink]="[
                  '/',
                  AppRoutesEnum.HUB,
                  HubRoutesEnum.DPIE_REGION,
                  item.id
                ]"
              >
                {{ item.value | titlecase }}
              </a>
            </div>
          </mat-expansion-panel>

          <!-- DP Lots -->
          <mat-expansion-panel *ngIf="georefResults?.lots">
            <mat-expansion-panel-header
              collapsedHeight="24px"
              expandedHeight="24px"
            >
              <mat-panel-title>DP Lots</mat-panel-title>
              <mat-panel-description>
                {{ georefResults?.lots ? georefResults.lots.length : '-' }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of georefResults.lots">
              <a
                [routerLink]="[
                  '/',
                  AppRoutesEnum.HUB,
                  HubRoutesEnum.CUSTOM_REF,
                  'cadastre',
                  item.id
                ]"
              >
                {{ item.value | titlecase }}
              </a>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div fxLayout="column" class="icon-container">
    <button
      mat-icon-button
      (click)="close()"
      style="padding-right: 20px"
      class="close"
    >
      <mat-icon fontSet="fal" fontIcon="fa-times"></mat-icon>
    </button>
  </div>
</div>
