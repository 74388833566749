import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PlannedAreaAnnotation } from '../../interfaces/planned-area-annotation';
import { PlannedAreaDataService } from '../../services/planned-area-data/planned-area-data.service';
import { ConfirmationDialogService } from '../../../core/services/confirmation-dialog/confirmation-dialog.service';
import { MapboxService } from '../../../shared/services/mapbox/mapbox.service';
import { first, takeUntil } from 'rxjs/operators';
import { MapObject } from '../../../shared/interfaces/map-object';
import { NotificationPanelService } from '../../../shared/services/notification-panel/notification-panel.service';
import { NotificationPopupType } from '../../../core/enums/notification-popup-type';
import { AuthService } from '../../../core/services/auth/auth.service';
import { UserRoles } from '../../../core/enums/user-roles';

@Component({
  selector: 'ee-annotation-marker-popup',
  templateUrl: './annotation-marker-popup.component.html',
  styleUrls: ['./annotation-marker-popup.component.scss']
})
export class AnnotationMarkerPopupComponent implements OnInit, OnDestroy {
  mapObject: MapObject = null;
  visible = false;
  originalLabel: string = null;
  annotation: PlannedAreaAnnotation = null;
  editMode = false;
  otherEdit = false;
  isNew = false;
  isUserGapEditor = false;

  private clickEventObservable: Observable<boolean> = null;
  private annotationUpdateObservable: Observable<PlannedAreaAnnotation> = null;
  private saveSubject: Subject<PlannedAreaAnnotation> =
    new Subject<PlannedAreaAnnotation>();
  private deleteSubject: Subject<number> = new Subject<number>();
  private editSubject: Subject<number> = new Subject<number>();
  private cancelSubject: Subject<number> = new Subject<number>();
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  @ViewChild('annotationPopup', { static: true }) el: ElementRef;
  constructor(
    private authService: AuthService,
    private plannedAreaDataService: PlannedAreaDataService,
    private notificationPanelService: NotificationPanelService,
    private confirmDialog: ConfirmationDialogService,
    private mapboxService: MapboxService
  ) {}

  ngOnInit() {
    this.isUserGapEditor = this.authService.checkUserRole(UserRoles.GAP_EDITOR);
  }

  ngOnDestroy() {
    if (this.editMode) {
      this.cancel();
    }
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  onMapEdit() {
    this.mapboxService
      .onMapEdit(this.mapObject)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(mode => {
        this.otherEdit = mode !== null && !this.editMode && !this.isNew;
      });
  }

  set(
    mapObject: MapObject,
    annotation: PlannedAreaAnnotation,
    clickEventObservable: Observable<boolean>,
    annotationUpdateObservable: Observable<PlannedAreaAnnotation>,
    isNew?: boolean
  ) {
    this.mapObject = mapObject;
    this.annotation = annotation;
    this.clickEventObservable = clickEventObservable;
    this.annotationUpdateObservable = annotationUpdateObservable;
    this.originalLabel = annotation.label;
    this.isNew = isNew;

    if (isNew) {
      this.visible = true;
      this.editMode = true;
      this.otherEdit = false;
    }

    if (this.clickEventObservable) {
      this.watchClickEvent();
    }

    if (this.annotationUpdateObservable) {
      this.watchAnnotationUpdate();
    }

    this.onMapEdit();
  }

  onDelete(): Observable<number> {
    return this.deleteSubject;
  }

  onEdit(): Observable<number> {
    return this.editSubject;
  }

  onCancel(): Observable<number> {
    return this.cancelSubject;
  }

  onSave(): Observable<PlannedAreaAnnotation> {
    return this.saveSubject;
  }

  watchClickEvent() {
    this.clickEventObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => {
        if (value) {
          this.visible = !this.visible;
        }
      });
  }

  watchAnnotationUpdate() {
    this.annotationUpdateObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(annotation => {
        if (this.annotation.annotId === annotation.annotId) {
          // Update geometry
          this.annotation.geometry = annotation.geometry;
        }
      });
  }

  show() {
    this.visible = true;
  }

  catchClick(e) {
    e.cancelBubble = true;
    e.preventDefault();
  }

  hide(e) {
    e.cancelBubble = true;
    e.preventDefault();
    this.visible = false;
  }

  close(e) {
    e.cancelBubble = true;
    e.preventDefault();
    this.visible = false;
  }

  cancel(e?) {
    if (e) {
      e.cancelBubble = true;
      e.preventDefault();
    }
    // reset to original value
    this.annotation.label = this.originalLabel;
    this.editMode = false;
    this.cancelSubject.next(this.annotation.annotId);
  }

  edit(e) {
    e.cancelBubble = true;
    e.preventDefault();
    this.editMode = true;
    this.editSubject.next(this.annotation.annotId);
  }

  save(e) {
    e.cancelBubble = true;
    e.preventDefault();
    // save annotation object
    this.plannedAreaDataService
      .saveAnnotation(this.annotation, this.isNew)
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.originalLabel = this.annotation.label;

          this.notificationPanelService.showNotification({
            type: NotificationPopupType.SUCCESS,
            title: 'Annotation saved',
            message: 'The selected annotation has been saved successfully.'
          });
        }
        this.isNew = false;
        this.editMode = false;
        this.saveSubject.next(result);
      });
  }

  deleteAnnotation() {
    this.plannedAreaDataService
      .deleteAnnotation(this.annotation.annotId)
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.deleteSubject.next(this.annotation.annotId);
          this.deleteSubject.complete();

          this.notificationPanelService.showNotification({
            type: NotificationPopupType.SUCCESS,
            title: 'Annotation deleted',
            message: 'The selected annotation has been deleted successfully.'
          });
        }
      });
  }

  delete() {
    this.confirmDialog
      .showDialog(
        'Delete annotation',
        'Are you sure you wish to delete the selected annotation?',
        'Delete?'
      )
      .subscribe(val => {
        if (val) {
          this.deleteAnnotation();
        }
      });
  }
}
