import { Component, OnInit } from '@angular/core';
import { ApiCommsService } from '../../services/api-comms/api-comms.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'ee-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {
  gifUrl = null;

  constructor(private apiCommsService: ApiCommsService) {}

  ngOnInit() {
    this.getGiphy();
  }

  private getGiphy() {
    this.apiCommsService
      .getGiphyImage('construction')
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.gifUrl = result;
        }
      });
  }
}
