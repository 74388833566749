import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiCommsService } from 'src/app/core/services/api-comms/api-comms.service';
import { DownloadDisclaimerService } from 'src/app/core/services/download-disclaimer/download-disclaimer.service';
import { DownloadsApiCommsService } from 'src/app/core/services/downloads-api-comms/downloads-api-comms.service';

@Component({
  selector: 'ee-admin-school-master-list',
  templateUrl: './admin-school-master-list.component.html',
  styleUrls: ['./admin-school-master-list.component.scss']
})
export class AdminSchoolMasterListComponent implements OnInit {
  allColumns: any[] = [];
  categorizedColumns: any[] = [];
  selectedDisplayedColumns: string[] = [];
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  itemsPerPage = 25;
  totalItems = 0;
  currentPage = 0;
  masterData: any[] = [];
  showFilter = false;
  filterPosition = { top: 0, left: 0 };
  columnFilterText: string = '';
  isDropdownOpen = false;
  filterText: string = '';
  valueFilterText: string = '';
  selectedColumn: string = '';
  selectedOperator: string = '';
  selectedValues: any[] = [];
  operators: string[] = [];
  columnValues: any[] = [];
  betweenValues: [number, number] = [0, 0];
  filterState: {
    column: string;
    operator: string;
    values: any[];
    displayValues: string[];
  }[] = [];

  @ViewChild(MatSort, { static: true }) matSort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(
    private apiCommsService: ApiCommsService,
    private downloadsApiComms: DownloadsApiCommsService
  ) {}

  ngOnInit(): void {
    this.loadSchoolMasterList();
    this.dataSource.sort = this.matSort;
    this.dataSource.paginator = this.paginator;
    //this.dataSource.filterPredicate = (data: any, filter: string) => true;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.matSort;
  }

  onSearch(value: string): void {
    this.filterText = value.toLowerCase();
  }

  getFilteredColumns(columns: any[]): any[] {
    if (!this.filterText) {
      return columns;
    }
    const searchText = this.filterText.toLowerCase();
    return columns.filter(column =>
      column.displayName.toLowerCase().includes(searchText)
    );
  }

  getFilterColumns(columns: any[]): any[] {
    if (!this.columnFilterText) {
      return columns;
    }
    const searchText = this.columnFilterText.toLowerCase();
    return columns.filter(column =>
      column.displayName.toLowerCase().includes(searchText)
    );
  }

  isAllColumnsSelected(): boolean {
    return this.displayedColumns.length === this.allColumns.length;
  }

  getMaxRowIndex(): number[] {
    const maxRows = Math.max(
      ...this.categorizedColumns.map(category => category.columns.length)
    );
    return Array.from({ length: maxRows }, (_, i) => i);
  }

  toggleAllColumns(checked: boolean): void {
    this.displayedColumns = checked
      ? this.allColumns.map(column => column.key).filter(key => key)
      : [];
  }

  getFilteredValues(values: any[]): any[] {
    if (!this.valueFilterText) {
      return values;
    }
    const searchText = this.valueFilterText.toLowerCase();
    return values.filter(value =>
      value?.toString().toLowerCase().includes(searchText)
    );
  }

  loadSchoolMasterList(): void {
    this.apiCommsService.getSchoolMasterListForDisplay().subscribe({
      next: (response: any) => {
        this.masterData = response.data || [];
        this.dataSource.data = this.masterData.slice(0, this.itemsPerPage);
        this.totalItems = response.totalItems || 0;

        this.categorizedColumns = response.categorizedColumns || [];

        this.allColumns = this.categorizedColumns
          .map(category => category.columns)
          .reduce((acc, columns) => acc.concat(columns), [])
          .filter(column => column.key)
          .map(col => ({
            ...col,
            type: col.type || 'string',
            operators: col.operators || ['Equals', 'Does not equal']
          }))
          .sort((a, b) => a.displayName.localeCompare(b.displayName));
        this.displayedColumns = this.allColumns.map(col => col.key);
      },
      error: err => console.error('Error loading data:', err)
    });
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  updateDisplayedColumns(): void {
    this.displayedColumns = [...this.displayedColumns].sort((a, b) =>
      this.getFormattedColumnName(a).localeCompare(
        this.getFormattedColumnName(b)
      )
    );
    this.dataSource.data = this.dataSource.data;
  }

  // updateDisplayedColumns(): void {
  //   this.displayedColumns = this.allColumns
  //     .sort((a, b) => a.displayName.localeCompare(b.displayName))
  //     .map(col => col.key);

  //   this.dataSource.data = this.dataSource.data;
  // }

  isCategorySelected(category: any): boolean {
    return category.columns.every(column =>
      this.selectedDisplayedColumns.includes(column.key)
    );
  }

  getDisplayName(columnKey: string): string {
    const column = this.allColumns.find(col => col.key === columnKey);
    return column ? column.displayName : columnKey;
  }

  // toggleCategory(category: any, checked: boolean): void {
  //   category.columns.forEach(column => {
  //     if (checked && !this.selectedDisplayedColumns.includes(column.key)) {
  //       this.selectedDisplayedColumns.push(column.key);
  //     } else if (!checked && this.selectedDisplayedColumns.includes(column.key)) {
  //       this.selectedDisplayedColumns = this.selectedDisplayedColumns.filter(key => key !== column.key);
  //     }
  //   });
  //   this.updateDisplayedColumns();
  // }

  onColumnToggle(columnKey: string): void {
    if (!columnKey) return;

    if (this.displayedColumns.includes(columnKey)) {
      this.displayedColumns = this.displayedColumns.filter(
        key => key !== columnKey
      );
    } else {
      this.displayedColumns.push(columnKey);
    }
    //this.updateDisplayedColumns();
  }

  changePage(event: any): void {
    this.itemsPerPage = event.pageSize;
    this.currentPage = event.pageIndex;

    const startIndex = this.currentPage * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    const filteredData = this.applyFiltersToData(this.masterData);

    this.dataSource.data = filteredData.slice(startIndex, endIndex);
  }

  updateSort(event: any): void {
    const sortField = event.active;
    const sortDirection = event.direction || 'asc';

    this.masterData.sort((a, b) => {
      const valA = a[sortField];
      const valB = b[sortField];

      if (valA === null || valA === undefined)
        return sortDirection === 'asc' ? -1 : 1;
      if (valB === null || valB === undefined)
        return sortDirection === 'asc' ? 1 : -1;

      if (typeof valA === 'string' && typeof valB === 'string') {
        return sortDirection === 'asc'
          ? valA.localeCompare(valB)
          : valB.localeCompare(valA);
      }

      return sortDirection === 'asc'
        ? valA > valB
          ? 1
          : -1
        : valA < valB
          ? 1
          : -1;
    });

    this.applyAllFilters();
  }

  onColumnSelect(columnKey: string): void {
    this.selectedColumn = columnKey;
    this.selectedOperator = '';
    this.selectedValues = [];
    this.columnFilterText = '';
    this.valueFilterText = '';

    const column = this.allColumns.find(col => col.key === columnKey);
    if (!column) return;
    //this.operators = column?.operators || ['Equals', 'Does not equal'];
    this.operators =
      column.type === 'number' && !column.operators.includes('Between')
        ? [...column.operators, 'Between']
        : column.operators;

    this.columnValues = Array.from(
      new Set(
        this.dataSource.data
          .map(item => item[columnKey]?.toString().trim() || '(Blanks)')
          .filter(value => value !== '')
      )
    ).sort();
  }

  onOperatorChange(): void {
    if (this.selectedOperator === 'Between') {
      this.selectedValues = [];
      this.betweenValues = [0, 0];
    }
  }

  public getFormattedColumnName(key: string): string {
    if (!key) {
      return '';
    }
    return key
      .replace(/_/g, ' ')
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/\b\w/g, char => char.toUpperCase());
  }

  applyFilter(): void {
    if (this.selectedColumn && this.selectedOperator) {
      let values =
        this.selectedOperator === 'Between'
          ? [this.betweenValues[0], this.betweenValues[1]]
          : this.selectedValues;

      const existingFilter = this.filterState.find(
        filter => filter.column === this.selectedColumn
      );

      if (existingFilter) {
        existingFilter.operator = this.selectedOperator;
        existingFilter.values = values;
        existingFilter.displayValues =
          this.selectedOperator === 'Between'
            ? [`${values[0]} - ${values[1]}`]
            : values;
      } else {
        this.filterState.push({
          column: this.selectedColumn,
          operator: this.selectedOperator,
          values: values,
          displayValues:
            this.selectedOperator === 'Between'
              ? [`${values[0]} - ${values[1]}`]
              : values
        });
      }

      this.applyAllFilters();
      this.columnFilterText = '';
      this.valueFilterText = '';
    }
  }

  // openFilter(columnKey: string, event: MouseEvent): void {
  //   this.selectedColumn = columnKey;
  //   const target = event.target as HTMLElement;
  //   const rect = target.getBoundingClientRect();

  //   this.filterPosition = {
  //     top: rect.bottom + window.scrollY,
  //     left: rect.left + window.scrollX,
  //   };
  //   this.showFilter = true;
  // }

  resetFilters(): void {
    // Clear filter-related properties
    this.selectedColumn = '';
    this.selectedOperator = '';
    this.selectedValues = [];
    this.filterState = [];
    this.dataSource.filter = '';
    this.showFilter = false;

    // Reset pagination
    this.currentPage = 0;
    this.itemsPerPage = 25;

    // Reset dataSource to initial data
    this.dataSource.data = [...this.masterData.slice(0, this.itemsPerPage)];
    this.totalItems = this.masterData.length;

    // Select all columns
    this.selectedDisplayedColumns = this.allColumns.map(column => column.key);
    this.displayedColumns = [...this.selectedDisplayedColumns];

    this.allColumns.sort((a, b) => a.displayName.localeCompare(b.displayName));
    this.displayedColumns = this.allColumns
      .filter(col => this.displayedColumns.includes(col.key))
      .map(col => col.key);

    // Update the displayed columns
    this.updateDisplayedColumns();

    // Reset the filter predicate
    this.dataSource.filterPredicate = (data: any, filter: string) => true;

    if (this.matSort) {
      this.matSort.active = '';
      this.matSort.direction = '';
    }
  }

  removeFilter(filterToRemove: {
    column: string;
    operator: string;
    values: any[];
    displayValues: string[];
  }): void {
    this.filterState = this.filterState.filter(
      filter =>
        filter.column !== filterToRemove.column ||
        filter.operator !== filterToRemove.operator ||
        filter.values.join(',') !== filterToRemove.values.join(',')
    );
    this.selectedColumn = '';
    this.selectedOperator = '';
    this.selectedValues = [];

    this.applyAllFilters();
  }

  applyAllFilters(): void {
    const filteredData = this.applyFiltersToData(this.masterData);

    this.totalItems = filteredData.length;

    const startIndex = this.currentPage * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.dataSource.data = filteredData.slice(startIndex, endIndex);

    this.updateColumnValues(filteredData);
  }

  private applyFiltersToData(data: any[]): any[] {
    if (this.filterState.length === 0) return [...data];
    return data.filter(item => {
      return this.filterState.every(filter => {
        const value =
          item[filter.column] !== null && item[filter.column] !== undefined
            ? item[filter.column].toString().trim()
            : '(Blanks)';

        const filterValue = filter.values.map(val =>
          val.toString().trim().toLowerCase()
        );

        switch (filter.operator) {
          case 'Equals':
            return filterValue.includes(value.toLowerCase());
          case 'Does not equal':
            return !filterValue.includes(value.toLowerCase());
          case 'Contains':
            return filterValue.some(fv => value.toLowerCase().includes(fv));
          case 'Does not contain':
            return filterValue.every(fv => !value.toLowerCase().includes(fv));
          case 'Is greater than':
            return filterValue.every(fv => parseFloat(value) > parseFloat(fv));
          case 'Is less than':
            return filterValue.every(fv => parseFloat(value) < parseFloat(fv));
          case 'Between':
            return (
              !isNaN(parseFloat(value)) &&
              parseFloat(value) >= parseFloat(filter.values[0]) &&
              parseFloat(value) <= parseFloat(filter.values[1])
            );
          default:
            return true;
        }
      });
    });
  }

  closeFilter(): void {
    this.showFilter = false;
  }

  updateColumnValues(filteredData: any[]): void {
    this.allColumns.forEach(column => {
      const uniqueValues = Array.from(
        new Set(
          filteredData.map(item =>
            item[column.key] !== null && item[column.key] !== undefined
              ? item[column.key].toString().trim()
              : '(Blanks)'
          )
        )
      ).sort((a, b) => a.localeCompare(b));

      column.values = uniqueValues;
    });
  }

  resetFilter(): void {
    this.selectedColumn = '';
    this.filterState = this.filterState.filter(
      filter => filter.column !== this.selectedColumn
    );
    this.selectedOperator = '';
    this.selectedValues = [];
    this.applyAllFilters();

    this.showFilter = false;
  }

  getColumnsPlaceholder(): string {
    if (this.displayedColumns.length === 0) {
      return 'Select Columns';
    }
    if (this.displayedColumns.length <= 5) {
      return this.displayedColumns
        .map(key => this.getFormattedColumnName(key))
        .join(', ');
    }
    return `${this.displayedColumns.length} Columns Selected`;
  }

  downloadFilteredList(): void {
    const filteredData = this.applyFiltersToData(this.masterData);
    const displayedColumns = this.displayedColumns;

    if (filteredData.length === 0 || displayedColumns.length === 0) {
      alert('No data or columns selected to export!');
      return;
    }

    const columnDisplayNameMap: { [key: string]: string } =
      this.allColumns.reduce(
        (acc, column) => {
          acc[column.key] = column.displayName;
          return acc;
        },
        {} as { [key: string]: string }
      );

    const updatedFilterState = this.filterState.map(filter => ({
      column: columnDisplayNameMap[filter.column] || filter.column,
      operator: filter.operator,
      values: filter.displayValues.join(', ')
    }));

    const updatedFilteredColumns = this.allColumns
      .filter(col => displayedColumns.includes(col.key))
      .map(col => ({
        key: col.key,
        displayName: col.displayName
      }));

    const payload = {
      filteredData,
      filteredColumns: updatedFilteredColumns,
      filterState: updatedFilterState
    };

    this.downloadsApiComms.downloadFilterdList(payload);
  }
}
