<div class="map-container">
  <div [id]="mapId" class="map"></div>
  <ee-map-stats class="map-stats" [mapObject]="mapObject"></ee-map-stats>
  <ee-map-controls
    class="map-controls"
    *ngIf="mapControlsEnabled"
    [mapObject]="mapObject"
  ></ee-map-controls>
  <a [id]="mapId + '-img-export'"></a>
</div>
