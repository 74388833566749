import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

import { PageHeaderComponent } from './components/page-header/page-header.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { MenuComponent } from './components/menu/menu.component';

import { CoreRoutingModule } from './core-routing.module';
import { AuthService } from './services/auth/auth.service';
import { AuthGuard } from './guards/auth/auth.guard';
import { ApiInterceptorService } from './services/api-interceptor/api-interceptor.service';
import { IconLoaderService } from './services/icon-loader/icon-loader.service';
import { LoadingComponent } from './components/loading/loading.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { AnnotationMarkerComponent } from '../planned-areas/components/annotation-marker/annotation-marker.component';
import { AnnotationMarkerPopupComponent } from '../planned-areas/components/annotation-marker-popup/annotation-marker-popup.component';
import { MaterialModule } from '../shared/modules/material.module';
import { TooltipComponent } from '../shared/components/tooltip/tooltip.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationPanelComponent } from './components/notification-panel/notification-panel.component';
import { ErrorHandlerService } from './services/error-handler/error-handler.service';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { SharedModule } from '../shared/shared.module';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { AdminEditUserDialogComponent } from '../admin/components/admin-edit-user-dialog/admin-edit-user-dialog.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { MakeUpMirrorComponent } from './components/make-up-mirror/make-up-mirror.component';
import { MaintenanceGuard } from './guards/maintenance/maintenance.guard';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { UserNotificationsDialogComponent } from './components/user-notifications-dialog/user-notifications-dialog.component';
import { AdminEditSchoolAlertDialogComponent } from '../admin/components/admin-edit-school-alert-dialog/admin-edit-school-alert-dialog.component';
import { HubSchoolAlertDialogComponent } from '../hub/components/hub-school-alert-dialog/hub-school-alert-dialog.component';
import { ComingSoonStormComponent } from './components/coming-soon-storm/coming-soon-storm.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { NotificationMenuComponent } from './components/notification-menu/notification-menu.component';
import { UnsubscribePageComponent } from './components/unsubscribe-page/unsubscribe-page.component';
import { SessionExpiryNotificationComponent } from './components/session-expiry-notification/session-expiry-notification.component';
import { RestrictedPageComponent } from './components/restricted-page/restricted-page.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { AppSnowComponent } from './components/app-snow/app-snow.component';

export function iconLoaderFactory(provider: IconLoaderService) {
  return () => provider.load();
}

@NgModule({
  declarations: [
    PageHeaderComponent,
    MainPageComponent,
    MenuComponent,
    LoadingComponent,
    ConfirmationDialogComponent,
    // Note Angular does not currently support loading entry components from sub-modules
    AnnotationMarkerComponent,
    AnnotationMarkerPopupComponent,
    TooltipComponent,
    NotificationPanelComponent,
    UnsubscribePageComponent,
    RestrictedPageComponent,
    LoginPageComponent,
    ComingSoonComponent,
    ComingSoonStormComponent,
    AdminEditUserDialogComponent,
    InfoDialogComponent,
    MakeUpMirrorComponent,
    MaintenanceComponent,
    UserNotificationsDialogComponent,
    AdminEditSchoolAlertDialogComponent,
    HubSchoolAlertDialogComponent,
    NotificationListComponent,
    NotificationMenuComponent,
    SessionExpiryNotificationComponent,
    ErrorPageComponent,
    AppSnowComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    CoreRoutingModule,
    MaterialModule,
    HttpClientModule,
    FlexLayoutModule,
    FormsModule,
    SharedModule.forRoot()
  ],
  exports: [
    RouterModule,
    PageHeaderComponent,
    MenuComponent,
    LoadingComponent,
    NotificationPanelComponent,
    ComingSoonComponent,
    ComingSoonStormComponent,
    NotificationMenuComponent
  ],
  providers: [
    AuthGuard,
    MaintenanceGuard,
    AuthService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptorService,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    IconLoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: iconLoaderFactory,
      deps: [IconLoaderService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-US'
    }
  ]
})
export class CoreModule {}
