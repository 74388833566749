import { Component, OnDestroy, OnInit } from '@angular/core';
import { InstructionsService } from '../../services/instructions/instructions.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InstructionsMessage } from '../../../core/interfaces/instructions-message';

@Component({
  selector: 'ee-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss']
})
export class InstructionsComponent implements OnInit, OnDestroy {
  instructionsMessage: InstructionsMessage = {
    message: '',
    icon: '',
    visible: false
  };

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private instructionService: InstructionsService) {}

  ngOnInit() {
    this.onInstructionsToggle();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private onInstructionsToggle() {
    this.instructionService
      .onInstructionsToggle()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(instructionsMessage => {
        this.instructionsMessage = instructionsMessage;
      });
  }
}
