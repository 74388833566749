export enum HubSchoolRoutes {
  SCHOOL_OVERVIEW = 'overview',
  SCHOOL_PROFILE = 'profile',
  SCHOOL_COMPOSITION = 'composition',
  SCHOOL_DEMOGRAPHY = 'demography',
  SCHOOL_PROJECTS = 'projects',
  SCHOOL_UTILISATION = 'utilisation',
  SCHOOL_ASSETS = 'assets',
  SCHOOL_LOCALITY = 'locality',
  SCHOOL_ACADEMIC_PROFILE = 'school-outcomes',
  SCHOOL_EARLY_CHILDHOOD = 'early-childhood'
}
