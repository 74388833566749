import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MapMultiFeatureClickObject } from '../../../shared/interfaces/map-multi-feature-click-object';

@Injectable({
  providedIn: 'root'
})
export class PlannedAreaSelectPopupService {
  private selectPopupToggleSubject: Subject<MapMultiFeatureClickObject> =
    new Subject<MapMultiFeatureClickObject>();

  constructor() {}

  onSelectPopupToggle(): Subject<MapMultiFeatureClickObject> {
    return this.selectPopupToggleSubject;
  }

  showPopup(selectPopupEvent: MapMultiFeatureClickObject): void {
    this.selectPopupToggleSubject.next(selectPopupEvent);
  }
}
