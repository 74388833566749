<div class="bg-container"></div>
<div class="content">
  <div class="logo-container">
    <a href="../">
      <img src="../assets/images/eagle-eye-logo.svg" />
      <div class="title">EAGLE EYE</div>
    </a>
  </div>
  <div class="help-container">
    <a href="mailto:sci@det.nsw.edu.au?subject=Maintenance%20mode%20enquiry">
      Need help?
    </a>
  </div>
  <div class="main-content">
    <h2>Maintenance Mode</h2>
    <p>
      Eagle Eye is currently undergoing maintenance and is unavailable for use.
      <br />
      <br />
      <img [src]="gifUrl" *ngIf="gifUrl" class="gif" />
      <span
        *ngIf="
          maintenanceModeSettings?.maintenanceStartDate &&
          maintenanceModeSettings?.maintenanceEndDate
        "
      >
        <br />
        <br />
        Maintenance is scheduled between
        <span class="bold">
          {{
            maintenanceModeSettings?.maintenanceStartDate
              | date: 'd MMMM y, h:mm a'
          }}
        </span>
        and
        <span class="bold">
          {{
            maintenanceModeSettings?.maintenanceEndDate
              | date: 'd MMMM y, h:mm a'
          }}.
        </span>
      </span>
      <br />
      <br />
      We strive to get back online as soon as possible.
    </p>
  </div>
</div>
