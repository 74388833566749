import { Injectable } from '@angular/core';
import { MapboxService } from '../mapbox/mapbox.service';
import { MapObject } from '../../interfaces/map-object';
import { BehaviorSubject, Subject } from 'rxjs';
import { MapboxDrawModes } from '../../enums/mapbox-draw-modes';
import { area, circle, length, lineString, polygon } from '@turf/turf';
import { createDisplayCircle } from '../mapbox/custom-modes/shared';
import { MapMeasureMode } from '../../enums/map-measure-mode';
import { MapMeasurementEvent } from '../../interfaces/map-measurement-event';
import {
  MapAreaMeasurementResult,
  MapLineMeasurementResult,
  MapMeasurement,
  MapRadiusMeasurementResult,
  MapTransportMeasurementResult,
  MapTravelMeasurementResult
} from '../../interfaces/map-measurement';
import { MapEditMode } from '../../enums/map-edit-mode';
import { InstructionsService } from '../instructions/instructions.service';
import { LineString, Polygon } from 'geojson';
import { HubCustomViewType } from '../../../hub/enums/hub-custom-view-type';
import { TileyService } from '../tiley/tiley.service';
import { first } from 'rxjs/operators';
import { MapMeasurePanelState } from '../../enums/map-measure-panel-state';
import { MapboxSdkService } from '../mapbox-sdk/mapbox-sdk.service';
import { TransportApiService } from '../transport-api/transport-api.service';
import { TransportTripResult } from '../../interfaces/transport-trip-result';
import * as moment from 'moment';
import {
  parseArea,
  parseDuration,
  parseLength
} from '../../../utils/parse-values';
import Layer = mapboxgl.Layer;
import { TransportTripModeClass } from '../../enums/transport-trip-mode-class';

@Injectable({
  providedIn: 'root'
})
export class MapMeasureToolsService {
  // TO DO:
  /*
    1. Remove filter styles and replace with state properties (how to do this with circle - not sure?)
   */

  private readonly mapMeasurementsSourceId: string = 'map-measurements';
  private readonly travelMeasurementsSourceId: string = 'travel-measurements';
  private readonly tripMeasurementsSourceId: string = 'trip-measurements';

  private drawStyles = [
    // Lines
    {
      id: 'measure-line-active',
      filter: [
        'all',
        ['!=', ['get', 'user_mode'], 'measure_travel_walking'],
        ['!=', ['get', 'user_mode'], 'measure_travel_cycling'],
        ['!=', ['get', 'user_mode'], 'measure_travel_driving'],
        ['!=', ['get', 'user_mode'], 'measure_travel_transport'],
        ['==', ['get', 'active'], 'true']
      ],
      type: 'line',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#0000FF',
        'line-dasharray': [0.2, 2],
        'line-width': 3
      }
    },
    {
      id: 'measure-line-inactive',
      filter: [
        'all',
        ['!=', ['get', 'user_mode'], 'measure_travel_walking'],
        ['!=', ['get', 'user_mode'], 'measure_travel_cycling'],
        ['!=', ['get', 'user_mode'], 'measure_travel_driving'],
        ['!=', ['get', 'user_mode'], 'measure_travel_transport'],
        ['!=', ['get', 'active'], 'true']
      ],
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      type: 'line',
      paint: {
        'line-color': '#0000FF',
        'line-width': 3
      }
    },
    {
      id: 'measure-line-label',
      type: 'symbol',
      filter: ['==', ['geometry-type'], 'LineString'],
      paint: {
        'text-color': '#000000',
        'text-halo-color': '#FFFFFF',
        'text-halo-width': 2
      },
      layout: {
        'symbol-placement': 'line-center',
        'text-font': ['Open Sans Regular'],
        'text-field': '{user_displayLength}',
        'text-size': 12,
        'text-justify': 'center'
      }
    },
    // Polygons
    {
      id: 'measure-polygon-fill',
      type: 'fill',
      filter: ['==', ['geometry-type'], 'Polygon'],
      paint: {
        'fill-color': '#0000FF',
        'fill-opacity': 0.1
      }
    },
    {
      id: 'measure-polygon-perimeter-label',
      type: 'symbol',
      filter: ['==', ['geometry-type'], 'Polygon'],
      paint: {
        'text-color': '#000000',
        'text-halo-color': '#FFFFFF',
        'text-halo-width': 2
      },
      layout: {
        'symbol-placement': 'line-center',
        'text-font': ['Open Sans Regular'],
        'text-field': '{user_displayPerimeter}',
        'text-size': 12,
        'text-justify': 'center'
      }
    },
    {
      id: 'measure-polygon-area-label',
      type: 'symbol',
      filter: ['==', ['geometry-type'], 'Polygon'],
      paint: {
        'text-color': '#000000',
        'text-halo-color': '#FFFFFF',
        'text-halo-width': 3
      },
      layout: {
        'symbol-placement': 'point',
        'text-font': ['Open Sans Regular'],
        'text-field': '{user_displayArea}',
        'text-size': 12,
        'text-justify': 'center'
      }
    },
    // Vertices
    {
      id: 'measure-line-points-inactive',
      type: 'circle',
      filter: [
        'all',
        ['!=', ['get', 'meta'], 'midpoint'],
        [
          'any',
          [
            'all',
            ['==', ['get', 'meta'], 'vertex'],
            ['!=', ['get', 'active'], 'true']
          ],
          ['==', ['get', 'user_mode'], 'measure_travel_walking'],
          ['==', ['get', 'user_mode'], 'measure_travel_cycling'],
          ['==', ['get', 'user_mode'], 'measure_travel_driving'],
          ['==', ['get', 'user_mode'], 'measure_travel_transport']
        ]
      ],
      paint: {
        'circle-stroke-width': 2,
        'circle-stroke-color': '#0000FF',
        'circle-radius': 3,
        'circle-color': '#FFFFFF'
      }
    },
    {
      id: 'measure-line-points-active',
      type: 'circle',
      filter: [
        'all',
        ['==', ['get', 'active'], 'true'],
        ['==', ['get', 'meta'], 'vertex']
      ],
      paint: {
        'circle-stroke-width': 3,
        'circle-stroke-color': '#0000FF',
        'circle-radius': 5,
        'circle-color': '#FFFFFF'
      }
    }
  ];

  private mapStyles: Layer[] = [
    // Normal styles
    {
      id: 'measurement-line',
      type: 'line',
      filter: [
        'all',
        ['==', ['id'], ''],
        ['!=', ['get', 'mode'], 'measure_travel_walking'],
        ['!=', ['get', 'mode'], 'measure_travel_cycling'],
        ['!=', ['get', 'mode'], 'measure_travel_driving'],
        ['!=', ['get', 'mode'], 'measure_travel_transport']
      ],
      paint: {
        'line-color': '#000000',
        'line-width': 3,
        'line-opacity': 0.2
      }
    },
    {
      id: 'measurement-fill',
      type: 'fill',
      filter: ['all', ['==', ['geometry-type'], 'Polygon'], ['!=', ['id'], '']],
      paint: {
        'fill-color': '#000000',
        'fill-opacity': 0.05
      }
    },
    {
      id: 'measurement-line-hover',
      type: 'line',
      filter: ['==', ['id'], ''],
      paint: {
        'line-color': '#00FF00',
        'line-width': 3
      }
    },
    {
      id: 'measurement-fill-hover',
      type: 'fill',
      filter: [
        'all',
        ['==', ['geometry-type'], 'Polygon'],
        ['any', ['==', ['id'], ''], ['==', ['get', 'parentId'], '']]
      ],
      paint: {
        'fill-color': '#00FF00',
        'fill-opacity': 0.1
      }
    },
    {
      id: 'measurement-line-selected',
      type: 'line',
      filter: [
        'all',
        ['==', ['geometry-type'], 'Polygon'],
        ['==', ['get', 'parentId'], '']
      ],
      paint: {
        'line-color': '#000FFF',
        'line-width': 3
      }
    },
    {
      id: 'measurement-fill-selected',
      type: 'fill',
      filter: [
        'all',
        ['==', ['geometry-type'], 'Polygon'],
        ['==', ['get', 'parentId'], '']
      ],
      paint: {
        'fill-color': '#0000FF',
        'fill-opacity': 0.1
      }
    }
  ];

  private travelStyles: Layer[] = [
    {
      id: 'direction-route',
      type: 'line',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#3b9ddd',
        'line-width': 8,
        'line-opacity': [
          'case',
          ['boolean', ['feature-state', ['get', 'active']], false],
          1,
          0.8
        ]
      }
    }
  ];

  private tripStyles: Layer[] = [
    {
      id: 'trip-direction-route-case',
      type: 'line',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': 'hsl(151, 0%, 100%)',
        'line-opacity': [
          'case',
          ['boolean', ['feature-state', ['get', 'active']], false],
          1,
          0.4
        ],
        'line-width': [
          'interpolate',
          ['exponential', 1.5],
          ['zoom'],
          10,
          5.8,
          18,
          22
        ]
      }
    },
    {
      id: 'trip-direction-route-base-1',
      type: 'line',
      filter: [
        'all',
        ['!=', ['get', 'modeClass'], 99],
        ['!=', ['get', 'modeClass'], 100]
      ],
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': [
          'match',
          ['get', 'modeClass'],
          [1],
          '#F6891F',
          [2],
          '#05B3AD',
          [4],
          '#EF373E',
          [5],
          '#00B5EF',
          [7],
          '#742283',
          [9],
          '#58B947',
          [11],
          '#8CD4F5',
          [1000],
          '#000000',
          'hsl(35, 100%, 0%)'
        ],
        'line-opacity': [
          'case',
          ['boolean', ['feature-state', ['get', 'active']], false],
          1,
          0.4
        ],
        'line-width': [
          'interpolate',
          ['exponential', 1.5],
          ['zoom'],
          10,
          2,
          18,
          12
        ]
      }
    },
    {
      id: 'trip-direction-route-base-2',
      type: 'line',
      filter: [
        'any',
        ['==', ['get', 'modeClass'], 99],
        ['==', ['get', 'modeClass'], 100]
      ],
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': [
          'match',
          ['get', 'modeClass'],
          [99],
          '#D9DADB',
          [100],
          '#D9DADB',
          'hsl(35, 100%, 0%)'
        ],
        'line-dasharray': [0.5, 2],
        'line-opacity': [
          'case',
          ['boolean', ['feature-state', ['get', 'active']], false],
          1,
          0.4
        ],
        'line-width': [
          'interpolate',
          ['exponential', 1.5],
          ['zoom'],
          10,
          2,
          18,
          12
        ]
      }
    }
  ];

  constructor(
    private mapboxService: MapboxService,
    private mapboxSdkService: MapboxSdkService,
    private transportApiService: TransportApiService,
    private tileyService: TileyService,
    private instructionsService: InstructionsService
  ) {}

  private getMeasurementById(mapObject: MapObject, id: string): MapMeasurement {
    let foundMeasurement: MapMeasurement = null;

    for (const measurement of mapObject.measurementObject.measurements) {
      if (id === measurement.id) {
        foundMeasurement = measurement;
        break;
      }
    }

    return foundMeasurement;
  }

  private setActiveMeasurement(
    mapObject: MapObject,
    id: string,
    featureId?: string | number
  ) {
    const measurement = this.getMeasurementById(mapObject, id);

    if (measurement) {
      // Force trigger mouse leave
      this.onMeasurementMouseLeave(mapObject);
      this.onTravelMouseLeave(mapObject);
      this.onTripMouseLeave(mapObject);

      // Clear existing current measurement
      setTimeout(() => {
        this.clearActiveMeasurement(mapObject);

        // Set current measurement
        mapObject.measurementObject.currentMeasurement = measurement;

        // Update measurement panel
        this.updateMeasureEvent(mapObject, {
          visible: true,
          measurement: measurement
        });

        this.mapboxService.setFilter(mapObject, 'measurement-line', [
          'all',
          ['!=', ['id'], measurement.id],
          ['!=', ['get', 'mode'], 'measure_travel_walking'],
          ['!=', ['get', 'mode'], 'measure_travel_cycling'],
          ['!=', ['get', 'mode'], 'measure_travel_driving'],
          ['!=', ['get', 'mode'], 'measure_travel_transport']
        ]);

        this.mapboxService.setFilter(mapObject, 'measurement-fill', [
          'all',
          ['==', ['geometry-type'], 'Polygon'],
          ['!=', ['id'], measurement.id]
        ]);

        this.mapboxService.setFilter(mapObject, 'measurement-line-selected', [
          'all',
          ['==', ['geometry-type'], 'Polygon'],
          ['==', ['get', 'parentId'], measurement.id]
        ]);
        this.mapboxService.setFilter(mapObject, 'measurement-fill-selected', [
          'all',
          ['==', ['geometry-type'], 'Polygon'],
          ['==', ['get', 'parentId'], measurement.id]
        ]);

        // Enable edit mode
        this.mapboxService.enableEditMode(
          mapObject,
          MapEditMode.MEASURING_TOOL,
          this.drawStyles
        );

        // Add measurement to draw layer
        mapObject.draw.add(measurement.geoJson);

        mapObject.draw.changeMode(MapboxDrawModes.DIRECT_SELECT, {
          featureId: measurement.id || id
        });

        // Set active travel feature (if necessary)
        if (featureId) {
          if (
            measurement.mode === MapMeasureMode.MEASURE_TRAVEL_WALKING ||
            measurement.mode === MapMeasureMode.MEASURE_TRAVEL_CYCLING ||
            measurement.mode === MapMeasureMode.MEASURE_TRAVEL_DRIVING
          ) {
            mapObject.measurementObject.travelSelectId = featureId;
            mapObject.map.setFeatureState(
              {
                source: this.travelMeasurementsSourceId,
                id: mapObject.measurementObject.travelSelectId.toString()
              },
              { active: true }
            );
          } else if (
            measurement.mode === MapMeasureMode.MEASURE_TRAVEL_TRANSPORT
          ) {
            mapObject.measurementObject.tripSelectId = featureId;
            mapObject.map.setFeatureState(
              {
                source: this.tripMeasurementsSourceId,
                id: mapObject.measurementObject.tripSelectId.toString()
              },
              { active: true }
            );
          }
        }

        mapObject.measurementObject.isActive = true;
      });
    }
  }

  clearActiveMeasurement(mapObject: MapObject) {
    if (
      mapObject &&
      mapObject.measurementObject &&
      mapObject.measurementObject.isActive
    ) {
      // Hide instruction message
      this.instructionsService.hide();

      // Clear all remaining drawing objects from map
      mapObject.draw.deleteAll();

      // Disable edit mode
      this.mapboxService.disableEditMode(mapObject);

      // Reset measurement object
      mapObject.measurementObject.isActive = false;
      mapObject.measurementObject.currentMeasurement = null;

      // Trigger update event
      this.updateMeasureEvent(mapObject, {
        visible: false
      });

      // Reset map styles
      this.mapboxService.setFilter(mapObject, 'measurement-line', [
        'all',
        ['!=', ['id'], ''],
        ['!=', ['get', 'mode'], 'measure_travel_walking'],
        ['!=', ['get', 'mode'], 'measure_travel_cycling'],
        ['!=', ['get', 'mode'], 'measure_travel_driving'],
        ['!=', ['get', 'mode'], 'measure_travel_transport']
      ]);

      this.mapboxService.setFilter(mapObject, 'measurement-fill', [
        'all',
        ['==', ['geometry-type'], 'Polygon'],
        ['!=', ['id'], '']
      ]);

      this.mapboxService.setFilter(mapObject, 'measurement-line-selected', [
        'all',
        ['==', ['geometry-type'], 'Polygon'],
        ['==', ['get', 'parentId'], '']
      ]);
      this.mapboxService.setFilter(mapObject, 'measurement-fill-selected', [
        'all',
        ['==', ['geometry-type'], 'Polygon'],
        ['==', ['get', 'parentId'], '']
      ]);

      // TO DO - Fix this?
      if (mapObject.measurementObject.travelSelectId) {
        mapObject.map.setFeatureState(
          {
            source: this.travelMeasurementsSourceId,
            id: mapObject.measurementObject.travelSelectId.toString()
          },
          { active: false }
        );
        mapObject.measurementObject.travelSelectId = null;
      }

      if (mapObject.measurementObject.tripSelectId) {
        mapObject.map.setFeatureState(
          {
            source: this.tripMeasurementsSourceId,
            id: mapObject.measurementObject.tripSelectId.toString()
          },
          { active: false }
        );
        mapObject.measurementObject.tripSelectId = null;
      }
    }
  }

  deleteActiveMeasurement(mapObject: MapObject) {
    if (
      mapObject &&
      mapObject.measurementObject &&
      mapObject.measurementObject.isActive
    ) {
      // Remove main geometry
      const index = mapObject.measurementObject.measurements.indexOf(
        mapObject.measurementObject.currentMeasurement
      );
      if (index > -1) {
        mapObject.measurementObject.measurements.splice(index, 1);
        this.updateMeasurementLayer(mapObject);
      }

      // Update travel layer
      if (
        mapObject.measurementObject.currentMeasurement.mode ===
          MapMeasureMode.MEASURE_TRAVEL_WALKING ||
        mapObject.measurementObject.currentMeasurement.mode ===
          MapMeasureMode.MEASURE_TRAVEL_CYCLING ||
        mapObject.measurementObject.currentMeasurement.mode ===
          MapMeasureMode.MEASURE_TRAVEL_DRIVING
      ) {
        this.updateTravelLayer(mapObject);
      } else if (
        mapObject.measurementObject.currentMeasurement.mode ===
        MapMeasureMode.MEASURE_TRAVEL_TRANSPORT
      ) {
        this.updateTransportTripLayer(mapObject);
      }

      this.clearActiveMeasurement(mapObject);
    }
  }

  private onMeasurementMouseMove(mapObject: MapObject, e: any) {
    if (
      (!mapObject.editMode ||
        mapObject.editMode === MapEditMode.MEASURING_TOOL) &&
      (!mapObject.measurementObject.isActive ||
        (mapObject.measurementObject.isActive &&
          mapObject.measurementObject.currentMeasurement.id)) &&
      e &&
      e.features &&
      e.features.length > 0
    ) {
      const feature = e.features[0];

      // why is it a null string ('null') ??????
      let id = null;
      if (feature.properties.id && feature.properties.id !== 'null') {
        id = feature.properties.id;
      } else if (
        feature.properties.parentId &&
        !(
          mapObject.measurementObject.isActive &&
          feature.properties.parentId ===
            mapObject.measurementObject.currentMeasurement.id
        )
      ) {
        id = feature.properties.parentId;
      }

      if (
        id &&
        (!mapObject.measurementObject.currentMeasurement ||
          mapObject.measurementObject.currentMeasurement.id !== id)
      ) {
        this.mapboxService.setFilter(mapObject, 'measurement-line-hover', [
          'any',
          ['==', ['id'], id],
          ['==', ['get', 'parentId'], id]
        ]);
        this.mapboxService.setFilter(mapObject, 'measurement-fill-hover', [
          'all',
          ['==', ['geometry-type'], 'Polygon'],
          ['any', ['==', ['id'], id], ['==', ['get', 'parentId'], id]]
        ]);
        mapObject.map.getCanvas().style.cursor = 'pointer';
      }
    }
  }

  private onMeasurementMouseLeave(mapObject: MapObject) {
    if (
      (!mapObject.editMode ||
        mapObject.editMode === MapEditMode.MEASURING_TOOL) &&
      (!mapObject.measurementObject.isActive ||
        (mapObject.measurementObject.isActive &&
          mapObject.measurementObject.currentMeasurement.id))
    ) {
      this.mapboxService.setFilter(mapObject, 'measurement-line-hover', [
        'any',
        ['==', ['id'], ''],
        ['==', ['get', 'parentId'], '']
      ]);
      this.mapboxService.setFilter(mapObject, 'measurement-fill-hover', [
        'all',
        ['==', ['geometry-type'], 'Polygon'],
        ['any', ['==', ['id'], ''], ['==', ['get', 'parentId'], '']]
      ]);

      mapObject.map.getCanvas().style.cursor = '';
    }
  }

  private onMeasurementMouseClick(
    mapObject: MapObject,
    e: any,
    layers: string[]
  ) {
    // Only allow click if:
    // There user is not in edit mode or the edit mode is for the measuring tool; and
    // The measurement object is not active, or the measurement is active but the selected feature is not the current measurement
    if (
      (!mapObject.editMode ||
        mapObject.editMode === MapEditMode.MEASURING_TOOL) &&
      (!mapObject.measurementObject.isActive ||
        (mapObject.measurementObject.isActive &&
          mapObject.measurementObject.currentMeasurement.id)) &&
      (!mapObject.measurementObject.currentMeasurement ||
        !mapObject.measurementObject.currentMeasurement.isNew)
    ) {
      const features = mapObject.map.queryRenderedFeatures(e.point, {
        layers: layers
      });

      if (features && features.length > 0) {
        // Only handle the first feature
        const feature = features[0];

        // why is it a null string ('null') ??????
        let id = null;
        if (feature.properties.id && feature.properties.id !== 'null') {
          id = feature.properties.id;
        } else if (
          feature.properties.parentId &&
          !(
            mapObject.measurementObject.isActive &&
            feature.properties.parentId ===
              mapObject.measurementObject.currentMeasurement.id
          )
        ) {
          id = feature.properties.parentId;
        }

        if (id) {
          this.setActiveMeasurement(mapObject, id, feature.id);
        }
      }
    }
  }

  private onTravelMouseMove(mapObject: MapObject, e: any) {
    if (
      (!mapObject.editMode ||
        mapObject.editMode === MapEditMode.MEASURING_TOOL) &&
      (!mapObject.measurementObject.isActive ||
        (mapObject.measurementObject.isActive &&
          mapObject.measurementObject.currentMeasurement.id)) &&
      e &&
      e.features &&
      e.features.length > 0
    ) {
      if (
        !mapObject.measurementObject.travelSelectId ||
        (mapObject.measurementObject.travelSelectId &&
          mapObject.measurementObject.travelSelectId !== e.features[0].id)
      ) {
        mapObject.measurementObject.travelHoverId = e.features[0].id;
        mapObject.map.setFeatureState(
          {
            source: this.travelMeasurementsSourceId,
            id: mapObject.measurementObject.travelHoverId.toString()
          },
          { active: true }
        );
        mapObject.map.getCanvas().style.cursor = 'pointer';
      }
    }
  }

  private onTravelMouseLeave(mapObject: MapObject) {
    if (
      !mapObject.editMode ||
      (mapObject.editMode === MapEditMode.MEASURING_TOOL &&
        (!mapObject.measurementObject.isActive ||
          (mapObject.measurementObject.isActive &&
            mapObject.measurementObject.currentMeasurement.id)))
    ) {
      if (
        mapObject.measurementObject.travelHoverId &&
        mapObject.measurementObject.travelHoverId !==
          mapObject.measurementObject.travelSelectId
      ) {
        mapObject.map.setFeatureState(
          {
            source: this.travelMeasurementsSourceId,
            id: mapObject.measurementObject.travelHoverId.toString()
          },
          { active: false }
        );
        mapObject.measurementObject.travelHoverId = null;
        mapObject.map.getCanvas().style.cursor = '';
      }
    }
  }

  private onTripMouseMove(mapObject: MapObject, e: any) {
    if (
      (!mapObject.editMode ||
        mapObject.editMode === MapEditMode.MEASURING_TOOL) &&
      (!mapObject.measurementObject.isActive ||
        (mapObject.measurementObject.isActive &&
          mapObject.measurementObject.currentMeasurement.id)) &&
      e &&
      e.features &&
      e.features.length > 0
    ) {
      if (
        !mapObject.measurementObject.tripSelectId ||
        (mapObject.measurementObject.tripSelectId &&
          mapObject.measurementObject.tripSelectId !== e.features[0].id)
      ) {
        mapObject.measurementObject.tripHoverId = e.features[0].id;
        mapObject.map.setFeatureState(
          {
            source: this.tripMeasurementsSourceId,
            id: mapObject.measurementObject.tripHoverId.toString()
          },
          { active: true }
        );
        mapObject.map.getCanvas().style.cursor = 'pointer';
      }
    }
  }

  private onTripMouseLeave(mapObject: MapObject) {
    if (
      !mapObject.editMode ||
      (mapObject.editMode === MapEditMode.MEASURING_TOOL &&
        (!mapObject.measurementObject.isActive ||
          (mapObject.measurementObject.isActive &&
            mapObject.measurementObject.currentMeasurement.id)))
    ) {
      if (
        mapObject.measurementObject.tripHoverId &&
        mapObject.measurementObject.tripHoverId !==
          mapObject.measurementObject.tripSelectId
      ) {
        mapObject.map.setFeatureState(
          {
            source: this.tripMeasurementsSourceId,
            id: mapObject.measurementObject.tripHoverId.toString()
          },
          { active: false }
        );
        mapObject.measurementObject.tripHoverId = null;
        mapObject.map.getCanvas().style.cursor = '';
      }
    }
  }

  private createOrUpdateCircle(mapObject: MapObject) {
    if (
      mapObject.measurementObject.currentMeasurement &&
      mapObject.measurementObject.currentMeasurement.mode ===
        MapMeasureMode.MEASURE_RADIUS
    ) {
      const circleGeoJson = createDisplayCircle(
        mapObject.measurementObject.currentMeasurement.geoJson,
        mapObject.measurementObject.currentMeasurement.id
      );

      (
        mapObject.measurementObject.currentMeasurement
          .result as MapRadiusMeasurementResult
      ).circleMeasurement = {
        id: null,
        parentId: mapObject.measurementObject.currentMeasurement.id,
        mode: MapMeasureMode.MEASURE_RADIUS,
        geometry: circleGeoJson.geometry
      };

      this.updateMeasurementLayer(mapObject);

      // Make new circle selected
      mapObject.map.setFilter('measurement-line-selected', [
        'all',
        ['==', ['geometry-type'], 'Polygon'],
        [
          '==',
          ['get', 'parentId'],
          mapObject.measurementObject.currentMeasurement.id
        ]
      ]);
      mapObject.map.setFilter('measurement-fill-selected', [
        'all',
        ['==', ['geometry-type'], 'Polygon'],
        [
          '==',
          ['get', 'parentId'],
          mapObject.measurementObject.currentMeasurement.id
        ]
      ]);
    }
  }

  private updateMeasurementOnChange(mapObject: MapObject, feature: any) {
    // Update/set current measurement
    mapObject.measurementObject.currentMeasurement.id = feature.id;
    mapObject.measurementObject.currentMeasurement.geometry = feature.geometry;
    mapObject.measurementObject.currentMeasurement.geoJson = feature;

    // Travel measurements
    if (
      mapObject.measurementObject.currentMeasurement.mode ===
        MapMeasureMode.MEASURE_TRAVEL_WALKING ||
      mapObject.measurementObject.currentMeasurement.mode ===
        MapMeasureMode.MEASURE_TRAVEL_CYCLING ||
      mapObject.measurementObject.currentMeasurement.mode ===
        MapMeasureMode.MEASURE_TRAVEL_DRIVING
    ) {
      this.updateTravelMeasurement(mapObject);
    }
    // Transport measurements
    else if (
      mapObject.measurementObject.currentMeasurement.mode ===
      MapMeasureMode.MEASURE_TRAVEL_TRANSPORT
    ) {
      this.updateTransportMeasurement(mapObject);
    }
    // All others
    else {
      // If measurement is a circle, create circle geom in static layer
      if (
        mapObject.measurementObject.currentMeasurement.mode ===
        MapMeasureMode.MEASURE_RADIUS
      ) {
        this.createOrUpdateCircle(mapObject);
      }

      mapObject.measurementObject.currentMeasurement.updateGeoreference = true;
      this.performGeoreference(mapObject);
    }
  }

  performGeoreference(mapObject: MapObject) {
    let geom = null;

    // Only do georeference if it needs updating and user is viewing georeference panel
    if (
      mapObject.measurementObject.currentMeasurement &&
      mapObject.measurementObject.currentMeasurement.mode &&
      mapObject.measurementObject.currentMeasurement.updateGeoreference &&
      mapObject.measurementObject.measurePanelState ===
        MapMeasurePanelState.GEOREFERENCE
    ) {
      mapObject.measurementObject.currentMeasurement.updateGeoreference = false;

      if (
        mapObject.measurementObject.currentMeasurement.mode ===
        MapMeasureMode.MEASURE_RADIUS
      ) {
        geom = (
          mapObject.measurementObject.currentMeasurement
            .result as MapRadiusMeasurementResult
        ).circleMeasurement.geometry;
      } else if (
        mapObject.measurementObject.currentMeasurement.mode ===
          MapMeasureMode.MEASURE_TRAVEL_WALKING ||
        mapObject.measurementObject.currentMeasurement.mode ===
          MapMeasureMode.MEASURE_TRAVEL_CYCLING ||
        mapObject.measurementObject.currentMeasurement.mode ===
          MapMeasureMode.MEASURE_TRAVEL_DRIVING
      ) {
        geom = (
          mapObject.measurementObject.currentMeasurement
            .result as MapTravelMeasurementResult
        ).travelMeasurement.geometry;
      } else if (
        mapObject.measurementObject.currentMeasurement.mode ===
        MapMeasureMode.MEASURE_TRAVEL_TRANSPORT
      ) {
        geom = (
          mapObject.measurementObject.currentMeasurement
            .result as MapTransportMeasurementResult
        ).tripResult.geometry;
      } else {
        geom = mapObject.measurementObject.currentMeasurement.geometry;
      }
    }

    // Do georeference and update results
    if (geom) {
      let exclude = [];
      // * Circles => exclude lots if radius > 500m
      if (
        mapObject.measurementObject.currentMeasurement.mode ===
        MapMeasureMode.MEASURE_RADIUS
      ) {
        if (
          (
            mapObject.measurementObject.currentMeasurement
              .result as MapRadiusMeasurementResult
          ).radius > 0.5
        ) {
          exclude = ['lots'];
        }
        // * Rectangle => exclude lots if area > 1km2
      } else if (
        mapObject.measurementObject.currentMeasurement.mode ===
        MapMeasureMode.MEASURE_POLYGON
      ) {
        if (
          (
            mapObject.measurementObject.currentMeasurement
              .result as MapAreaMeasurementResult
          ).area > 1000000
        ) {
          exclude = ['lots'];
        }
        // * Lines (rest) => governmentSchools, nonGovSchools, lots
      } else {
        exclude = ['governmentSchools', 'nonGovernmentSchools'];
      }
      this.tileyService
        .doGeoreference(geom, exclude)
        .pipe(first())
        .subscribe(result => {
          if (result) {
            if (mapObject.measurementObject.currentMeasurement) {
              mapObject.measurementObject.currentMeasurement.georeferenceResults =
                result;
            }
          }
        });
    }
  }

  // When drawing has been initially created (after finished being drawn)
  onDrawCreate(mapObject: MapObject, e: any) {
    if (
      mapObject &&
      mapObject.measurementObject &&
      mapObject.measurementObject.isActive
    ) {
      if (e && e.features && e.features.length === 1) {
        this.instructionsService.hide();

        // Hack prevent user from triggering the 'click' event when the click/dbl click to finish drawing a measurement
        setTimeout(() => {
          mapObject.measurementObject.currentMeasurement.isNew = false;
        }, 100);

        this.updateMeasurementOnChange(mapObject, e.features[0]);
        this.addNewMeasurement(
          mapObject,
          mapObject.measurementObject.currentMeasurement
        );

        mapObject.map.getCanvas().style.cursor = 'grab';
      }
    }
  }

  // When drawing has been finished
  private onDrawEditComplete(mapObject: MapObject, e: any) {
    if (
      mapObject &&
      mapObject.measurementObject &&
      mapObject.measurementObject.isActive
    ) {
      if (e && e.features && e.features.length === 1) {
        this.instructionsService.hide();

        this.updateMeasurementOnChange(mapObject, e.features[0]);
        this.updateMeasurementLayer(mapObject);
      }
    }
  }

  // Update measurements as the geometry is modified
  // (Note: travel measurement does not perform as such as it requires origin/destination)
  onDrawEditLive(mapObject: MapObject, e: any) {
    if (
      mapObject.measurementObject.isActive &&
      e &&
      e.features &&
      e.features.length === 1
    ) {
      const feature = e.features[0];

      // Line Measuring
      if (
        feature.geometry.type === 'LineString' &&
        mapObject.measurementObject.currentMeasurement.mode ===
          MapMeasureMode.MEASURE_LINE
      ) {
        this.updateLineMeasurement(
          mapObject,
          feature.id,
          feature.geometry.coordinates
        );
      }
      // Radius Measuring
      else if (
        feature.geometry.type === 'LineString' &&
        mapObject.measurementObject.currentMeasurement.mode ===
          MapMeasureMode.MEASURE_RADIUS
      ) {
        this.updateRadiusMeasurement(
          mapObject,
          feature.id,
          feature.geometry.coordinates
        );
      }
      // Polygon measuring
      else if (
        feature.geometry.type === 'Polygon' &&
        mapObject.measurementObject.currentMeasurement.mode ===
          MapMeasureMode.MEASURE_POLYGON
      ) {
        this.updatePolygonMeasurement(
          mapObject,
          feature.id,
          feature.geometry.coordinates
        );
      }
    }
  }

  private setInstructionsMessage(mapObject: MapObject, mode: MapMeasureMode) {
    if (
      mapObject.measurementObject.isActive &&
      (!mapObject.measurementObject.currentMeasurement ||
        !mapObject.measurementObject.currentMeasurement.id)
    ) {
      let icon = null;
      let initialMessage = null;
      let clickMessage = null;

      switch (mode) {
        case MapMeasureMode.MEASURE_LINE:
          icon = 'ruler';
          initialMessage = 'Click on the map to start measuring a distance.';
          clickMessage =
            'Click to add additional points to your line. Double-click to finish.';
          break;
        case MapMeasureMode.MEASURE_POLYGON:
          icon = 'draw-square';
          initialMessage = 'Click on the map to start measuring an area.';
          clickMessage =
            'Click to add additional points to your area. Double-click to finish.';
          break;
        case MapMeasureMode.MEASURE_RADIUS:
          icon = 'draw-circle';
          initialMessage =
            'Click, then drag to draw a circle and find the radius.';
          clickMessage = 'Double-click to finish.';
          break;
        case MapMeasureMode.MEASURE_TRAVEL_WALKING:
          icon = 'walking';
          initialMessage = 'Click on the map to define the start location.';
          clickMessage =
            'Click to add waypoints to the route. Double-click to finish';
          break;
        case MapMeasureMode.MEASURE_TRAVEL_CYCLING:
          icon = 'bicycle';
          initialMessage = 'Click on the map to define the start location.';
          clickMessage =
            'Click to add waypoints to the route. Double-click to finish';
          break;
        case MapMeasureMode.MEASURE_TRAVEL_DRIVING:
          icon = 'car';
          initialMessage = 'Click on the map to define the start location.';
          clickMessage =
            'Click to add waypoints to the route. Double-click to finish';
          break;
        case MapMeasureMode.MEASURE_TRAVEL_TRANSPORT:
          icon = 'bus';
          initialMessage = 'Click on the map to define the start location.';
          clickMessage = 'Click or double-click to set your destination.';
          break;
      }

      this.instructionsService.setMessage(initialMessage, icon);

      if (mapObject) {
        mapObject.map.once('click', () => {
          if (
            mapObject.measurementObject &&
            mapObject.measurementObject.isActive
          ) {
            this.instructionsService.setMessage(clickMessage, icon);
          }
        });
      }
    }
  }

  private createEvents(mapObject: MapObject) {
    if (!mapObject.measurementObject.eventsCreated) {
      // Draw events
      mapObject.map.on('draw.create', e => {
        this.onDrawCreate(mapObject, e);
      });
      mapObject.map.on('draw.update', e => {
        this.onDrawEditComplete(mapObject, e);
      });

      mapObject.map.on('draw.liveUpdate', e => {
        this.onDrawEditLive(mapObject, e);
      });

      // Measurement events
      this.mapboxService.registerEvent(
        mapObject,
        'click',
        this.mapMeasurementsSourceId,
        e => {
          this.onMeasurementMouseClick(mapObject, e, [
            'measurement-line',
            'measurement-fill'
          ]);
        }
      );

      this.mapboxService.registerEvent(
        mapObject,
        'mousemove',
        this.mapMeasurementsSourceId,
        e => {
          this.onMeasurementMouseMove(mapObject, e);
        }
      );

      this.mapboxService.registerEvent(
        mapObject,
        'mouseleave',
        this.mapMeasurementsSourceId,
        () => {
          this.onMeasurementMouseLeave(mapObject);
        }
      );

      this.mapboxService.registerEvent(
        mapObject,
        'click',
        this.travelMeasurementsSourceId,
        e => {
          this.onMeasurementMouseClick(mapObject, e, ['direction-route']);
        }
      );

      this.mapboxService.registerEvent(
        mapObject,
        'mousemove',
        this.travelMeasurementsSourceId,
        e => {
          this.onTravelMouseMove(mapObject, e);
        }
      );

      this.mapboxService.registerEvent(
        mapObject,
        'mouseleave',
        this.travelMeasurementsSourceId,
        () => {
          this.onTravelMouseLeave(mapObject);
        }
      );

      this.mapboxService.registerEvent(
        mapObject,
        'click',
        this.tripMeasurementsSourceId,
        e => {
          this.onMeasurementMouseClick(mapObject, e, [
            'trip-direction-route-base-1',
            'trip-direction-route-case'
          ]);
        }
      );

      this.mapboxService.registerEvent(
        mapObject,
        'mousemove',
        this.tripMeasurementsSourceId,
        e => {
          this.onTripMouseMove(mapObject, e);
        }
      );

      this.mapboxService.registerEvent(
        mapObject,
        'mouseleave',
        this.tripMeasurementsSourceId,
        () => {
          this.onTripMouseLeave(mapObject);
        }
      );

      mapObject.measurementObject.eventsCreated = true;
    }
  }

  onMeasurePanelUpdate(mapObject: MapObject): Subject<MapMeasurementEvent> {
    if (mapObject && mapObject.measurementObject) {
      return mapObject.measurementObject.measurePanelEventSubject;
    } else {
      return null;
    }
  }

  private createNewMeasurement(mapObject: MapObject, mode: MapMeasureMode) {
    mapObject.measurementObject.isActive = true;

    // Create, set and update new measurement object
    const newMeasurement: MapMeasurement = {
      id: null,
      mode: mode,
      isNew: true
    };

    mapObject.measurementObject.currentMeasurement = newMeasurement;
    this.updateMeasureEvent(mapObject, {
      visible: true,
      measurement: newMeasurement
    });
  }

  private enableEditMode(
    mapObject: MapObject,
    drawMode: MapboxDrawModes | MapMeasureMode
  ) {
    // Initialise measurement object (if required) for particular map object
    if (mapObject && !mapObject.measurementObject) {
      mapObject.measurementObject = {
        isActive: false,
        eventsCreated: false,
        currentMeasurement: null,
        measurements: [],
        measurePanelEventSubject: new BehaviorSubject<MapMeasurementEvent>(
          null
        ),
        transportOptions: {
          timing: 'arr',
          time: '0700',
          date: null,
          modes: [
            TransportTripModeClass.TRAIN,
            TransportTripModeClass.BUS,
            TransportTripModeClass.SCHOOL_BUS,
            TransportTripModeClass.FERRY,
            TransportTripModeClass.LIGHT_RAIL,
            TransportTripModeClass.METRO,
            TransportTripModeClass.COACH
          ]
        }
      };
      this.createEvents(mapObject);
      mapObject.measurementReady.next(true);
    }

    // Set map edit mode and draw mode
    this.mapboxService.enableEditMode(
      mapObject,
      MapEditMode.MEASURING_TOOL,
      this.drawStyles
    );
    this.mapboxService.setDrawMode(mapObject, drawMode);
    mapObject.map.getCanvas().style.cursor = 'crosshair';

    // Reset layers
    this.updateMeasurementLayer(mapObject);
    this.updateTravelLayer(mapObject);
    this.updateTransportTripLayer(mapObject);
  }

  enableMeasureTool(mapObject: MapObject, mode: MapMeasureMode) {
    if (mapObject) {
      this.clearActiveMeasurement(mapObject);
      this.enableEditMode(mapObject, mode);
      this.createNewMeasurement(mapObject, mode);
      this.setInstructionsMessage(mapObject, mode);
    }
  }

  private updateMeasureEvent(
    mapObject: MapObject,
    measureEvent: MapMeasurementEvent
  ) {
    mapObject.measurementObject.measurePanelEventSubject.next(measureEvent);
  }

  private updateLineMeasurement(
    mapObject: MapObject,
    id: string,
    coordinates: number[][]
  ) {
    const line = lineString(coordinates);
    const lineLength = length(line, { units: 'kilometers' });
    const displayLength = parseLength(lineLength);

    if (mapObject.draw) {
      mapObject.draw.setFeatureProperty(id, 'length', lineLength);
      mapObject.draw.setFeatureProperty(id, 'displayLength', displayLength);
    }

    (mapObject.measurementObject.currentMeasurement
      .result as MapLineMeasurementResult) = {
      length: lineLength,
      displayLength: displayLength
    };

    this.updateMeasureEvent(mapObject, {
      visible: true,
      measurement: mapObject.measurementObject.currentMeasurement
    });
  }

  private updatePolygonMeasurement(
    mapObject: MapObject,
    id: string,
    coordinates: number[][][]
  ) {
    const poly = polygon(coordinates);

    const polyArea = area(poly);
    const displayArea = parseArea(polyArea);

    const perimeter = length(poly, { units: 'kilometers' });
    const displayPerimeter = parseLength(perimeter);

    if (mapObject.draw) {
      mapObject.draw.setFeatureProperty(id, 'area', polyArea);
      mapObject.draw.setFeatureProperty(id, 'perimeter', perimeter);
      mapObject.draw.setFeatureProperty(id, 'displayArea', displayArea);
      mapObject.draw.setFeatureProperty(
        id,
        'displayPerimeter',
        displayPerimeter
      );
    }

    (mapObject.measurementObject.currentMeasurement
      .result as MapAreaMeasurementResult) = {
      area: polyArea,
      perimeter: perimeter,
      displayArea: displayArea,
      displayPerimeter: displayPerimeter
    };

    this.updateMeasureEvent(mapObject, {
      visible: true,
      measurement: mapObject.measurementObject.currentMeasurement
    });
  }

  private updateRadiusMeasurement(
    mapObject: MapObject,
    id: string,
    coordinates: number[][]
  ) {
    const line = lineString(coordinates);
    const radius = length(line, { units: 'kilometers' });
    const displayRadius = parseLength(radius);
    const circumference = radius * 2 * Math.PI;
    const displayCircumference = parseLength(circumference);

    if (mapObject.draw) {
      mapObject.draw.setFeatureProperty(id, 'length', radius);
      mapObject.draw.setFeatureProperty(id, 'perimeter', circumference);
      mapObject.draw.setFeatureProperty(id, 'displayLength', displayRadius);
      mapObject.draw.setFeatureProperty(
        id,
        'displayPerimeter',
        displayCircumference
      );
    }

    (mapObject.measurementObject.currentMeasurement
      .result as MapRadiusMeasurementResult) = {
      radius: Math.round(radius * 1000) / 1000,
      circumference: circumference,
      displayRadius: displayRadius,
      displayCircumference: displayCircumference
    };

    this.updateMeasureEvent(mapObject, {
      visible: true,
      measurement: mapObject.measurementObject.currentMeasurement
    });
  }

  private updateTravelMeasurement(mapObject: MapObject) {
    if (
      mapObject.measurementObject.currentMeasurement &&
      (mapObject.measurementObject.currentMeasurement.mode ===
        MapMeasureMode.MEASURE_TRAVEL_WALKING ||
        mapObject.measurementObject.currentMeasurement.mode ===
          MapMeasureMode.MEASURE_TRAVEL_DRIVING ||
        mapObject.measurementObject.currentMeasurement.mode ===
          MapMeasureMode.MEASURE_TRAVEL_CYCLING)
    ) {
      const waypoints: { coordinates: number[] }[] = [];

      for (const coord of (
        mapObject.measurementObject.currentMeasurement.geometry as LineString
      ).coordinates) {
        waypoints.push({
          coordinates: coord
        });
      }

      let profile = null;
      switch (mapObject.measurementObject.currentMeasurement.mode) {
        case MapMeasureMode.MEASURE_TRAVEL_DRIVING:
          profile = 'driving';
          break;
        case MapMeasureMode.MEASURE_TRAVEL_CYCLING:
          profile = 'driving';
          break;
        case MapMeasureMode.MEASURE_TRAVEL_WALKING:
          profile = 'walking';
          break;
      }

      this.mapboxSdkService
        .getDirections(profile, waypoints)
        .pipe(first())
        .subscribe(response => {
          if (response) {
            const route = response.routes[0];
            const distance = route.distance;
            const duration = route.duration;
            const displayDistance = parseLength(route.distance / 1000);
            const displayDuration = parseDuration(route.duration);

            let travelId = null;
            let firstSet = false;

            // Determine travel id (assign if new measurement / fetch existing otherwise)
            if (
              !mapObject.measurementObject.currentMeasurement ||
              !mapObject.measurementObject.currentMeasurement.result ||
              !(
                mapObject.measurementObject.currentMeasurement
                  .result as MapTravelMeasurementResult
              ).travelId
            ) {
              if (!mapObject.measurementObject.travelCount) {
                mapObject.measurementObject.travelCount = 1;
              } else {
                mapObject.measurementObject.travelCount++;
              }
              travelId = mapObject.measurementObject.travelCount;
              firstSet = true;
            } else {
              travelId = (
                mapObject.measurementObject.currentMeasurement
                  .result as MapTravelMeasurementResult
              ).travelId;
            }

            // Update measurement result
            (mapObject.measurementObject.currentMeasurement
              .result as MapTravelMeasurementResult) = {
              travelId: travelId,
              distance: distance,
              duration: duration,
              displayDistance: displayDistance,
              displayDuration: displayDuration,
              travelMeasurement: {
                id: mapObject.measurementObject.currentMeasurement.id,
                mode: mapObject.measurementObject.currentMeasurement.mode,
                geometry: route.geometry
              }
            };

            this.updateMeasureEvent(mapObject, {
              visible: true,
              measurement: mapObject.measurementObject.currentMeasurement
            });

            // Do georeferencing against returned route
            mapObject.measurementObject.currentMeasurement.updateGeoreference =
              true;
            this.performGeoreference(mapObject);

            // Update layer
            this.updateTravelLayer(mapObject);

            // Set active feature
            if (firstSet) {
              mapObject.measurementObject.travelSelectId = travelId;
              mapObject.map.setFeatureState(
                {
                  source: this.travelMeasurementsSourceId,
                  id: mapObject.measurementObject.travelSelectId.toString()
                },
                { active: true }
              );
            }
          }
        });
    }
  }

  updateTransportMeasurement(mapObject: MapObject) {
    if (
      mapObject.measurementObject.currentMeasurement &&
      mapObject.measurementObject.currentMeasurement.mode ===
        MapMeasureMode.MEASURE_TRAVEL_TRANSPORT
    ) {
      if (mapObject.measurementObject.transportOptions) {
        const origin = (
          mapObject.measurementObject.currentMeasurement.geometry as LineString
        ).coordinates[0];
        const destination = (
          mapObject.measurementObject.currentMeasurement.geometry as LineString
        ).coordinates[1];
        const date = moment(
          mapObject.measurementObject.transportOptions.date
        ).format('YYYYMMDD');

        this.transportApiService
          .getTrip(
            origin,
            destination,
            date,
            mapObject.measurementObject.transportOptions.time,
            mapObject.measurementObject.transportOptions.timing,
            mapObject.measurementObject.transportOptions.modes
          )
          .subscribe((response: TransportTripResult) => {
            if (response) {
              let tripId: number = null;
              let firstSet = false;

              // Determine trip id (assign if new measurement / fetch existing otherwise)
              if (
                !mapObject.measurementObject.currentMeasurement ||
                !mapObject.measurementObject.currentMeasurement.result ||
                !(
                  mapObject.measurementObject.currentMeasurement
                    .result as MapTransportMeasurementResult
                ).tripId
              ) {
                if (!mapObject.measurementObject.tripCount) {
                  mapObject.measurementObject.tripCount = 1;
                } else {
                  mapObject.measurementObject.tripCount++;
                }
                tripId = mapObject.measurementObject.tripCount;
                firstSet = true;
              } else {
                tripId = (
                  mapObject.measurementObject.currentMeasurement
                    .result as MapTransportMeasurementResult
                ).tripId;
              }

              // Update measurement result
              (mapObject.measurementObject.currentMeasurement
                .result as MapTransportMeasurementResult) = {
                tripId: tripId,
                displayDistance: parseLength(response.totalDistance),
                displayDuration: parseDuration(response.totalDuration),
                displayDepartureTime: moment(response.departureTime).format(
                  'h:mm a'
                ),
                displayArrivalTime: moment(response.arrivalTime).format(
                  'h:mm a'
                ),
                tripResult: response
              };

              // Add extra properties to legs
              (
                mapObject.measurementObject.currentMeasurement
                  .result as MapTransportMeasurementResult
              ).tripResult.legs.forEach(leg => {
                leg.tripId = tripId;
                leg.parentId =
                  mapObject.measurementObject.currentMeasurement.id;
              });

              this.updateMeasureEvent(mapObject, {
                visible: true,
                measurement: mapObject.measurementObject.currentMeasurement
              });

              // Do georeferencing against returned route
              mapObject.measurementObject.currentMeasurement.updateGeoreference =
                true;
              this.performGeoreference(mapObject);

              // Update layer
              this.updateTransportTripLayer(mapObject);

              // Set active feature
              if (firstSet) {
                mapObject.measurementObject.tripSelectId = tripId;
                mapObject.map.setFeatureState(
                  {
                    source: this.tripMeasurementsSourceId,
                    id: mapObject.measurementObject.tripSelectId.toString()
                  },
                  { active: true }
                );
              }
            }
            // No response
            else {
              // Clear result
              if (
                mapObject.measurementObject.currentMeasurement &&
                mapObject.measurementObject.currentMeasurement.result
              ) {
                // Update measurement result
                (mapObject.measurementObject.currentMeasurement
                  .result as MapTransportMeasurementResult) = {
                  tripId: (
                    mapObject.measurementObject.currentMeasurement
                      .result as MapTransportMeasurementResult
                  ).tripId,
                  displayDistance: null,
                  displayDuration: null,
                  displayDepartureTime: null,
                  displayArrivalTime: null,
                  tripResult: null
                };
              }

              // Clear existing georeference results
              mapObject.measurementObject.currentMeasurement.georeferenceResults =
                null;

              // Update layer
              this.updateTransportTripLayer(mapObject);

              this.updateMeasureEvent(mapObject, {
                visible: true,
                measurement: mapObject.measurementObject.currentMeasurement,
                failed: true
              });
            }
          });
      }
    }
  }

  private updateMeasurementLayer(mapObject: MapObject) {
    const objects = [];

    mapObject.measurementObject.measurements.forEach(measurement => {
      objects.push(measurement);
      if (measurement.mode === MapMeasureMode.MEASURE_RADIUS) {
        objects.push(
          (measurement.result as MapRadiusMeasurementResult).circleMeasurement
        );
      }
    });

    this.mapboxService.updateGeoJsonDataLayer(
      mapObject,
      this.mapMeasurementsSourceId,
      objects,
      null,
      this.mapStyles
    );
  }

  private updateTravelLayer(mapObject: MapObject) {
    const objects = [];

    mapObject.measurementObject.measurements.forEach(measurement => {
      if (
        measurement.mode === MapMeasureMode.MEASURE_TRAVEL_WALKING ||
        measurement.mode === MapMeasureMode.MEASURE_TRAVEL_DRIVING ||
        measurement.mode === MapMeasureMode.MEASURE_TRAVEL_CYCLING
      ) {
        if (
          measurement.result &&
          (measurement.result as MapTravelMeasurementResult).travelMeasurement
        ) {
          objects.push(
            (measurement.result as MapTravelMeasurementResult).travelMeasurement
          );
        }
      }
    });

    this.mapboxService.updateGeoJsonDataLayer(
      mapObject,
      this.travelMeasurementsSourceId,
      objects,
      'travelId',
      this.travelStyles
    );
  }

  // Extracts each individual legs for each trip and is combined into a single object to update layer source data
  private updateTransportTripLayer(mapObject: MapObject) {
    let objects = [];

    mapObject.measurementObject.measurements.forEach(measurement => {
      if (measurement.mode === MapMeasureMode.MEASURE_TRAVEL_TRANSPORT) {
        if (
          measurement.result &&
          (measurement.result as MapTransportMeasurementResult).tripResult
        ) {
          objects = objects.concat(
            (measurement.result as MapTransportMeasurementResult).tripResult
              .legs
          );
        }
      }
    });

    this.mapboxService.updateGeoJsonDataLayer(
      mapObject,
      this.tripMeasurementsSourceId,
      objects,
      'tripId',
      this.tripStyles
    );
  }

  setRadius(mapObject: MapObject, radius: number) {
    // This updates the radius for an existing measurement object
    if (
      mapObject &&
      mapObject.measurementObject &&
      mapObject.measurementObject.currentMeasurement &&
      mapObject.measurementObject.currentMeasurement.mode ===
        MapMeasureMode.MEASURE_RADIUS
    ) {
      if (mapObject.measurementObject.currentMeasurement.id) {
        // Create a new line segment to act as the radius
        const center = (
          mapObject.measurementObject.currentMeasurement.geometry as LineString
        ).coordinates[0];
        const fakeCircle = circle(center, radius, {
          steps: 64,
          units: 'kilometers'
        });

        if (fakeCircle) {
          (
            mapObject.measurementObject.currentMeasurement.geoJson
              .geometry as LineString
          ).coordinates[1] = fakeCircle.geometry.coordinates[0][0];
        }

        // Update everything
        const feature = mapObject.measurementObject.currentMeasurement.geoJson;

        // Update draw layer
        mapObject.draw.set({
          type: 'FeatureCollection',
          features: [feature]
        });

        this.onDrawEditLive(mapObject, {
          features: [feature]
        });

        this.onDrawEditComplete(mapObject, {
          features: [feature]
        });
      } else {
        console.log('setting for future use');
        // TO DO - I dont know yet :(
      }
    }
  }

  setMeasurePanelState(
    mapObject: MapObject,
    state: number | MapMeasurePanelState
  ) {
    mapObject.measurementObject.measurePanelState = state;

    if (
      mapObject.measurementObject.measurePanelState ===
      MapMeasurePanelState.GEOREFERENCE
    ) {
      this.performGeoreference(mapObject);
    }
  }

  // These are the new functions for 'custom' stuff - revisit

  addNewMeasurement(mapObject: MapObject, measurement: MapMeasurement) {
    mapObject.measurementObject.measurements.push(measurement);
    this.updateMeasurementLayer(mapObject);
  }

  createAndAddNewMeasurement(
    mapObject: MapObject,
    encodedPolyline: string,
    viewType: HubCustomViewType
  ) {
    if (mapObject && encodedPolyline) {
      let currentEncodedPolyline = null;

      if (
        mapObject.measurementObject &&
        mapObject.measurementObject.isActive &&
        mapObject.measurementObject.currentMeasurement &&
        mapObject.measurementObject.currentMeasurement.geometry
      ) {
        const geom = mapObject.measurementObject.currentMeasurement.geometry;

        if (
          mapObject.measurementObject.currentMeasurement.mode ===
          MapMeasureMode.MEASURE_POLYGON
        ) {
          currentEncodedPolyline =
            this.mapboxService.convertPolygonToEncodedPolyline(geom as Polygon);
        } else if (
          mapObject.measurementObject.currentMeasurement.mode ===
          MapMeasureMode.MEASURE_RADIUS
        ) {
          currentEncodedPolyline =
            this.mapboxService.convertLineStringToEncodedPolyline(
              geom as LineString
            );
        }
      }

      // Only create measurement if it does not exist
      if (
        !currentEncodedPolyline ||
        currentEncodedPolyline !== encodedPolyline
      ) {
        this.clearActiveMeasurement(mapObject); // GOOD

        let parentGeom = null;

        if (viewType === HubCustomViewType.POLYGON) {
          this.enableEditMode(mapObject, MapMeasureMode.MEASURE_POLYGON);
          this.createNewMeasurement(mapObject, MapMeasureMode.MEASURE_POLYGON);

          parentGeom =
            this.mapboxService.convertEncodedPolylineToPolygon(encodedPolyline);
        } else if (viewType === HubCustomViewType.RADIUS) {
          this.enableEditMode(mapObject, MapMeasureMode.MEASURE_RADIUS);
          this.createNewMeasurement(mapObject, MapMeasureMode.MEASURE_RADIUS);

          parentGeom =
            this.mapboxService.convertEncodedPolylineToLineString(
              encodedPolyline
            );
        }

        if (mapObject.draw) {
          const parentId = mapObject.draw.add(parentGeom)[0];

          if (parentId) {
            let feature = mapObject.draw.get(parentId);

            this.onDrawEditLive(mapObject, {
              features: [feature]
            });

            feature = mapObject.draw.get(parentId);

            this.onDrawCreate(mapObject, {
              features: [feature]
            });

            mapObject.draw.changeMode(MapboxDrawModes.DIRECT_SELECT, {
              featureId: parentId
            });
          }
        }
      }
    }
  }
}
