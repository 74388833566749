<ee-loading></ee-loading>
<div class="app-container">
  <ee-menu></ee-menu>
  <div class="app-content">
    <ee-notification-panel></ee-notification-panel>
    <ee-page-header></ee-page-header>
    <div class="router-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
