<div
  #tooltipContainer
  [class]="
    'tooltip-container' +
    (tooltipDirection ? ' ' + tooltipDirection : '') +
    (tooltipClass ? ' ' + tooltipClass : '')
  "
  [ngClass]="{ visible: visible, reversed: reversed, fixed: fixed }"
  [ngStyle]="{ top: top, left: left }"
>
  <button
    mat-icon-button
    class="close-x-button"
    (click)="toggleFixed()"
    *ngIf="fixed"
  >
    <mat-icon fontSet="far" fontIcon="fa-times"></mat-icon>
  </button>

  <div [innerHTML]="body | safeHtml"></div>
</div>
