import { Injectable } from '@angular/core';
import { BehaviorSubject, observable, Observable, of } from 'rxjs';
import { ApiCommsService } from '../../services/api-comms/api-comms.service';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { UserData } from '../../../admin/interfaces/user-data';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserResolverService implements Resolve<UserData> {
  private currentUserDataSubject: BehaviorSubject<UserData> =
    new BehaviorSubject<UserData>(null);

  constructor(private apiCommsService: ApiCommsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<UserData> {
    return this.getCurrentUserData();
  }

  onUpdate(): BehaviorSubject<UserData> {
    return this.currentUserDataSubject;
  }

  getCurrentUserData(): Observable<UserData> {
    if (this.currentUserDataSubject.getValue()) {
      return of(this.currentUserDataSubject.getValue());
    } else {
      return this.apiCommsService.getCurrentUser().pipe(
        tap(data => {
          this.currentUserDataSubject.next(data);
        }),
        catchError(error => {
          console.log(error);
          return new Observable<UserData>(null);
        })
      );
    }
  }

  getCurrentUserDataValue(): UserData {
    return this.currentUserDataSubject.getValue();
  }
}
