<div>
  <div class="logo" fxLayout="column" fxLayoutAlign="start center">
    <a href="/">
      <img src="assets/images/eagle-eye-logo.svg" />
      <h1>EAGLE EYE</h1>
    </a>
  </div>
  <div class="menu-items">
    <div class="menu-accent"></div>

    <ng-container *ngFor="let item of menuItems">
      <div
        class="menu-tile"
        *ngIf="(item.hidden && item.selected) || !item.hidden"
        [ngClass]="{ selected: item.selected, 'coming-soon': item.comingSoon }"
        (click)="selectItem(item)"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <mat-icon
          class="icon"
          fontSet="fal"
          [fontIcon]="'fa-' + item.icon"
          *ngIf="!item.customIcon"
        ></mat-icon>
        <mat-icon
          class="zhuri-logo"
          [svgIcon]="item.icon"
          *ngIf="item.customIcon"
        ></mat-icon>
        <div class="label">{{ item.label }}</div>
      </div>
      <div class="hr" *ngIf="item.hidden && item.selected"></div>
    </ng-container>
  </div>
</div>
