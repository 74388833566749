import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'ee-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {
  @ViewChild(PerfectScrollbarDirective)
  scrollContainer: PerfectScrollbarDirective;

  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      console.log('scroll container', this.scrollContainer);
      this.scrollContainer.update();
      this.scrollContainer.config.suppressScrollY = false;
    });
  }

  close(val: boolean) {
    this.dialogRef.close(val);
  }
}
