import { Injectable } from '@angular/core';
import { MapObject } from '../../interfaces/map-object';
import { MapboxService } from '../mapbox/mapbox.service';
import { MapEditMode } from '../../enums/map-edit-mode';
import { first } from 'rxjs/operators';
import { Layer } from 'mapbox-gl';
import { MapImage } from '../../interfaces/map-image';
import { environment } from '../../../../environments/environment';
import { TileyService } from '../tiley/tiley.service';

@Injectable({
  providedIn: 'root'
})
export class MapInspectorService {
  private readonly markerImage: MapImage = {
    id: 'map-inspector-marker',
    url: environment.assetsFolder + 'map-inspector-marker.png'
  };

  private readonly mapInspectorLayerId = 'map-inspector-layer';
  private layers: Layer[] = [
    {
      id: this.mapInspectorLayerId,
      type: 'symbol',
      layout: {
        'icon-image': this.markerImage.id,
        'icon-size': 1,
        'icon-anchor': 'bottom'
      }
    }
  ];

  constructor(
    private mapboxService: MapboxService,
    private tileyService: TileyService
  ) {}

  enable(mapObject: MapObject) {
    mapObject.mapInspectorObject.visible = true;
    mapObject.mapInspectorObject.onVisibilityChange.next(
      mapObject.mapInspectorObject.visible
    );

    this.enableMapEditMode(mapObject);

    this.loadImages(mapObject, () => {
      this.registerMapClickEvent(mapObject);
    });
  }

  disable(mapObject: MapObject) {
    if (mapObject && mapObject.mapInspectorObject) {
      mapObject.mapInspectorObject.visible = false;
      mapObject.mapInspectorObject.onVisibilityChange.next(
        mapObject.mapInspectorObject.visible
      );

      this.unregisterEvents(mapObject);
      this.removeMapMarker(mapObject);
      this.disableMapEditMode(mapObject);
    }
  }

  private loadImages(mapObject: MapObject, callback: () => void) {
    this.mapboxService.loadImages(mapObject, [this.markerImage], callback);
  }

  private registerMapClickEvent(mapObject: MapObject) {
    if (
      mapObject &&
      mapObject.mapInspectorObject &&
      !mapObject.mapInspectorObject.onMapClick
    ) {
      mapObject.mapInspectorObject.onMapClick = e => {
        this.onMapClick(mapObject, e);
      };

      mapObject.map.on('click', mapObject.mapInspectorObject.onMapClick);
    }
  }

  private unregisterEvents(mapObject: MapObject) {
    if (
      mapObject &&
      mapObject.mapInspectorObject &&
      mapObject.mapInspectorObject.onMapClick
    ) {
      mapObject.map.off('click', mapObject.mapInspectorObject.onMapClick);
      mapObject.mapInspectorObject.onMapClick = null;
    }
  }

  private enableMapEditMode(mapObject: MapObject) {
    if (mapObject) {
      mapObject.map.getCanvas().style.cursor = 'crosshair';
      this.mapboxService.enableEditMode(mapObject, MapEditMode.INSPECTOR_TOOL);
    }
  }

  private disableMapEditMode(mapObject: MapObject) {
    if (mapObject) {
      this.mapboxService.disableEditMode(mapObject);
    }
  }

  private onMapClick(mapObject: MapObject, e: any) {
    if (e.lngLat) {
      const coords: [number, number] = [e.lngLat.lng, e.lngLat.lat];

      mapObject.mapInspectorObject.marker = {
        geometry: {
          type: 'Point',
          coordinates: [e.lngLat.lng, e.lngLat.lat]
        }
      };

      this.updateMapMarker(mapObject);
      this.performGeoreference(mapObject, coords);
    }
  }

  private performGeoreference(mapObject: MapObject, coords: [number, number]) {
    this.tileyService
      .doGeoreference(mapObject.mapInspectorObject.marker.geometry, [
        'governmentSchools',
        'nonGovernmentSchools'
      ])
      .pipe(first())
      .subscribe(result => {
        if (result) {
          result.coordinates = coords;
          mapObject.mapInspectorObject.onGeoreferenceUpdate.next(result);
        }
      });
  }

  private updateMapMarker(mapObject: MapObject) {
    this.mapboxService.updateGeoJsonDataLayer(
      mapObject,
      this.mapInspectorLayerId,
      [mapObject.mapInspectorObject.marker],
      null,
      this.layers
    );
    this.createMarkerEvents(mapObject);
  }

  private createMarkerEvents(mapObject: MapObject) {
    // Mouse down event
    if (!mapObject.mapInspectorObject.onMarkerMouseDown) {
      const markerMouseDownEvent = (e: any) => {
        // Prevent map from panning while dragging
        e.preventDefault();
        mapObject.mapInspectorObject.isDragging = true;
      };

      mapObject.map.on(
        'mousedown',
        this.mapInspectorLayerId,
        markerMouseDownEvent
      );
      mapObject.mapInspectorObject.onMarkerMouseDown = markerMouseDownEvent;
    }

    // Mouse up event
    if (!mapObject.mapInspectorObject.onMarkerMouseUp) {
      const markerMouseUpEvent = e => {
        if (mapObject.mapInspectorObject.isDragging) {
          mapObject.mapInspectorObject.isDragging = false;
          this.performGeoreference(mapObject, [e.lngLat.lng, e.lngLat.lat]);
        }
      };

      mapObject.map.on('mouseup', markerMouseUpEvent);
      mapObject.mapInspectorObject.onMarkerMouseUp = markerMouseUpEvent;
    }

    // (MAP) Mouse move event
    if (!mapObject.mapInspectorObject.onMapMouseMove) {
      const mapMouseMoveEvent = e => {
        if (
          mapObject.mapInspectorObject.isDragging &&
          mapObject.mapInspectorObject.marker
        ) {
          e.preventDefault();
          mapObject.mapInspectorObject.marker.geometry.coordinates = [
            e.lngLat.lng,
            e.lngLat.lat
          ];
          this.mapboxService.updateGeoJsonDataLayer(
            mapObject,
            this.mapInspectorLayerId,
            [mapObject.mapInspectorObject.marker],
            null,
            this.layers
          );
        }
      };

      mapObject.map.on('mousemove', mapMouseMoveEvent);
      mapObject.mapInspectorObject.onMapMouseMove = mapMouseMoveEvent;
    }

    // Mouse move event
    if (!mapObject.mapInspectorObject.onMarkerMouseMove) {
      const markerMouseMoveEvent = () => {
        mapObject.map.getCanvas().style.cursor = 'pointer';
      };

      mapObject.map.on(
        'mousemove',
        this.mapInspectorLayerId,
        markerMouseMoveEvent
      );
      mapObject.mapInspectorObject.onMarkerMouseMove = markerMouseMoveEvent;
    }

    // Mouse leave event
    if (!mapObject.mapInspectorObject.onMarkerMouseLeave) {
      const markerMouseLeaveEvent = () => {
        mapObject.map.getCanvas().style.cursor = 'crosshair';
      };

      mapObject.map.on(
        'mouseleave',
        this.mapInspectorLayerId,
        markerMouseLeaveEvent
      );
      mapObject.mapInspectorObject.onMarkerMouseLeave = markerMouseLeaveEvent;
    }

    // mapObject.map.getCanvas().style.cursor = 'pointer';
  }

  private removeMapMarker(mapObject: MapObject) {
    if (mapObject && mapObject.mapInspectorObject) {
      if (mapObject.mapInspectorObject.onMarkerMouseDown) {
        mapObject.map.off(
          'mousedown',
          this.mapInspectorLayerId,
          mapObject.mapInspectorObject.onMarkerMouseDown
        );
        mapObject.mapInspectorObject.onMarkerMouseDown = null;
      }

      if (mapObject.mapInspectorObject.onMarkerMouseUp) {
        mapObject.map.off(
          'mouseup',
          mapObject.mapInspectorObject.onMarkerMouseUp
        );
        mapObject.mapInspectorObject.onMarkerMouseUp = null;
      }

      if (mapObject.mapInspectorObject.onMapMouseMove) {
        mapObject.map.off(
          'mousemove',
          mapObject.mapInspectorObject.onMapMouseMove
        );
        mapObject.mapInspectorObject.onMapMouseMove = null;
      }

      if (mapObject.mapInspectorObject.onMarkerMouseMove) {
        mapObject.map.off(
          'mousemove',
          this.mapInspectorLayerId,
          mapObject.mapInspectorObject.onMarkerMouseMove
        );
        mapObject.mapInspectorObject.onMarkerMouseMove = null;
      }

      if (mapObject.mapInspectorObject.onMarkerMouseLeave) {
        mapObject.map.off(
          'mouseleave',
          this.mapInspectorLayerId,
          mapObject.mapInspectorObject.onMarkerMouseLeave
        );
        mapObject.mapInspectorObject.onMarkerMouseLeave = null;
      }

      this.mapboxService.updateGeoJsonDataLayer(
        mapObject,
        this.mapInspectorLayerId,
        null,
        null,
        this.layers
      );
    }
  }
}
