import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { MaintenanceModeService } from '../../services/maintenance-mode/maintenance-mode.service';
import { MaintenanceModeSettings } from '../../interfaces/maintenance-mode-settings';
import { first, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutes } from '../../enums/app-routes';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

interface CloudinaryResponse {
  resources: Array<{
    public_id: string;
    context?: {
      custom?: {
        school?: string;
        blurb?: string;
      };
    };
  }>;
}

@Component({
  selector: 'ee-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  readonly appVersion = environment.appVersion;
  readonly currentDate = new Date();

  maintenanceModeSettings: MaintenanceModeSettings = null;
  maintenanceOverride = false;

  // Properties for the splash image and metadata
  splashImageUrl: string = '';
  splashSchool?: string;
  splashBlurb?: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private maintenanceModeService: MaintenanceModeService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.checkMaintenanceOverride();
    this.getMaintenanceModeSettings();
    this.getSplashImage();
  }

  isDecember(): boolean {
    //If only for Dec
    // const currentMonth = new Date().getMonth() + 1;
    // return currentMonth === 12;
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();

    // Display the snow component from December 1st to January 14th
    return (
      (currentMonth === 12 && currentDay >= 1) ||
      (currentMonth === 1 && currentDay <= 14)
    );
  }

  private getMaintenanceModeSettings() {
    this.maintenanceModeService
      .getMaintenanceModeSettings()
      .pipe(
        first(),
        tap(response => {
          if (
            response &&
            response.maintenanceModeActive &&
            !this.maintenanceOverride
          ) {
            this.router.navigate([AppRoutes.MAINTENANCE]);
          }
        })
      )
      .subscribe(response => {
        this.maintenanceModeSettings = response;
      });
  }

  private checkMaintenanceOverride() {
    if (
      this.route &&
      this.route.snapshot.queryParams &&
      this.route.snapshot.queryParams.override
    ) {
      this.maintenanceOverride = this.route.snapshot.queryParams.override;
    }
  }

  private getSplashImage() {
    const url = 'https://res.cloudinary.com/mappy-mochi/image/list/splash.json';
    this.http.get<CloudinaryResponse>(url).subscribe({
      next: data => {
        const resources = data.resources;
        if (resources && resources.length > 0) {
          const randomIndex = Math.floor(Math.random() * resources.length);
          const metadata = resources[randomIndex];
          // Build the transformed image URL (width 3000px and quality 70%)
          this.splashImageUrl = `https://res.cloudinary.com/mappy-mochi/image/upload/w_3000/q_70/${metadata.public_id}`;
          // Optionally, store additional metadata
          this.splashSchool = metadata.context?.custom?.school;
          this.splashBlurb = metadata.context?.custom?.blurb;
        }
      },
      error: error => {
        console.error('Error fetching splash image:', error);
      }
    });
  }

  login() {
    this.authService.redirectToSSOLoginPage();
  }

  requestAccess() {
    const link = document.createElement('a');
    link.href =
      'mailto:sci@det.nsw.edu.au?subject=Eagle%20Eye%20-%20Request%20Access';
    link.click();
    link.remove();
  }
}
