// name used for search api call
export enum MapSearchItem {
  // use mapbox search api
  LOCATION = 'location',
  COORDINATES = 'coordinates',
  // Item names used for tiley search api - not tags
  LGA = 'lga',
  GOV_SCHOOL = 'gov_school',
  NON_GOV_SCHOOL = 'non_gov_school',
  NEW_SCHOOL = 'new_school',
  UPGRADE_SCHOOL = 'upgrade_school',
  CLOSED_GOV_SCHOOL = 'closed_gov_school',
  CATCHMENT = 'catchment',
  CLUSTER = 'cluster',
  PLANNED_AREA_PRECINT = 'planned_area',
  STATE_ELECTORATE_DISTRICTS = 'sed',
  DET_REGIONS = 'det_region',
  SA1_BOUNDARIES = 'sa1',
  SA2_BOUNDARIES = 'sa2',
  SA3_BOUNDARIES = 'sa3',
  SA4_BOUNDARIES = 'sa4',
  OPERATIONAL_DIRECTORATES = 'performance_directorate',
  PRINCIPAL_NETWORKS = 'principal_network',
  DPIE_REGIONS = 'dpie_region',
  MESH_BLOCKS = 'mesh_block',
  CADASTRE_LOTS = 'cadastre'
}
