import { Injectable } from '@angular/core';
import { Point } from 'geojson';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { MapReferenceLayer } from 'src/app/shared/interfaces/map-reference-layer';
import { PropertyFilterValue } from 'src/app/shared/interfaces/map-reference-layer-property-filter';
import { SpatialFilterSetValue } from 'src/app/shared/interfaces/map-reference-layer-spatial-filter';
import { MapLayerActionType } from '../../../shared/enums/map-layer-action-type';
import { MapReferenceLayerDefs } from '../../../shared/enums/map-reference-layer-defs';
import { MapObject } from '../../../shared/interfaces/map-object';
import { MapReferenceLayersService } from '../../../shared/services/map-reference-layers/map-reference-layers.service';
import { MapboxService } from '../../../shared/services/mapbox/mapbox.service';
import { TileyService } from '../../../shared/services/tiley/tiley.service';
import { HubSchoolLevel } from '../../enums/hub-school-level';
import { HubSchoolData } from '../../interfaces/hub-school-data';
import { LayoutSpecification, PaintSpecification } from 'mapbox-gl';
import { Map3dViewService } from 'src/app/shared/services/map-3d-view/map-3d-view.service';

@Injectable({
  providedIn: 'root'
})
export class HubSchoolViewMappingService {
  private mapReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private mapObject: MapObject = null;
  private schoolData: HubSchoolData = null;
  private enrolmentYear: number = null;
  private spatialFilterSetValue: SpatialFilterSetValue = null;

  constructor(
    private mapboxService: MapboxService,
    private mapReferenceLayerService: MapReferenceLayersService,
    private map3dViewService: Map3dViewService,
    private tileyService: TileyService
  ) {}

  setMapObject(mapObject: MapObject) {
    if (!this.mapObject) {
      this.mapObject = mapObject;

      this.mapReady.next(true);
    }
  }

  onMapReady(): BehaviorSubject<boolean> {
    return this.mapReady;
  }

  setView(schoolData: HubSchoolData) {
    this.schoolData = schoolData;
    const { schoolId, isGovSchool, schoolStatus } = schoolData.general;
    const isSchoolClosed = ['Closed', 'Recess'].includes(schoolStatus);

    // Determine which layers to show based on school type and status
    // non gov school => show non gov school layer
    // gov school, closed => show closed school layer and site boundary
    // gov school, active => show all related school layers, catchment layer, and site boundary
    const { schoolLayers, catchmentLayers } = this.getLayersForSchool(
      isGovSchool,
      isSchoolClosed
    );

    // Add school view layers and apply property filter (schoolId) to school layers
    if (schoolLayers && schoolLayers.length > 0) {
      schoolLayers.forEach(layer => {
        this.mapReferenceLayerService.addVectorLayer(
          this.mapObject,
          layer,
          MapLayerActionType.PROGRAMMATIC
        );
        this.mapReferenceLayerService.applyPropertyFilter(
          this.mapObject,
          layer,
          {
            field: layer.idColumn,
            operator: '==',
            value: schoolId
          }
        );
      });
    }

    if (catchmentLayers && catchmentLayers.length > 0) {
      catchmentLayers.forEach(layer => {
        this.mapReferenceLayerService.addVectorLayer(
          this.mapObject,
          layer,
          MapLayerActionType.PROGRAMMATIC
        );
        this.mapReferenceLayerService.applyPropertyFilter(
          this.mapObject,
          layer,
          {
            field: 'school_id',
            operator: '==',
            value: schoolId
          }
        );
      });
      // Create spatial filter based on catchment complete utility layer since the spatial filter only supports one
      const catchmentUtilityLayer =
        this.mapReferenceLayerService.getLayerByLayerName(
          this.mapObject,
          MapReferenceLayerDefs.SCHOOL_CATCHMENTS_COMPLETE_UTILITY
        );
      this.spatialFilterSetValue = {
        type: 'feature',
        baseLayer: {
          layer: catchmentUtilityLayer,
          value: schoolId,
          field: 'school_id' // since we changed the idColumn from school_id to catchment_uid
        }
      };
    } else {
      // otherwise the spatial filter is null
      this.spatialFilterSetValue = null;
    }

    // Handle all initialized layers that aren't part of the school view core layers
    // if they are not user enabled, remove them
    // if they are user enabled, apply spatial filter (if spatial filter is null, the filter will be removed)
    this.mapReferenceLayerService
      .getAllInitialisedLayers(this.mapObject)
      .filter(layer => ![...schoolLayers, ...catchmentLayers].includes(layer))
      .forEach(layer => {
        if (!layer.userEnabled) {
          this.mapReferenceLayerService.removeVectorLayer(
            this.mapObject,
            layer
          );
        } else {
          this.mapReferenceLayerService.applySpatialPropertyFilter(
            this.mapObject,
            layer,
            this.spatialFilterSetValue
          );
        }
      });

    // zoom to school location
    this.panAndZoomToSchool();
  }

  private getLayersForSchool(isGovSchool: boolean, isSchoolClosed: boolean) {
    let schoolLayers: MapReferenceLayer[] = [];
    let catchmentLayers: MapReferenceLayer[] = [];
    if (!isGovSchool) {
      schoolLayers.push(
        this.mapReferenceLayerService.getLayerByLayerName(
          this.mapObject,
          MapReferenceLayerDefs.NON_GOV_SCHOOLS
        )
      );
    } else {
      const siteBoundaryLayer =
        this.mapReferenceLayerService.getLayerByLayerName(
          this.mapObject,
          MapReferenceLayerDefs.SITE_BOUNDARY
        );
      // for 3d views
      if (this.map3dViewService.is3dViewEnabled()) {
        schoolLayers.push(
          this.mapReferenceLayerService.getLayerByLayerName(
            this.mapObject,
            MapReferenceLayerDefs.SCHOOLS_3D
          )
        );
      }
      if (isSchoolClosed) {
        const closedSchoolLayer =
          this.mapReferenceLayerService.getLayerByLayerName(
            this.mapObject,
            MapReferenceLayerDefs.CLOSED_GOV_SCHOOLS
          );
        schoolLayers.push(closedSchoolLayer, siteBoundaryLayer);
      } else {
        schoolLayers.push(
          siteBoundaryLayer,
          this.mapReferenceLayerService.getLayerByLayerName(
            this.mapObject,
            MapReferenceLayerDefs.GOV_SCHOOLS
          ),
          this.mapReferenceLayerService.getLayerByLayerName(
            this.mapObject,
            MapReferenceLayerDefs.SCHOOL_UPGRADES
          ),
          this.mapReferenceLayerService.getLayerByLayerName(
            this.mapObject,
            MapReferenceLayerDefs.NEW_SCHOOLS
          ),
          this.mapReferenceLayerService.getLayerByLayerName(
            this.mapObject,
            MapReferenceLayerDefs.NEW_PRESCHOOLS
          )
        );
        const schoolCatchments = this.getSchoolCatchmentLayers().map(
          layerName =>
            this.mapReferenceLayerService.getLayerByLayerName(
              this.mapObject,
              layerName
            )
        );
        catchmentLayers.push(...schoolCatchments);
      }
    }
    return { schoolLayers, catchmentLayers };
  }

  clearView() {
    if (this.schoolData) {
      this.mapReferenceLayerService
        .getAllInitialisedLayers(this.mapObject)
        .forEach(layer =>
          this.mapReferenceLayerService.clearPropertyFilter(
            this.mapObject,
            layer
          )
        );
    }
    this.schoolData = null;
  }

  getSpatialFilterSetValue() {
    return this.spatialFilterSetValue;
  }

  private panAndZoomToSchool() {
    const point: Point = this.schoolData.general.schoolLocation as Point;

    if (point) {
      this.mapboxService.flyTo(
        this.mapObject,
        [point.coordinates[0], point.coordinates[1]],
        16
      );
    }
  }

  //Early Childhood Tab Changes
  // Display all early childhood locations in the adjacent sa2 area.
  showECOLayers(sa2Name: string, sa2Code: number) {
    const ecoLayer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.EARLY_CHILDHOOD_SITES_SERVICE_TYPE
    );
    const sa2Layer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.SA2_BOUNDARIES
    );
    // add sa2 and eco layers first
    this.mapReferenceLayerService.addVectorLayer(
      this.mapObject,
      sa2Layer,
      MapLayerActionType.PROGRAMMATIC
    );
    this.mapReferenceLayerService.addVectorLayer(
      this.mapObject,
      ecoLayer,
      MapLayerActionType.PROGRAMMATIC
    );
    // apply filters
    const sa2Filter: PropertyFilterValue = {
      field: 'sa2_name_2021',
      operator: '==',
      value: sa2Name
    };
    const ecoFilter: PropertyFilterValue = {
      operator: 'AND',
      conditions: [
        {
          field: 'service_status',
          operator: '==',
          value: 'Approved'
        },
        {
          field: 'sa2_code_2021',
          operator: '==',
          value: sa2Code
        }
      ]
    };
    this.mapReferenceLayerService.applyPropertyFilter(
      this.mapObject,
      sa2Layer,
      sa2Filter
    );
    this.mapReferenceLayerService.toggleSpatialFilter(
      this.mapObject,
      ecoLayer,
      'off'
    );
    this.mapReferenceLayerService.applyPropertyFilter(
      this.mapObject,
      ecoLayer,
      ecoFilter
    );
  }

  //Early Childhood Tab Changes
  // Hide all early childhood locations in the adjacent sa2 area.
  // SA2 boundaries will be removed and the spatial filter (link to school catchment) will be turned on for the eco layer.
  hideECOLayers() {
    const sa2Layer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.SA2_BOUNDARIES
    );
    const ecoLayer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.EARLY_CHILDHOOD_SITES_SERVICE_TYPE
    );
    this.mapReferenceLayerService.toggleSpatialFilter(
      this.mapObject,
      ecoLayer,
      'on'
    );
    this.mapReferenceLayerService.removeVectorLayer(this.mapObject, sa2Layer);
  }

  private getSchoolCatchmentLayers(): MapReferenceLayerDefs[] {
    const layers: MapReferenceLayerDefs[] = [];

    if (this.schoolData.general.isGovSchool) {
      if (this.schoolData.general.levelOfSchooling === 'Primary') {
        layers.push(MapReferenceLayerDefs.PRIMARY_CATCHMENT);
        if (this.schoolData.general.hasFutureCatchment) {
          layers.push(MapReferenceLayerDefs.PRIMARY_CATCHMENT_PROPOSED);
        }
      } else if (this.schoolData.general.levelOfSchooling === 'Secondary') {
        if (this.schoolData.general.schoolPopulationGender === 'Coed') {
          layers.push(MapReferenceLayerDefs.SECONDARY_COED_CATCHMENT);
          if (this.schoolData.general.hasFutureCatchment) {
            layers.push(
              MapReferenceLayerDefs.SECONDARY_COED_CATCHMENT_PROPOSED
            );
          }
        } else if (this.schoolData.general.schoolPopulationGender === 'Girls') {
          layers.push(MapReferenceLayerDefs.SECONDARY_GIRLS_CATCHMENT);
          if (this.schoolData.general.hasFutureCatchment) {
            layers.push(
              MapReferenceLayerDefs.SECONDARY_GIRLS_CATCHMENT_PROPOSED
            );
          }
        } else if (this.schoolData.general.schoolPopulationGender === 'Boys') {
          layers.push(MapReferenceLayerDefs.SECONDARY_BOYS_CATCHMENT);
          if (this.schoolData.general.hasFutureCatchment) {
            layers.push(
              MapReferenceLayerDefs.SECONDARY_BOYS_CATCHMENT_PROPOSED
            );
          }
        }
      } else if (
        this.schoolData.general.levelOfSchooling === 'Central/Community' ||
        this.schoolData.general.levelOfSchooling === 'Central'
      ) {
        layers.push(
          MapReferenceLayerDefs.PRIMARY_CENTRAL_CATCHMENT,
          MapReferenceLayerDefs.SECONDARY_CENTRAL_CATCHMENT
        );
        if (this.schoolData.general.hasFutureCatchment) {
          layers.push(
            MapReferenceLayerDefs.PRIMARY_CENTRAL_CATCHMENT_PROPOSED,
            MapReferenceLayerDefs.SECONDARY_CENTRAL_CATCHMENT_PROPOSED
          );
        }
      } else if (this.schoolData.general.levelOfSchooling === 'Infants') {
        layers.push(MapReferenceLayerDefs.INFANTS_CATCHMENT);
        if (this.schoolData.general.hasFutureCatchment) {
          layers.push(MapReferenceLayerDefs.INFANTS_CATCHMENT_PROPOSED);
        }
      }
    }

    return layers;
  }

  zoomToFitCatchmentBoundary(schoolId: number) {
    // Dataset is the same for all catchments
    const layer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.PRIMARY_CATCHMENT
    );

    // Fit catchment boundary to map view
    this.tileyService
      .getBoundingBox(layer.datasetName, layer.idColumn, String(schoolId))
      .pipe(first())
      .subscribe(response => {
        if (response && response.boundingBox) {
          this.mapboxService.fitToViewFeatures(this.mapObject.map, [
            { geometry: response.boundingBox }
          ]);
        }
      });
  }

  showEnrolmentLocations(
    schoolId: number,
    year: number,
    schoolLevel: HubSchoolLevel
  ) {
    const layer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.ENROLMENT_LOCATIONS
    );
    const baseName = layer.datasetName.replace(/_\d{4}$/, '');
    // remove previous year's data
    this.mapReferenceLayerService.removeVectorLayer(this.mapObject, layer);
    this.mapReferenceLayerService.addVectorLayer(
      this.mapObject,
      layer,
      MapLayerActionType.PROGRAMMATIC,
      {
        datasetName: `${baseName}_${year}`,
        layerName: layer.layerName,
        extraColumns: layer.extraColumns,
        baseFilter: layer.baseFilter
      }
    );
    const idColumn = layer.idColumn;

    // Update dataset name to point to the correct year (default 2008)
    this.enrolmentYear = year;

    const schoolLevelFilter =
      schoolLevel === HubSchoolLevel.PRIMARY
        ? {
            operator: '==',
            field: 'stage',
            value: 'Primary'
          }
        : schoolLevel === HubSchoolLevel.SECONDARY
          ? {
              operator: '==',
              field: 'stage',
              value: 'Secondary'
            }
          : null;
    const schoolIdFilter = {
      operator: '==',
      field: idColumn,
      value: schoolId
    };
    const filter = schoolLevelFilter
      ? {
          operator: 'AND',
          conditions: [schoolLevelFilter, schoolIdFilter]
        }
      : schoolIdFilter;
    this.mapReferenceLayerService.applyPropertyFilter(
      this.mapObject,
      layer,
      filter as PropertyFilterValue
    );
  }

  hideEnrolmentLocations() {
    const layer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.ENROLMENT_LOCATIONS
    );
    // remove source that comes with year
    if (this.enrolmentYear) {
      const sourceName = `${layer.layerName}_${this.enrolmentYear}`;
      if (this.mapObject.map.getSource(sourceName)) {
        this.mapObject.map.removeSource(sourceName);
      }
    }
    this.mapReferenceLayerService.removeVectorLayer(this.mapObject, layer);
    this.enrolmentYear = null;
  }

  private updateYearDefinition(styling: unknown, year: number) {
    // if the styling is not an object, then return as it is
    if (typeof styling !== 'object') {
      return styling;
    }
    const stylingString = JSON.stringify(styling);
    if (!stylingString.includes('cum_')) {
      return styling;
    }
    return JSON.parse(
      JSON.stringify(styling).replace(/cum_\d+/g, `cum_${year}`)
    );
  }

  showHousingProjectionUtilities(
    schoolId: number,
    year: number,
    schoolLevel: HubSchoolLevel
  ) {
    const layer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.UTILITY_HOUSING_SUPPLY_FORECAST
    );
    this.mapReferenceLayerService.addVectorLayer(this.mapObject, layer);
    // update layerStyling (column cum_<year>)
    if (layer.hasTooltip) {
      layer.tooltipText = layer.tooltipText.replace(/cum_\d*/g, 'cum_' + year);
    }
    layer.layerStyling.forEach(layerStyle => {
      // The filter is tricky because the layer definition is used - so need to update layer definition
      // the actual layer filter also includes other filters (like spatial filter)
      // so have to update layer definition, as well as update the year in the filter manually
      if (layerStyle.filter) {
        layerStyle.filter = ['has', `cum_${year}`];
        const filterValue = this.mapObject.map.getFilter(
          layerStyle.id.toString()
        );
        const updatedFilterValue = this.updateYearDefinition(filterValue, year);
        this.mapObject.map.setFilter(
          layerStyle.id.toString(),
          updatedFilterValue
        );
      }

      if (layerStyle.paint) {
        Object.keys(layerStyle.paint).forEach(key => {
          const updatedPaint = this.updateYearDefinition(
            layerStyle.paint[key],
            year
          );
          layerStyle.paint[key] = updatedPaint;
          this.mapObject.map.setPaintProperty(
            layerStyle.id.toString(),
            key as keyof PaintSpecification,
            updatedPaint
          );
        });
      }
      if (layerStyle.layout) {
        Object.keys(layerStyle.layout).forEach(key => {
          const updateLayout = this.updateYearDefinition(
            layerStyle.layout[key],
            year
          );
          layerStyle.layout[key] = updateLayout;
          this.mapObject.map.setLayoutProperty(
            layerStyle.id.toString(),
            key as keyof LayoutSpecification,
            updateLayout
          );
        });
      }
    });
    // Apply catchment filter based on school level
    // This is happening when the layer is firstly added, it won't be triggered again when switching years
    if (!layer.spatialFilter) {
      const catchmentLayer = this.mapReferenceLayerService.getLayerByLayerName(
        this.mapObject,
        MapReferenceLayerDefs.SCHOOL_CATCHMENTS_COMPLETE_UTILITY
      );

      const catchmentFilterValue: SpatialFilterSetValue = {
        type: 'feature',
        baseLayer: {
          layer: catchmentLayer,
          value: schoolId,
          field: 'school_id'
        }
      };
      this.mapReferenceLayerService.applySpatialPropertyFilter(
        this.mapObject,
        layer,
        catchmentFilterValue
      );
    }
  }

  hideHousingProjectionUtilities() {
    const layer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.UTILITY_HOUSING_SUPPLY_FORECAST
    );
    this.mapReferenceLayerService.removeVectorLayer(this.mapObject, layer);
  }

  addECOLayers() {
    if (this.onMapReady) {
      const layer = this.mapReferenceLayerService.getLayerByLayerName(
        this.mapObject,
        MapReferenceLayerDefs.EARLY_CHILDHOOD_SITES_SERVICE_TYPE
      );
      // apply spatial filter to match it to the primary catchment
      const catchmentLayer = this.mapReferenceLayerService.getLayerByLayerName(
        this.mapObject,
        MapReferenceLayerDefs.PRIMARY_CATCHMENT
      );
      const filterValue: SpatialFilterSetValue = {
        type: 'feature',
        baseLayer: {
          layer: catchmentLayer,
          value: this.schoolData.general.schoolId,
          field: 'school_id'
        }
      };
      this.mapReferenceLayerService.addVectorLayer(
        this.mapObject,
        layer,
        MapLayerActionType.PROGRAMMATIC
      );
      this.mapReferenceLayerService.applySpatialPropertyFilter(
        this.mapObject,
        layer,
        filterValue
      );
    }
  }

  removeECOLayers() {
    const ecoLayer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.EARLY_CHILDHOOD_SITES_SERVICE_TYPE
    );
    const sa2Layer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.SA2_BOUNDARIES
    );
    this.mapReferenceLayerService.removeVectorLayer(this.mapObject, ecoLayer);
    this.mapReferenceLayerService.removeVectorLayer(this.mapObject, sa2Layer);
  }

  showFloorPlan(schoolId: number, level: string) {
    const layer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.SCHOOL_FLOORPLANS
    );
    const filter: PropertyFilterValue = {
      operator: 'AND',
      conditions: [
        {
          field: 'school_id',
          operator: '==',
          value: schoolId
        },
        {
          field: 'level',
          operator: '==',
          value: level
        }
      ]
    };
    this.mapReferenceLayerService.addVectorLayer(
      this.mapObject,
      layer,
      MapLayerActionType.PROGRAMMATIC
    );
    this.mapReferenceLayerService.applyPropertyFilter(
      this.mapObject,
      layer,
      filter
    );
  }

  hideFloorPlan() {
    const layer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.SCHOOL_FLOORPLANS
    );
    this.mapObject.map.easeTo({ pitch: 0 });
    this.mapReferenceLayerService.removeVectorLayer(this.mapObject, layer);
  }

  zoomToFitFloorPlan(schoolId: number) {
    const { datasetName, idColumn } =
      this.mapReferenceLayerService.getLayerByLayerName(
        this.mapObject,
        MapReferenceLayerDefs.SITE_BOUNDARY
      );
    this.tileyService
      .getBoundingBox(datasetName, idColumn, schoolId)
      .pipe(first())
      .subscribe(response => {
        if (response && response.boundingBox) {
          this.mapboxService.fitToViewFeatures(
            this.mapObject.map,
            [{ geometry: response.boundingBox }],
            true,
            this.mapObject.map.getPitch() < 60 ? 60 : null
          );
        }
      });
  }

  showHistoricalActualCatchment(catchmentIds: number[]) {
    const layer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.CATCHMENT_HISTORY_ACTUAL
    );
    const filter: PropertyFilterValue = {
      operator: 'in',
      field: 'catchment_uid',
      value: catchmentIds
    };
    this.mapReferenceLayerService.addVectorLayer(
      this.mapObject,
      layer,
      MapLayerActionType.PROGRAMMATIC
    );
    this.mapReferenceLayerService.applyPropertyFilter(
      this.mapObject,
      layer,
      filter
    );
  }

  hideHistoricalActualCatchment() {
    const layer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.CATCHMENT_HISTORY_ACTUAL
    );
    this.mapReferenceLayerService.removeVectorLayer(this.mapObject, layer);
  }

  showHistoricalFutureCatchment(catchmentIds: number[]) {
    const layer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.CATCHMENT_HISTORY_FUTURE
    );
    const filter: PropertyFilterValue = {
      operator: 'in',
      field: 'catchment_future_uid',
      value: catchmentIds
    };
    this.mapReferenceLayerService.addVectorLayer(
      this.mapObject,
      layer,
      MapLayerActionType.PROGRAMMATIC
    );
    this.mapReferenceLayerService.applyPropertyFilter(
      this.mapObject,
      layer,
      filter
    );
  }

  hideHistoricalFutureCatchment() {
    const layer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.CATCHMENT_HISTORY_FUTURE
    );
    this.mapReferenceLayerService.removeVectorLayer(this.mapObject, layer);
  }

  // isSchoolOverlapping(schoolId: number): Observable<boolean> {
  //   const url = `/api/school-overlaps/${schoolId}`; // Adjust the endpoint as per your backend
  //   return this.http.get<boolean>(url);
  // }
}
