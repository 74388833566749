<div>
  <button mat-icon-button class="close-x-button" (click)="close()">
    <mat-icon fontSet="far" fontIcon="fa-times"></mat-icon>
  </button>

  <h1 mat-dialog-title>Import Map Data</h1>

  <div mat-dialog-content>
    <div
      [ngClass]="{ 'is-dragging': isDragging }"
      class="upload-area"
      (dragenter)="uploadDragEnter($event)"
      (dragover)="uploadDragOver($event)"
      (dragleave)="uploadDragLeave($event)"
      (drop)="uploadDrop($event)"
    >
      <div class="content">
        <p>
          You can import features and view them in HUB by selecting a GeoJSON
          file or dragging and dropping them here.
        </p>
        <button mat-button class="storm-add-button" (click)="fileInput.click()">
          <mat-icon
            fontSet="far"
            [fontIcon]="selectedFile ? 'fa-file-code' : 'fa-plus'"
          ></mat-icon>
          <span>{{ selectedFile ? selectedFile : 'Import Feature' }}</span>
          <input
            #fileInput
            type="file"
            (change)="processFile($event.target.files)"
            style="display: none"
            accept=".geojson"
          />
        </button>
      </div>

      <div class="upload-message" *ngIf="isDragging">
        <mat-icon fontSet="fas" fontIcon="fa-cloud-upload"></mat-icon>
        Drag and drop files here.
      </div>
    </div>
  </div>

  <div mat-dialog-actions fxLayoutAlign="end end">
    <button mat-raised-button (click)="close()">Cancel</button>
    <button
      mat-raised-button
      class="key-action"
      mat-dialog-close="true"
      [disabled]="!fileSelected"
      (click)="import()"
    >
      Import
    </button>
  </div>
</div>
