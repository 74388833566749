import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  private readonly coreUrl =
    environment.backendServer.mainPath + environment.backendServer.eagleEye;

  constructor(private http: HttpClient) {}

  getLatestUpdateDates(): Observable<any> {
    const url = `${this.coreUrl}/latest-updates`;
    return this.http.get<any>(url);
  }
}
