import { Injectable } from '@angular/core';
import { MapObject } from '../../interfaces/map-object';
import { Layer } from 'mapbox-gl';
import { MapPsuedoLayers } from '../../enums/map-psuedo-layers';
import { MapboxService } from '../mapbox/mapbox.service';

@Injectable({
  providedIn: 'root'
})
export class MapContourToolService {
  private readonly contourSourceId = 'contour-layer-source';

  private contourLayers: Layer[] = [
    {
      id: 'contour-layer-contour-lines-casing',
      source: this.contourSourceId,
      'source-layer': 'contour',
      type: 'line',
      paint: {
        'line-color': 'hsla(0, 0, 100%, 0.6)',
        'line-width': 3
      }
    },
    {
      id: 'contour-layer-contour-lines',
      source: this.contourSourceId,
      'source-layer': 'contour',
      type: 'line',
      paint: {
        'line-color': 'rgba(201, 71, 245, 0.6)',
        'line-width': 1
      }
    },
    {
      id: 'contour-layer-contour-label',
      source: this.contourSourceId,
      'source-layer': 'contour',
      type: 'symbol',
      layout: {
        'text-field': ['concat', ['to-string', ['get', 'ele']], 'm'],
        'text-size': [
          'interpolate',
          ['exponential', 1.5],
          ['zoom'],
          0,
          8,
          16,
          14
        ],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-padding': 2,
        'symbol-avoid-edges': true,
        'symbol-placement': 'line'
      },
      paint: {
        'text-color': 'rgba(201, 71, 245, 0.8)',
        'text-halo-width': 1.5,
        'text-halo-blur': 0.5,
        'text-halo-color': 'hsla(0, 0%, 100%, 0.8)'
      }
    }
  ];

  constructor(private mapboxService: MapboxService) {}

  enable(mapObject: MapObject) {
    this.mapboxService.updateVectorDataLayer(
      mapObject,
      this.contourSourceId,
      'mapbox://mapbox.mapbox-terrain-v2',
      this.contourLayers,
      MapPsuedoLayers.PSUEDO_CONTOUR_LAYER
    );
    this.mapboxService.showLayers(mapObject, this.contourLayers);
  }

  disable(mapObject: MapObject) {
    this.mapboxService.hideLayers(mapObject, this.contourLayers);
  }
}
