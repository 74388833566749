import { Injectable } from '@angular/core';
import { HubViewType, HubViewTypeFeature } from '../../enums/hub-view-type';
import { MapReferenceLayerDefs } from '../../../shared/enums/map-reference-layer-defs';
import { MapObject } from '../../../shared/interfaces/map-object';
import { BehaviorSubject } from 'rxjs';
import { MapReferenceLayersService } from '../../../shared/services/map-reference-layers/map-reference-layers.service';
import { MapReferenceLayer } from '../../../shared/interfaces/map-reference-layer';
import { first } from 'rxjs/operators';
import { MapboxService } from '../../../shared/services/mapbox/mapbox.service';
import { ClusterType } from '../../enums/cluster-type';
import { TileyService } from '../../../shared/services/tiley/tiley.service';
import { MapLayerActionType } from '../../../shared/enums/map-layer-action-type';
import { MapMeasureToolsService } from '../../../shared/services/map-measure-tools/map-measure-tools.service';
import { HubCustomViewType } from '../../enums/hub-custom-view-type';
import { Geometry } from 'geojson';
import { SpatialFilterSetValue } from '../../../shared/interfaces/map-reference-layer-spatial-filter';
import { MapReachabilityService } from '../../../shared/services/map-reachability/map-reachability.service';
import { PropertyFilterValue } from '../../../shared/interfaces/map-reference-layer-property-filter';

@Injectable({
  providedIn: 'root'
})
export class HubMappingService {
  private mapReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private mapObject: MapObject = null;
  private activeLayer: MapReferenceLayer = null;
  private spatialFilterSetValue: SpatialFilterSetValue;
  private hoverLayer: MapReferenceLayer = null;

  constructor(
    private tileyService: TileyService,
    private mapboxService: MapboxService,
    private mapReferenceLayerService: MapReferenceLayersService,
    private mapMeasureToolsService: MapMeasureToolsService,
    private mapReachabilityService: MapReachabilityService
  ) {}

  setMapObject(mapObject: MapObject) {
    if (!this.mapObject) {
      this.mapObject = mapObject;

      this.mapReady.next(true);
    }
  }

  onMapReady(): BehaviorSubject<boolean> {
    return this.mapReady;
  }

  setView(
    viewType: HubViewType,
    clusterType?: ClusterType,
    customLayerName?: string
  ) {
    this.activeLayer = this.getLayerByViewType(
      viewType,
      clusterType,
      customLayerName
    );
    if (this.activeLayer) {
      this.mapReferenceLayerService
        .getAllInitialisedLayers(this.mapObject)
        .forEach(layer =>
          this.mapReferenceLayerService.clearSpatialFilter(
            this.mapObject,
            layer
          )
        );
    }
  }

  setNswView() {
    this.setView(HubViewType.NSW);
    this.addSchoolLayers();
    this.removeNonUserAddedExistingLayers();
    // reset map filters
    this.spatialFilterSetValue = null;
    // reset individual layer filters
    this.mapReferenceLayerService
      .getAllInitialisedLayers(this.mapObject)
      .forEach(layer => {
        this.mapReferenceLayerService.clearAllFilters(this.mapObject, layer);
      });
    // reset to home map view
    this.mapboxService.resetToHome(this.mapObject);
  }

  // layer based view
  setGeographicalView(
    viewType: HubViewTypeFeature,
    id: string,
    customLayerName?: string,
    clusterType?: ClusterType
  ) {
    const itemId = parseInt(id, 10) || id;
    this.setView(viewType, clusterType, customLayerName);
    if (this.activeLayer) {
      this.addSchoolLayers(clusterType);
      this.addActiveLayer();
      this.removeNonUserAddedExistingLayers();
      // apply filters to all initialised layers
      this.spatialFilterSetValue = {
        type: 'feature',
        baseLayer: {
          layer: this.activeLayer,
          value: itemId
        }
      };

      this.mapReferenceLayerService
        .getAllInitialisedLayers(this.mapObject)
        .forEach(layer => {
          // for the active layers, add property filter
          if (layer.layerName === this.activeLayer.layerName) {
            this.mapReferenceLayerService.applyPropertyFilter(
              this.mapObject,
              layer,
              {
                field: layer.idColumn || 'tile_id',
                operator: '==',
                value: itemId
              }
            );
          } else {
            // otherwise set spatial filter
            this.mapReferenceLayerService.applySpatialPropertyFilter(
              this.mapObject,
              layer,
              this.spatialFilterSetValue
            );
          }
        });
      // Zoom to show active layer
      this.tileyService
        .getBoundingBox(
          this.activeLayer.datasetName,
          this.activeLayer.idColumn || 'tile_id',
          id
        )
        .pipe(first())
        .subscribe(response => {
          if (response && response.boundingBox) {
            this.mapboxService.fitToViewFeatures(this.mapObject.map, [
              { geometry: response.boundingBox }
            ]);
          }
        });
    }
  }

  setCustomView(polylineGeom: string, customViewType: HubCustomViewType) {
    this.setView(HubViewType.CUSTOM);

    // Convert encoded polyline to geojson geometry
    let geometry = null;

    // Apply filter to all reference layers
    if (customViewType === HubCustomViewType.POLYGON) {
      geometry =
        this.mapboxService.convertEncodedPolylineToPolygon(polylineGeom);
    } else if (customViewType === HubCustomViewType.RADIUS) {
      geometry =
        this.mapboxService.getCircleGeomFromRadiusEncodedPolyline(polylineGeom);
    }

    if (geometry) {
      this.addSchoolLayers();
      this.removeNonUserAddedExistingLayers();
      this.spatialFilterSetValue = {
        type: 'geometry',
        geometry
      };
      this.mapReferenceLayerService
        .getAllInitialisedLayers(this.mapObject)
        .forEach(layer =>
          this.mapReferenceLayerService.applySpatialPropertyFilter(
            this.mapObject,
            layer,
            this.spatialFilterSetValue
          )
        );
      // Zoom to show active layer
      this.mapboxService.fitToViewFeatures(this.mapObject.map, [
        { geometry: geometry }
      ]);
    }
  }

  setGeometryView(geometry: Geometry) {
    if (geometry) {
      this.setView(HubViewType.REACHABILITY);
      this.addSchoolLayers();
      this.removeNonUserAddedExistingLayers();
      this.spatialFilterSetValue = {
        type: 'geometry',
        geometry
      };
      this.mapReferenceLayerService
        .getAllInitialisedLayers(this.mapObject)
        .forEach(layer =>
          this.mapReferenceLayerService.applySpatialPropertyFilter(
            this.mapObject,
            layer,
            this.spatialFilterSetValue
          )
        );
      // Zoom to show active layer
      this.mapboxService.fitToViewFeatures(this.mapObject.map, [
        { geometry: geometry }
      ]);
    }
  }

  // start of aggregation used for hub-table components
  setAggViewType(isSummary: boolean, viewType: HubViewType) {
    this.clearAggViewType();

    if (isSummary) {
      this.hoverLayer = this.getLayerByViewType(viewType);
    } else {
      this.hoverLayer = null;
    }

    // Add hover layer
    if (this.hoverLayer && this.hoverLayer !== this.activeLayer) {
      this.mapReferenceLayerService.addVectorLayer(
        this.mapObject,
        this.hoverLayer,
        MapLayerActionType.PROGRAMMATIC
      );
      this.mapReferenceLayerService.applySpatialPropertyFilter(
        this.mapObject,
        this.hoverLayer,
        this.spatialFilterSetValue
      );
    }
  }

  clearAggViewType() {
    // Remove existing hover layer if exists
    if (this.hoverLayer && this.hoverLayer !== this.activeLayer) {
      this.mapReferenceLayerService.removeVectorLayer(
        this.mapObject,
        this.hoverLayer
      );
      this.hoverLayer = null;
    }
  }

  setFeatureHover(id: number) {
    if (this.hoverLayer) {
      this.mapReferenceLayerService.highlightFeature(
        this.mapObject,
        this.hoverLayer,
        id,
        this.hoverLayer.idColumn || 'tile_id'
      );
    } else {
      const schoolLayers = this.getSchoolLayers();
      schoolLayers.forEach(layer =>
        this.mapReferenceLayerService.highlightFeature(
          this.mapObject,
          layer,
          id,
          layer.idColumn || 'tile_id'
        )
      );
    }
  }

  setEcoFeatureHover(id: number) {
    if (this.hoverLayer) {
      this.mapReferenceLayerService.highlightFeature(
        this.mapObject,
        this.hoverLayer,
        id
      );
    } else {
      const ecoLayer = this.mapReferenceLayerService.getLayerByLayerName(
        this.mapObject,
        MapReferenceLayerDefs.EARLY_CHILDHOOD_SITES_SERVICE_TYPE
      );
      this.mapReferenceLayerService.highlightFeature(
        this.mapObject,
        ecoLayer,
        id
      );
    }
  }

  clearFeatureHover() {
    if (this.hoverLayer) {
      this.mapReferenceLayerService.clearHighlightFeature(
        this.mapObject,
        this.hoverLayer
      );
    } else {
      const schoolLayers = this.getSchoolLayers();
      schoolLayers.forEach(layer =>
        this.mapReferenceLayerService.clearHighlightFeature(
          this.mapObject,
          layer
        )
      );
    }
  }

  clearEcoFeatureHover() {
    if (this.hoverLayer) {
      this.mapReferenceLayerService.clearHighlightFeature(
        this.mapObject,
        this.hoverLayer
      );
    } else {
      const ecoLayer = this.mapReferenceLayerService.getLayerByLayerName(
        this.mapObject,
        MapReferenceLayerDefs.EARLY_CHILDHOOD_SITES_SERVICE_TYPE
      );
      this.mapReferenceLayerService.clearHighlightFeature(
        this.mapObject,
        ecoLayer
      );
    }
  }

  getSpatialFilterSetValue() {
    return this.spatialFilterSetValue;
  }

  private getSchoolLayers() {
    const govSchoolLayer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.GOV_SCHOOLS
    );
    const upgradeSchoolsLayer =
      this.mapReferenceLayerService.getLayerByLayerName(
        this.mapObject,
        MapReferenceLayerDefs.SCHOOL_UPGRADES
      );
    const newSchoolLayer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.NEW_SCHOOLS
    );
    const preSchoolLayer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.NEW_PRESCHOOLS
    );

    return [
      govSchoolLayer,
      upgradeSchoolsLayer,
      newSchoolLayer,
      preSchoolLayer
    ];
  }

  private addSchoolLayers(clusterType?: ClusterType) {
    const schoolLayers = this.getSchoolLayers();
    const schoolClusterFilterValue: PropertyFilterValue = clusterType
      ? clusterType === ClusterType.PRIMARY
        ? {
            field: 'school_level',
            operator: 'in',
            value: ['Primary', 'Public', 'Central']
          }
        : ClusterType.SECONDARY
          ? {
              field: 'school_level',
              operator: 'in',
              value: ['High', 'Central']
            }
          : null
      : null;
    // add layers
    for (let schoolLayer of schoolLayers) {
      if (!(schoolLayer.initialised && schoolLayer.userEnabled)) {
        this.mapReferenceLayerService.addVectorLayer(
          this.mapObject,
          schoolLayer,
          MapLayerActionType.PROGRAMMATIC
        );
        if (schoolClusterFilterValue) {
          this.mapReferenceLayerService.applyPropertyFilter(
            this.mapObject,
            schoolLayer,
            schoolClusterFilterValue
          );
        }
      }
    }
  }

  private addActiveLayer() {
    if (!this.activeLayer) return;
    if (!(this.activeLayer.initialised && this.activeLayer.userEnabled)) {
      this.mapReferenceLayerService.addVectorLayer(
        this.mapObject,
        this.activeLayer,
        MapLayerActionType.PROGRAMMATIC
      );
    }
  }

  // remove all layers except:
  // * school layer (handled by addSchoolLayers function)
  // * active Layer (handled by addActiveLayer function)
  // * 3d layer (if 3d enabled)
  // * other layers that enabled by user
  private removeNonUserAddedExistingLayers() {
    const initialisedLayers =
      this.mapReferenceLayerService.getAllInitialisedLayers(this.mapObject);
    const ref3dLayer = this.mapReferenceLayerService.getLayerByLayerName(
      this.mapObject,
      MapReferenceLayerDefs.SCHOOLS_3D
    );
    const schoolLayers = this.getSchoolLayers();
    for (let initialisedLayer of initialisedLayers) {
      if (
        initialisedLayer === this.activeLayer ||
        schoolLayers.includes(initialisedLayer) ||
        ref3dLayer.layerName === initialisedLayer.layerName ||
        initialisedLayer.userEnabled
      )
        continue;
      this.mapReferenceLayerService.removeVectorLayer(
        this.mapObject,
        initialisedLayer
      );
    }
  }

  clearViewType() {
    this.mapReferenceLayerService
      .getAllInitialisedLayers(this.mapObject)
      .forEach(layer => {
        this.mapReferenceLayerService.clearAllFilters(this.mapObject, layer);
      });
  }

  getLayerByViewType(
    viewType: HubViewType,
    clusterType?: ClusterType,
    customLayerName?: string
  ) {
    if (customLayerName) {
      return this.mapReferenceLayerService.getLayerByLayerName(
        this.mapObject,
        customLayerName
      );
    }

    let layerName: MapReferenceLayerDefs = null;
    let layer: MapReferenceLayer = null;

    switch (viewType) {
      case HubViewType.LGA:
        layerName = MapReferenceLayerDefs.LGAS;
        break;
      case HubViewType.FED:
        layerName = MapReferenceLayerDefs.FED_REGIONS;
        break;
      case HubViewType.SED:
        layerName = MapReferenceLayerDefs.SED_REGIONS;
        break;
      case HubViewType.DET:
        layerName = MapReferenceLayerDefs.DET_REGIONS;
        break;
      case HubViewType.PRINCIPAL_NETWORK:
        layerName = MapReferenceLayerDefs.PRINCIPAL_NETWORKS;
        break;
      case HubViewType.OPERATIONAL_DIRECTORATE:
        layerName = MapReferenceLayerDefs.OPERATIONAL_DIRECTORATES;
        break;
      case HubViewType.DPIE_REGION:
        layerName = MapReferenceLayerDefs.DPIE_REGIONS;
        break;
      case HubViewType.CLUSTER:
        switch (clusterType) {
          case ClusterType.PRIMARY:
            layerName = MapReferenceLayerDefs.PRIMARY_SCHOOL_CLUSTERS;
            break;
          case ClusterType.SECONDARY:
            layerName = MapReferenceLayerDefs.SECONDARY_SCHOOL_CLUSTERS;
            break;
          case ClusterType.SSP:
            layerName = MapReferenceLayerDefs.SSP_SCHOOL_CLUSTERS;
            break;
        }
        break;
    }

    if (layerName) {
      layer = this.mapReferenceLayerService.getLayerByLayerName(
        this.mapObject,
        layerName
      );
    }

    return layer;
  }

  createMeasurementFromEncodedPolyline(
    encodedPolyline: string,
    viewType: HubCustomViewType
  ) {
    this.mapMeasureToolsService.createAndAddNewMeasurement(
      this.mapObject,
      encodedPolyline,
      viewType
    );
  }

  createReachabilityResults(reachabilityOptions: any) {
    this.mapReachabilityService.createReachabilityResults(
      this.mapObject,
      reachabilityOptions
    );
  }

  triggerResize() {
    if (this.mapObject && this.mapObject.map) {
      this.mapObject.map.resize();
    }
  }
}
