<div
  class="instructions-container"
  fxLayoutAlign="center center"
  [ngClass]="{ hidden: !instructionsMessage.visible }"
>
  <div class="content" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon
      fontSet="far"
      [fontIcon]="'fa-' + instructionsMessage.icon"
    ></mat-icon>
    <div>{{ instructionsMessage.message }}</div>
  </div>
</div>
