import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MapSearchService } from '../../services/map-search/map-search.service';
import { MatSelectionList } from '@angular/material/list';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ee-map-advanced-search',
  templateUrl: './map-advanced-search.component.html',
  styleUrls: ['./map-advanced-search.component.scss']
})
export class MapAdvancedSearchComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('searchItemsList') private searchItemsList: MatSelectionList;
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  searchItems = [];
  selectedOptions = [];

  visible = false;

  constructor(private mapSearchService: MapSearchService) {}

  ngOnInit(): void {
    this.searchItems = this.mapSearchService.getSearchItems();
    this.selectedOptions = this.mapSearchService.getSelectedSearchItems();
    this.watchAdvancedSearchVisible();
  }

  ngAfterViewInit() {
    this.searchItemsList.focus();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private watchAdvancedSearchVisible() {
    this.mapSearchService
      .onAdvancedSearchVisibilityChange()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(visible => {
        this.visible = visible;
      });
  }

  onSelectionChange(): void {
    this.mapSearchService.setSelectedSearchItems(this.selectedOptions);
  }

  close() {
    this.mapSearchService.closeAdvancedSearch();
  }

  selectAll() {
    this.searchItemsList.selectAll();
    this.mapSearchService.setSelectedSearchItems(this.selectedOptions);
  }

  clearAll() {
    this.searchItemsList.deselectAll();
    this.mapSearchService.setSelectedSearchItems(this.selectedOptions);
  }
}
