import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { MapObject } from '../../interfaces/map-object';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MapStatsService } from '../../services/map-stats/map-stats.service';

@Component({
  selector: 'ee-map-stats',
  templateUrl: './map-stats.component.html',
  styleUrls: ['./map-stats.component.scss']
})
export class MapStatsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() mapObject: MapObject;
  initialised = false;
  visible = false;
  zoomLevel: number = null;
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private mapStatsService: MapStatsService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mapObject && this.mapObject && !this.initialised) {
      this.initialised = true;
      this.watchVisibility();
      this.watchZoomLevel();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private watchVisibility() {
    this.mapStatsService
      .onVisibilityChange()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(visible => {
        this.visible = visible;
      });
  }

  private watchZoomLevel() {
    this.mapObject.onZoomChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(zoomLevel => {
        this.zoomLevel = Math.round(zoomLevel * 100) / 100;
      });
  }
}
