<div class="map-legend-container" [hidden]="referenceLayers.length === 0">
  <div class="map-legend-content" *ngIf="referenceLayers.length > 0"></div>
  <mat-accordion>
    <mat-expansion-panel
      [(expanded)]="legendExpanded"
      (afterCollapse)="refreshHints()"
      (afterExpand)="refreshHints()"
    >
      <mat-expansion-panel-header collapsedHeight="16px" expandedHeight="16px">
        <mat-panel-title>
          Legend
          <button
            mat-button
            class="filter-button simple-blue-button small"
            *ngIf="
              legendExpanded &&
              legendFilterStatus.display &&
              legendFilterStatus.active
            "
            (click)="toggleAllFilters($event, 'off')"
          >
            <i class="fa-solid fa-filter-slash"></i>
            Reset
          </button>
          <button
            mat-button
            class="filter-button simple-blue-button small"
            *ngIf="
              legendExpanded &&
              legendFilterStatus.display &&
              !legendFilterStatus.active
            "
            (click)="toggleAllFilters($event, 'on')"
          >
            <i class="fa-solid fa-filter"></i>
            Filter
          </button>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div>
        <div
          class="map-toggle-section"
          *ngIf="mapLegendTooltipEnabled"
          (click)="toggleMapToolTips()"
          [ngClass]="{ enabled: tooltipVisible }"
        >
          <div class="map-legend-tooltip-toggle">
            Display map layer tooltips on hover
          </div>
          <div fxFlex=""></div>
          <mat-slide-toggle
            class="toggle-slider"
            [(ngModel)]="tooltipVisible"
          ></mat-slide-toggle>
        </div>

        <mat-list
          role="list"
          [dragula]="dragGroup"
          [(dragulaModel)]="referenceLayers"
          id="left"
          [perfectScrollbar]
        >
          <ng-container *ngFor="let layer of referenceLayers">
            <mat-list-item
              role="listitem"
              class="legend-item"
              [ngClass]="{ disabled: !layer.visible }"
            >
              <div class="map-legend-grip-handle">
                <mat-icon
                  fontSet="fas"
                  fontIcon="fa-grip-vertical"
                  class="handle"
                ></mat-icon>
              </div>
              <div class="map-legend-type">
                <div
                  class="map-layer-color-square"
                  *ngIf="layer.legendStyling.shape === 'square'"
                  [ngStyle]="{
                    'background-color': layer.legendStyling.fillColor,
                    'border-color': layer.legendStyling.strokeColor
                  }"
                ></div>
                <div
                  class="map-layer-color-line"
                  *ngIf="layer.legendStyling.shape === 'line'"
                  [ngStyle]="{
                    'background-color': layer.legendStyling.fillColor,
                    'border-bottom-color': layer.legendStyling.strokeColor,
                    'border-top-color': layer.legendStyling.strokeColor
                  }"
                ></div>
                <div
                  class="map-layer-marker"
                  *ngIf="layer.legendStyling.shape === 'marker'"
                  [ngStyle]="{
                    'background-color': layer.legendStyling.fillColor,
                    'border-color': layer.legendStyling.strokeColor
                  }"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                >
                  <mat-icon
                    [svgIcon]="layer.legendStyling.markerImage"
                  ></mat-icon>
                </div>
              </div>
              <div
                class="map-legend-details"
                (click)="toggleLayerVisibility(layer)"
                fxLayout="row"
              >
                <p fxLayout="row" fxLayoutAlign="start center">
                  <span title="{{ layer.displayName }}">
                    {{ layer.displayName }}
                  </span>
                  <mat-icon
                    fontSet="fas"
                    fontIcon="fa-info-circle"
                    class="tooltip-icon"
                    eeTooltip
                    [tooltipText]="layer.tooltipHTML"
                    tooltipClass="wide-tooltip legend"
                    [tooltipDirection]="'right'"
                    [fixedOnClick]="true"
                  ></mat-icon>
                  <mat-icon
                    fontSet="fas"
                    fontIcon="fa-signal-stream"
                    *ngIf="layer.refreshInterval"
                    class="stream-icon"
                    eeTooltip
                    [tooltipText]="getStreamTooltip(layer)"
                    tooltipClass="stream-tooltip"
                    [tooltipDirection]="'right'"
                  ></mat-icon>
                  <mat-icon
                    fontSet="fas"
                    fontIcon="fa-exclamation-triangle"
                    *ngIf="
                      mapZoomLevel &&
                      ((layer.minZoom && mapZoomLevel < layer.minZoom) ||
                        (layer.maxZoom && mapZoomLevel > layer.maxZoom))
                    "
                    class="warning-icon"
                    eeTooltip
                    tooltipText="Layer not visible at current zoom level"
                    tooltipClass="tooltip-alert legend-panel"
                    [tooltipDirection]="'right'"
                  ></mat-icon>
                </p>
              </div>
              <div fxLayout="row" fxLayoutAlign="end end">
                <button
                  mat-icon-button
                  class="map-legend-button filter"
                  [ngClass]="{
                    visible: hasFilter(layer),
                    off: !hasActiveFilter(layer)
                  }"
                  (click)="toggleLayerFilter(layer)"
                >
                  <mat-icon
                    fontSet="fas"
                    fontIcon="fa-filter"
                    class="handle-ignore"
                  ></mat-icon>
                </button>
                <button
                  mat-icon-button
                  class="map-legend-button show-on-hover"
                  (click)="toggleLayerVisibility(layer)"
                >
                  <mat-icon
                    fontSet="fas"
                    fontIcon="fa-eye-slash"
                    class="handle-ignore"
                    *ngIf="layer.visible"
                  ></mat-icon>
                  <mat-icon
                    fontSet="fas"
                    fontIcon="fa-eye"
                    class="handle-ignore"
                    *ngIf="!layer.visible"
                  ></mat-icon>
                </button>
                <button
                  mat-icon-button
                  class="map-legend-button show-on-hover"
                  [ngClass]="{ 'palette-visible': layer.stylingOverwritten }"
                  (click)="showPalette(layer)"
                >
                  <mat-icon
                    fontSet="fas"
                    fontIcon="fa-palette"
                    class="handle-ignore"
                  ></mat-icon>
                </button>
                <button
                  mat-icon-button
                  class="map-legend-button show-on-hover"
                  (click)="removeLayer(layer)"
                >
                  <mat-icon
                    fontSet="fas"
                    fontIcon="fa-times"
                    class="handle-ignore"
                  ></mat-icon>
                </button>
              </div>
            </mat-list-item>
          </ng-container>
        </mat-list>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
