import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MapSearchService } from '../../services/map-search/map-search.service';
import {
  MapReferenceSearchResult,
  MapSearchResult
} from '../../interfaces/map-search-result';
import { FormControl } from '@angular/forms';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { MapObject } from '../../interfaces/map-object';
import { Subject } from 'rxjs';
import { MapSearchResultItem } from '../../interfaces/map-search-result-item';
import { Router } from '@angular/router';
import { HubSchoolRoutes } from 'src/app/hub/enums/hub-school-routes';
import { HubRoutes } from 'src/app/hub/enums/hub-routes';
import { AppRoutes } from 'src/app/core/enums/app-routes';
import { MapReferenceLayerDefs } from '../../enums/map-reference-layer-defs';
import { MapSearchItem } from '../../enums/map-search-item';

@Component({
  selector: 'ee-map-search',
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.scss']
})
export class MapSearchComponent implements OnInit, OnChanges, OnDestroy {
  @Input() mapObject: MapObject;
  @Input() extraClass: string;
  @ViewChild('mapSearchInput', { static: true }) mapSearchInput: ElementRef;
  @ViewChild('mapSearch', { static: true }) mapSearch: any;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  showClear = false;
  isLoading = false;
  searchValue = new FormControl();
  searchResults: MapSearchResultItem[] = [];
  selectedResult: MatOption = null;
  skipSelectClear = false;
  advancedSearchVisible = false;
  MapSearchItemEnum = MapSearchItem;
  private initialised = false;
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  hoveredItemIds: { [key: string]: boolean } = {};

  constructor(
    private router: Router,
    private mapSearchService: MapSearchService
  ) {}

  ngOnInit() {
    this.watchSearchValue();
    this.watchSearchLoading();
    this.watchSearchResults();
    this.watchAdvancedSearchVisible();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mapObject && this.mapObject && !this.initialised) {
      this.initialised = true;
      this.checkSearchClear();
    }
  }

  private watchSearchValue() {
    this.searchValue.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(500),
        tap(searchValue => {
          if (this.skipSelectClear) {
            this.skipSelectClear = false;
          } else {
            this.selectedResult = null;
          }

          this.showClear = searchValue !== '' && searchValue !== null;
        })
      )
      .subscribe(searchValue => {
        this.isLoading = true;
        if (typeof searchValue === 'string' || searchValue === null) {
          this.mapSearchService.search(searchValue);
        } else {
          this.isLoading = false;
          // only keep the item selected in the search results
          this.searchResults = [
            { item: searchValue.item, results: [searchValue], hovered: false }
          ];
        }
      });
  }

  private watchSearchLoading() {
    this.mapSearchService
      .onSearchLoading()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.isLoading = true;
      });
  }

  private watchSearchResults() {
    this.mapSearchService
      .onSearchComplete()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(results => {
        this.searchResults = results;
        this.isLoading = false;
      });
  }

  private watchAdvancedSearchVisible() {
    this.mapSearchService
      .onAdvancedSearchVisibilityChange()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(visible => {
        this.advancedSearchVisible = visible;
      });
  }

  private checkSearchClear() {
    this.mapObject.onForceSearchClear
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        if (result) {
          this.clearSearch();
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  onSearchFocus() {
    if (this.mapSearchService.getAdvancedSearchVisibility()) {
      this.mapSearchService.closeAdvancedSearch();
    }
  }

  clearSearch() {
    if (this.mapObject) {
      this.searchValue.reset();
      this.selectedResult = null;
      this.mapSearchService.clearSearch();
      this.mapSearchService.clearSearchResultLayer(this.mapObject);
    }
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    if (this.mapObject && event && event.option && event.option.value) {
      this.selectedResult = event.option;
      this.mapSearchService.selectSearchResult(
        this.mapObject,
        this.selectedResult.value as MapSearchResult
      );
      this.skipSelectClear = true;
      // Delay the blur to ensure it happens after Angular's change detection cycle
      setTimeout(() => {
        this.mapSearchInput.nativeElement.blur();
      });
    }
  }

  openSchoolView(result: MapReferenceSearchResult) {
    let url: string;
    if (result.type === 'reference') {
      const item = result.item;
      const id = result.id;
      if (item === this.MapSearchItemEnum.CATCHMENT) {
        // now figure out which catchment layer
        const catchmentLayerName =
          this.mapSearchService.getCatchmentLayer(result);
        url = `${AppRoutes.HUB}/${HubRoutes.CUSTOM_REF}/${catchmentLayerName}/${id}`;
      } else if (
        item === this.MapSearchItemEnum.GOV_SCHOOL ||
        item === this.MapSearchItemEnum.NON_GOV_SCHOOL ||
        item === this.MapSearchItemEnum.NEW_SCHOOL ||
        item === this.MapSearchItemEnum.UPGRADE_SCHOOL ||
        item === this.MapSearchItemEnum.CLOSED_GOV_SCHOOL
      ) {
        url = `${AppRoutes.HUB}/${HubRoutes.SCHOOL}/${id}/${HubSchoolRoutes.SCHOOL_OVERVIEW}`;
      } else if (item === this.MapSearchItemEnum.PLANNED_AREA_PRECINT) {
        url = `${AppRoutes.HUB}/${HubRoutes.CUSTOM_REF}/planned_areas/${id}`;
      } else if (item === this.MapSearchItemEnum.CLUSTER) {
        url = `${AppRoutes.HUB}/${HubRoutes.CLUSTER}/${id}`;
      } else if (item === this.MapSearchItemEnum.LGA) {
        url = `${AppRoutes.HUB}/${HubRoutes.LGA}/${id}`;
      } else if (item === this.MapSearchItemEnum.PRINCIPAL_NETWORKS) {
        url = `${AppRoutes.HUB}/${HubRoutes.PRINCIPAL_NETWORK}/${id}`;
      } else if (item === this.MapSearchItemEnum.STATE_ELECTORATE_DISTRICTS) {
        url = `${AppRoutes.HUB}/${HubRoutes.SED}/${id}`;
      } else if (item === this.MapSearchItemEnum.DET_REGIONS) {
        url = `${AppRoutes.HUB}/${HubRoutes.DET}/${id}`;
      } else if (item === this.MapSearchItemEnum.DPIE_REGIONS) {
        url = `${AppRoutes.HUB}/${HubRoutes.DPIE_REGION}/${id}`;
      } else if (item === this.MapSearchItemEnum.OPERATIONAL_DIRECTORATES) {
        url = `${AppRoutes.HUB}/${HubRoutes.OPERATIONAL_DIRECTORATE}/${id}`;
      } else if (item === this.MapSearchItemEnum.SA1_BOUNDARIES) {
        url = `${AppRoutes.HUB}/${HubRoutes.CUSTOM_REF}/${MapReferenceLayerDefs.SA1_BOUNDARIES}/${id}`;
      } else if (item === this.MapSearchItemEnum.SA2_BOUNDARIES) {
        url = `${AppRoutes.HUB}/${HubRoutes.CUSTOM_REF}/${MapReferenceLayerDefs.SA2_BOUNDARIES}/${id}`;
      } else if (item === this.MapSearchItemEnum.SA3_BOUNDARIES) {
        url = `${AppRoutes.HUB}/${HubRoutes.CUSTOM_REF}/${MapReferenceLayerDefs.SA3_BOUNDARIES}/${id}`;
      } else if (item === this.MapSearchItemEnum.SA4_BOUNDARIES) {
        url = `${AppRoutes.HUB}/${HubRoutes.CUSTOM_REF}/${MapReferenceLayerDefs.SA4_BOUNDARIES}/${id}`;
      } else if (item === this.MapSearchItemEnum.MESH_BLOCKS) {
        url = `${AppRoutes.HUB}/${HubRoutes.CUSTOM_REF}/${MapReferenceLayerDefs.MESH_BLOCKS}/${id}`;
      } else if (item === this.MapSearchItemEnum.CADASTRE_LOTS) {
        url = `${AppRoutes.HUB}/${HubRoutes.CUSTOM_REF}/${MapReferenceLayerDefs.CADASTRE_LOTS}/${id}`;
      }
    }
    this.router.navigate([url]);
    // Close the autocomplete panel after navigating
    if (this.autocomplete) {
      this.autocomplete.closePanel();
    }
  }

  displayResult(result: MapSearchResult) {
    return result ? result.result : undefined;
  }

  focusSearch() {
    this.mapSearchInput.nativeElement.focus();
  }

  toggleAdvancedSearch() {
    if (this.advancedSearchVisible) {
      this.mapSearchService.closeAdvancedSearch();
    } else {
      this.mapSearchService.openAdvancedSearch();
      this.autocomplete.closePanel();
    }
  }

  getResultsIcon(searchResult: MapSearchResultItem) {
    const itemId = this.mapSearchService
      .getSearchItems()
      .find(item => item.id === searchResult.item)?.id;
    let icon = 'vector';

    if (
      itemId === MapSearchItem.GOV_SCHOOL ||
      itemId === MapSearchItem.NEW_SCHOOL ||
      itemId === MapSearchItem.UPGRADE_SCHOOL ||
      itemId === MapSearchItem.NON_GOV_SCHOOL ||
      itemId === MapSearchItem.CLOSED_GOV_SCHOOL
    ) {
      icon = 'school';
    } else if (
      itemId === MapSearchItem.LOCATION ||
      itemId === MapSearchItem.COORDINATES
    ) {
      icon = 'marker';
    }
    return icon;
  }

  getResultsTag(searchResult: MapSearchResultItem) {
    if (searchResult) {
      const itemName = searchResult.item;
      if (itemName === MapSearchItem.LOCATION) {
        return 'Location';
      }
      if (itemName === MapSearchItem.COORDINATES) {
        return 'Coordinates';
      }
      const itemTag = this.mapSearchService
        .getSearchItems()
        .find(item => item.id === itemName)?.tag;
      return itemTag || '';
    }
  }

  shouldDisplayItemPills(result: MapSearchResult) {
    return result && result.type === 'reference' && result.id;
  }
}
