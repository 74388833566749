export enum MapBasemapName {
  MAPBOX_GREY = 'mapbox_grey',
  MAPBOX_STREETS = 'mapbox_streets',
  MAPBOX_DARK_GREY = 'mapbox_dark_grey',
  MAPBOX_SATELLITE = 'mapbox_satellite',
  MAPBOX_OUTDOORS = 'mapbox_outdoors',
  NEARMAP_DEFAULT = 'nearmap_default',
  NEARMAP_STREETS = 'nearmap_streets',
  SIX_MAPS = 'six_maps',
  NEARMAP_TERRAIN = 'nearmap_terrain',
  CARTO_LIGHT = 'carto_light'
}
