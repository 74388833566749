import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector
} from '@angular/core';
import { MapObject } from '../../interfaces/map-object';
import { MapTooltipComponent } from '../../components/map-tooltip/map-tooltip.component';
import { Marker } from 'mapbox-gl';

@Injectable({
  providedIn: 'root'
})
export class MapTooltipService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {}

  createTooltip(mapObject: MapObject) {
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(MapTooltipComponent)
      .create(this.injector);

    this.appRef.attachView(componentRef.hostView);
    componentRef.instance.set(mapObject);

    const marker = new Marker(componentRef.instance.el.nativeElement)
      .setLngLat(mapObject.map.getCenter())
      .addTo(mapObject.map);

    mapObject.mapTooltipObject = {
      marker: {
        componentRef: componentRef,
        marker: marker
      },
      onMouseOut: null
    };
  }

  private createMouseOutEvent(mapObject: MapObject) {
    if (mapObject && mapObject.mapTooltipObject) {
      if (mapObject.mapTooltipObject.onMouseOut) {
        this.removeMouseOutEvent(mapObject);
      }

      const mouseOutEvent: (ev: any) => void = (ev: any) => {
        this.hideTooltip(mapObject);
      };

      mapObject.map.on('mouseout', mouseOutEvent);
      mapObject.mapTooltipObject.onMouseOut = mouseOutEvent;
    }
  }

  private removeMouseOutEvent(mapObject: MapObject) {
    if (
      mapObject &&
      mapObject.mapTooltipObject &&
      mapObject.mapTooltipObject.onMouseOut
    ) {
      mapObject.map.off('mouseout', mapObject.mapTooltipObject.onMouseOut);
      mapObject.mapTooltipObject.onMouseOut = null;
    }
  }

  showTooltip(
    mapObject: MapObject,
    tooltipText: string,
    displayName: string,
    featureProps: any,
    coordinates: any
  ) {
    if (
      mapObject &&
      mapObject.mapTooltipObject &&
      mapObject.mapTooltipObject.marker
    ) {
      this.createMouseOutEvent(mapObject);

      const tooltipWidth = mapObject.enabledReferenceLayers.find(
        layer => layer.layerName === mapObject.hoveringLayer
      )?.tooltipWidth;

      mapObject.mapTooltipObject.marker.componentRef.instance.show(
        mapObject,
        tooltipText,
        displayName,
        tooltipWidth,
        featureProps
      );
      mapObject.mapTooltipObject.marker.marker.setLngLat([
        coordinates.lng,
        coordinates.lat
      ]);
    }
  }

  hideTooltip(mapObject: MapObject) {
    if (
      mapObject &&
      mapObject.mapTooltipObject &&
      mapObject.mapTooltipObject.marker
    ) {
      this.removeMouseOutEvent(mapObject);

      mapObject.mapTooltipObject.marker.componentRef.instance.hide();
    }
  }
}
