import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import {
  FilterSpecification,
  LayoutSpecification,
  PaintSpecification,
  VectorSourceSpecification
} from 'mapbox-gl';
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import tinycolor from 'tinycolor2';
import { environment } from '../../../../environments/environment';
import { MapReferenceLayerResolverService } from '../../../core/resolvers/map-reference-layer-resolver/map-reference-layer-resolver.service';
import { MapLayerActionType } from '../../enums/map-layer-action-type';
import { MapPsuedoLayers } from '../../enums/map-psuedo-layers';
import { MapReferenceLayerType } from '../../enums/map-reference-layer-type';
import { MapLayerPaletteData } from '../../interfaces/map-layer-palette-data';
import { MapLayerPaletteGroupData } from '../../interfaces/map-layer-palette-group-data';
import { MapObject } from '../../interfaces/map-object';
import { MapReferenceLayer } from '../../interfaces/map-reference-layer';
import { MapReferenceLayerCategory } from '../../interfaces/map-reference-layer-category';
import {
  PropertyFilter,
  PropertyFilterCondition,
  PropertyFilterGroup,
  PropertyFilterValue
} from '../../interfaces/map-reference-layer-property-filter';
import {
  SpatialFilter,
  SpatialFilterSetValue,
  SpatialFilterValue
} from '../../interfaces/map-reference-layer-spatial-filter';
import { MapTooltipService } from '../map-tooltip/map-tooltip.service';
import { MapboxService } from '../mapbox/mapbox.service';
import { TileyService } from '../tiley/tiley.service';

@Injectable({
  providedIn: 'root'
})
export class MapReferenceLayersService {
  constructor(
    private mapReferenceLayersDataService: MapReferenceLayerResolverService,
    private mapboxService: MapboxService,
    private mapTooltipService: MapTooltipService,
    private router: Router,
    private tileyService: TileyService
  ) {}

  assignReferenceLayersToMapObject(mapObject: MapObject) {
    this.mapReferenceLayersDataService
      .onReferenceLayerCategoriesReady()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          if (!mapObject.referenceLayerCategories) {
            mapObject.referenceLayerCategories = _.cloneDeep(result);
            mapObject.enabledReferenceLayers = [];
            mapObject.onReferenceLayersReady.next(true);
          }
        }
      });
  }

  reorderEnabledLayers(mapObject: MapObject, layers: MapReferenceLayer[]) {
    mapObject.enabledReferenceLayers = layers;

    let previousLayerId: string = MapPsuedoLayers.PSUEDO_LAYER_REF;

    for (const refLayer of layers) {
      if (refLayer.enabled) {
        for (let i = refLayer.layerStyling.length - 1; i >= 0; i--) {
          const layer = refLayer.layerStyling[i];
          mapObject.map.moveLayer(layer.id, previousLayerId);
          previousLayerId = layer.id;
        }
      }
    }
  }

  addVectorLayer(
    mapObject: MapObject,
    layer: MapReferenceLayer,
    actionType: MapLayerActionType = MapLayerActionType.PROGRAMMATIC,
    customLayerParameters?: Pick<
      MapReferenceLayer,
      'datasetName' | 'layerName' | 'extraColumns' | 'baseFilter'
    >
  ) {
    if (!mapObject || !mapObject.map) return;
    if (actionType === MapLayerActionType.PROGRAMMATIC) {
      layer.progammaticEnabled = true;
    } else if (actionType === MapLayerActionType.SEARCH) {
      layer.searchEnabled = true;
    } else if (actionType === MapLayerActionType.USER_TOGGLE) {
      layer.userEnabled = true;
    }
    // * visible: visible on the map - keep the visibility if it has been intentionally turned off
    if (layer.visible !== false) {
      layer.visible = true;
    }
    // * enabled: load on the legend and checked on reference layer panel
    layer.enabled = true;
    // if icon image involved, add icon image
    if (layer.imageName) {
      const images = layer.imageName.split(',').map(l => {
        return {
          id: l,
          url: `${environment.assetsFolder}${l}.png`
        };
      });
      this.mapboxService.loadImages(mapObject, images);
    }
    // * load live layer
    if (
      layer.datasetName.startsWith('http://') ||
      layer.datasetName.startsWith('https://')
    ) {
      this.mapboxService.updateGeoJsonDataLayer(
        mapObject,
        layer.layerName,
        layer.datasetName,
        null,
        layer.layerStyling,
        MapPsuedoLayers.PSUEDO_LAYER_REF
      );

      if (layer.refreshInterval && !layer.refreshIntervalTimer) {
        layer.refreshDate = moment().toDate();
        layer.refreshIntervalTimer = setInterval(() => {
          this.mapboxService.updateGeoJsonDataSource(
            mapObject,
            layer.layerName,
            layer.datasetName
          );
          layer.refreshDate = moment().toDate();
        }, layer.refreshInterval);

        layer.refreshTimeRemainingInterval = setInterval(() => {
          if (!layer.refreshTimeRemaining || layer.refreshTimeRemaining === 0) {
            layer.refreshTimeRemaining = layer.refreshInterval;
          } else {
            layer.refreshTimeRemaining -= 5000;
          }
        }, 5000);
      }
    } else {
      // * load vector tile layers
      // * If custom layer parameters are provided, use them (useful for enrolment locations)
      // * Otherwise use layer definition
      const layerDefinition = customLayerParameters || layer;
      const layerParameters = {
        dataset_name: layerDefinition.datasetName,
        layer_name: layerDefinition.layerName,
        ...(layerDefinition.extraColumns && {
          extra_columns: layerDefinition.extraColumns
        }),
        ...(layerDefinition.baseFilter && {
          base_filter: layerDefinition.baseFilter
        })
      };
      // get tiley endpoint
      const tiles = this.tileyService.getTileyUrl(
        '/tiles/{z}/{x}/{y}',
        layerParameters
      );
      const { layerStyling: subLayers, idColumn, layerName: sourceId } = layer;
      // add source if not existed
      if (!mapObject.map.getSource(sourceId)) {
        let layerSource: VectorSourceSpecification = {
          type: 'vector',
          tiles: [tiles]
        };
        layerSource.promoteId = idColumn || 'tile_id';
        mapObject.map.addSource(sourceId, layerSource);
      }
      // add layers if not existed
      const beforeLayerId = MapPsuedoLayers.PSUEDO_LAYER_REF;
      subLayers.forEach(l => {
        if (!mapObject.map.getLayer(l.id)) {
          const layerDef = {
            ...l,
            source: layer.layerName,
            'source-layer': layer.layerName
          };
          if (!layerDef.hasOwnProperty('metadata')) {
            layerDef.metadata = {};
          }
          layerDef.metadata['type'] = 'custom';
          // for 3d layer
          if (layer.layerType === MapReferenceLayerType.SCHOOLS_3D) {
            const clipLayerDef = {
              ...layerDef,
              type: 'clip' as const,
              id: layerDef.id + '_clip'
            };
            mapObject.map.addLayer(clipLayerDef, beforeLayerId);
          }
          mapObject.map.addLayer(layerDef, beforeLayerId);
        }
      });
    }

    // update styles and orders
    // * light mode dark mode colour invert
    this.mapboxService.checkAndInvertMapLayers(mapObject, layer.layerStyling);
    // * check local storage for colour palette overwrites
    this.checkLayerStylingOverwrites(mapObject, layer);
    // * resolve light emission
    this.mapboxService.updateLayersEmissiveStrength(mapObject);
    // sort out layer order
    this.setInitialLayerOrder(mapObject, layer);
    this.reorderEnabledLayers(mapObject, mapObject.enabledReferenceLayers);
    // add layer event
    this.initialiseReferenceLayerEvents(mapObject, layer);
    layer.initialised = true;
    mapObject.onReferenceLayersChanged.next(true);
  }

  // remove vector layer and ... real remove
  removeVectorLayer(mapObject: MapObject, layer: MapReferenceLayer) {
    if (layer) {
      layer.enabled = false;
      layer.initialised = false;
      layer.visible = undefined;
      layer.userEnabled = false;
      layer.progammaticEnabled = false;
      layer.searchEnabled = false;
      layer.propertyFilter = null;
      layer.spatialFilter = null;
      // Remove layer from array
      const index = mapObject.enabledReferenceLayers.indexOf(layer);

      if (index > -1) {
        mapObject.enabledReferenceLayers.splice(index, 1);
      }

      let layerRemoved = false;

      layer.layerStyling.forEach(l => {
        if (mapObject.map.getLayer(l.id)) {
          mapObject.map.removeLayer(l.id);
          // also remove clip layer in 3d
          if (layer.layerType === MapReferenceLayerType.SCHOOLS_3D) {
            mapObject.map.removeLayer(l.id + '_clip');
          }
          layerRemoved = true;
        }
      });
      if (mapObject.map.getSource(layer.layerName)) {
        mapObject.map.removeSource(layer.layerName);
      }
      if (layerRemoved) {
        mapObject.onReferenceLayersChanged.next(true);
      }
    }
  }

  toggleLayerVisibility(mapObject: MapObject, layer: MapReferenceLayer) {
    if (!layer.visible) {
      this.showLayer(mapObject, layer);
    } else {
      this.hideLayer(mapObject, layer);
    }
  }

  showLayer(mapObject: MapObject, layer: MapReferenceLayer) {
    layer.visible = true;
    this.mapboxService.showLayers(mapObject, layer.layerStyling);
    mapObject.onReferenceLayersChanged.next(true);
  }

  hideLayer(mapObject: MapObject, layer: MapReferenceLayer) {
    layer.visible = false;
    this.mapboxService.hideLayers(mapObject, layer.layerStyling);
    mapObject.onReferenceLayersChanged.next(true);
  }

  private convertTypeToInteger(type: MapReferenceLayerType): number {
    let result = -1;

    switch (type) {
      case MapReferenceLayerType.POINT:
        result = 0;
        break;
      case MapReferenceLayerType.SCHOOLS_3D:
        result = 1;
        break;
      case MapReferenceLayerType.LINE:
        result = 2;
        break;
      case MapReferenceLayerType.POLYGON:
        result = 3;
        break;
    }

    return result;
  }

  private setInitialLayerOrder(mapObject: MapObject, layer: MapReferenceLayer) {
    if (mapObject && mapObject.enabledReferenceLayers.indexOf(layer) === -1) {
      const layerType = this.convertTypeToInteger(layer.layerType);
      let index = 0;

      if (layerType > 0) {
        const currentLayers = mapObject.enabledReferenceLayers;
        for (let i = 0; i < currentLayers.length; i++) {
          const currentLayerType = this.convertTypeToInteger(
            currentLayers[i].layerType
          );
          if (layerType <= currentLayerType) {
            break;
          }
          index++;
        }
      }

      mapObject.enabledReferenceLayers.splice(index, 0, layer);
    }
  }

  private initialiseReferenceLayerEvents(
    mapObject: MapObject,
    layer: MapReferenceLayer
  ) {
    this.mapboxService.registerEvent(mapObject, 'click', layer.layerName, e => {
      this.onLayerClick(e, mapObject, layer);
    });

    this.mapboxService.registerEvent(
      mapObject,
      'mousemove',
      layer.layerName,
      e => {
        this.onLayerMouseMove(e, mapObject, layer);
      }
    );

    this.mapboxService.registerEvent(
      mapObject,
      'mouseleave',
      layer.layerName,
      () => {
        this.onLayerMouseLeave(mapObject, layer);
      }
    );
  }

  // Layer is only clickable (and then has routing) if
  // √ interactive
  // √ has interactive routes
  // √ has id_column (this will create a id when it is added)
  private onLayerClick(e: any, mapObject: MapObject, layer: MapReferenceLayer) {
    if (!mapObject.editMode && !layer.interactiveOverride) {
      if (
        e.features &&
        e.features.length > 0 &&
        e.features[0].id &&
        layer.interactive
      ) {
        // TODO temp fix
        if (this.router.url.startsWith('/admin')) {
          return;
        }
        const route = layer.interactiveRoute
          ? [layer.interactiveRoute, e.features[0].id]
          : ['hub', 'custom-ref', layer.layerName, e.features[0].id];
        this.router.navigate(route);
      }
      // const route = layer.interactive && layer.interactiveRoute ? [layer.interactiveRoute] : [AppRoutes.HUB, HubRoutes.CUSTOM_REF, layer.layerName]
      // if (e.features && e.features.length > 0 && e.features[0].id) {
      //   this.router.navigate([...route, e.features[0].id]);
      // }
    }
  }

  private onLayerMouseMove(
    e: any,
    mapObject: MapObject,
    layer: MapReferenceLayer
  ) {
    if (!mapObject.editMode && !layer.interactiveOverride) {
      mapObject.map.getCanvas().style.cursor = 'pointer';

      let coordinates = null;

      if (layer.hasTooltip && mapObject.tooltipEnabled) {
        if (
          e.features &&
          e.features.length > 0 &&
          e.features[0].properties &&
          e.features[0].geometry
        ) {
          if (e.features[0].geometry.type === 'Point') {
            coordinates = {
              lat: e.features[0].geometry.coordinates[1],
              lng: e.features[0].geometry.coordinates[0]
            };
          } else {
            coordinates = e.lngLat;
          }
          this.showMapToolTip(
            mapObject,
            layer.tooltipText,
            layer.displayName,
            e.features[0].properties,
            coordinates
          );
        }
      }

      if (
        layer.interactive &&
        e.features &&
        e.features[0] &&
        e.features[0].properties
      ) {
        const column = layer.idColumn || 'tile_id';
        const hoverId = e.features[0].properties[column];
        this.highlightFeature(mapObject, layer, hoverId, column);
      }
    }
  }

  private onLayerMouseLeave(mapObject: MapObject, layer: MapReferenceLayer) {
    if (!mapObject.editMode && !layer.interactiveOverride && layer.enabled) {
      mapObject.map.getCanvas().style.cursor = '';
      if (layer.interactive) {
        this.clearHighlightFeature(mapObject, layer);
      }

      if (layer.hasTooltip && mapObject.tooltipEnabled) {
        this.clearMapToolTip(mapObject);
      }
    }
  }

  public getLayerByLayerName(
    mapObject: MapObject,
    layerName: string
  ): MapReferenceLayer {
    let layer: MapReferenceLayer = null;

    const checkSubCategories = (subCategories: MapReferenceLayerCategory[]) => {
      for (const subCat of subCategories) {
        for (const refLayer of subCat.layers) {
          if (refLayer.layerName === layerName) {
            layer = refLayer;
            break;
          }
        }

        if (layer) {
          break;
        }

        checkSubCategories(subCat.subCategories);
      }
    };

    if (mapObject && mapObject.referenceLayerCategories) {
      checkSubCategories(mapObject.referenceLayerCategories);
    }

    return layer;
  }

  /* 
  Filter v2
  There are three types of filters:
  * property filter: based on properties of this layer. Please check type definition.
  * spatial filter: based on geometry intersection. It can be a geojson or feature(s) from another layer
  * style filter: defined in the layerStyling as mapbox expressions. Just pass it along
  
  The first two types of filters can be controlled and modified (aka. on/off)
  They have
  * a status called "on" - think it as a toggle
  * value - that is a geometry (geometry), an object (property), a layer filter (layer)
  */

  applyPropertyFilter(
    mapObject: MapObject,
    layer: MapReferenceLayer,
    filterValue: PropertyFilterValue
  ) {
    if (!filterValue) {
      this.clearPropertyFilter(mapObject, layer);
      return;
    }
    layer.propertyFilter = {
      on: true,
      value: filterValue
    };
    this.refreshLayerFilter(mapObject, layer);
  }

  // By default toggle the property filter of a layer
  // if the status is provided, force this status
  togglePropertyFilter(
    mapObject: MapObject,
    layer: MapReferenceLayer,
    status?: 'on' | 'off'
  ) {
    if (layer.propertyFilter) {
      const layerStatus = status
        ? status === 'on'
          ? true
          : false
        : !layer.propertyFilter.on;
      layer.propertyFilter = { ...layer.propertyFilter, on: layerStatus };
      this.refreshLayerFilter(mapObject, layer);
    }
  }

  clearPropertyFilter(mapObject: MapObject, layer: MapReferenceLayer) {
    layer.propertyFilter = null;
    this.refreshLayerFilter(mapObject, layer);
  }

  clearAllFilters(mapObject: MapObject, layer: MapReferenceLayer) {
    layer.propertyFilter = null;
    layer.spatialFilter = null;
    this.refreshLayerFilter(mapObject, layer);
  }

  // spatial filter
  applySpatialPropertyFilter(
    mapObject: MapObject,
    layer: MapReferenceLayer,
    filterValue: SpatialFilterSetValue
  ) {
    // won't do anything to live layers
    if (
      layer.datasetName.startsWith('http://') ||
      layer.datasetName.startsWith('https://')
    ) {
      return;
    }
    if (!filterValue) {
      this.clearSpatialFilter(mapObject, layer);
      return;
    }
    if (filterValue.type === 'geometry') {
      const { geometry } = filterValue;
      this.tileyService
        .getFeatureIdsByGeometry(geometry, layer.datasetName, layer.idColumn)
        .subscribe(data => {
          if (data) {
            layer.spatialFilter = {
              on: true,
              value: {
                type: 'geometry',
                geometry,
                layer: {
                  field: data.column,
                  value: data.values
                }
              }
            };
            this.refreshLayerFilter(mapObject, layer);
          }
        });
    } else {
      const {
        layer: baseLayer,
        value: baseValue,
        field
      } = filterValue.baseLayer;
      // Base layer is the layer that is used to filter this layer
      // Base column: specified field > id column > tile id
      const baseColumn = field || baseLayer.idColumn || 'tile_id';
      this.tileyService
        .getFeatureIdsByLayer(
          baseLayer.datasetName,
          baseColumn,
          baseValue,
          layer.datasetName,
          layer.idColumn
        )
        .subscribe(data => {
          if (data) {
            layer.spatialFilter = {
              on: true,
              value: {
                type: 'feature',
                baseLayer: {
                  layer: baseLayer,
                  value: baseValue,
                  field: baseColumn
                },
                layer: {
                  field: data.column,
                  value: data.values
                }
              }
            };
            this.refreshLayerFilter(mapObject, layer);
          }
        });
    }
  }

  toggleSpatialFilter(
    mapObject: MapObject,
    layer: MapReferenceLayer,
    status?: 'on' | 'off'
  ) {
    if (layer.spatialFilter) {
      const layerStatus = status
        ? status === 'on'
          ? true
          : false
        : !layer.spatialFilter.on;
      layer.spatialFilter = { ...layer.spatialFilter, on: layerStatus };
      this.refreshLayerFilter(mapObject, layer);
    }
  }

  clearSpatialFilter(mapObject: MapObject, layer: MapReferenceLayer) {
    layer.spatialFilter = null;
    this.refreshLayerFilter(mapObject, layer);
  }

  // helpers
  // check if the layer has filter.
  checkLayerFilter(layer: MapReferenceLayer) {
    return layer.spatialFilter || layer.propertyFilter;
  }
  // check if the layer has filter AND if the filter is triggered
  checkLayerFilterStatus(layer: MapReferenceLayer) {
    if (layer.spatialFilter && layer.spatialFilter.on) return true;
    if (layer.propertyFilter && layer.propertyFilter.on) return true;
    return false;
  }

  // convert property filter to mapbox expression
  private preparePropertyFilterCondition(
    PropertyFilterCondition: PropertyFilterCondition
  ) {
    const { field, operator, value } = PropertyFilterCondition;
    switch (operator) {
      case 'between':
        return [
          'all',
          ['>=', ['get', field], value[0]],
          ['<=', ['get', field], value[1]]
        ];
      case 'in':
        return [
          'in',
          ['get', field],
          ['literal', Array.isArray(value) ? value : [value]]
        ];
      case '!in':
        return [
          '!',
          [
            'in',
            ['get', field],
            ['literal', Array.isArray(value) ? value : [value]]
          ]
        ];
      case 'has':
        return ['has', field];
      case '!has':
        return ['!', ['has', field]];
      default:
        return [operator, ['get', field], value];
    }
  }

  preparePropertyFilterExpression(propertyFilterValue: PropertyFilterValue) {
    // if it is a simple property filter condition
    if ('field' in propertyFilterValue) {
      return this.preparePropertyFilterCondition(
        propertyFilterValue as PropertyFilterCondition
      );
    }
    const mapOperator = propertyFilterValue.operator === 'AND' ? 'all' : 'any';

    const expressions = propertyFilterValue.conditions.map(condition => {
      // Handle nested groups
      if (
        'operator' in condition &&
        (condition.operator === 'AND' || condition.operator === 'OR')
      ) {
        return this.preparePropertyFilterExpression(
          condition as PropertyFilterGroup
        );
      }
      return this.preparePropertyFilterCondition(
        condition as PropertyFilterCondition
      );
    });

    return [mapOperator, ...expressions];
  }

  // convert spatial filter to mapbox expression
  prepareSpatialFilterExpression(spatialFilterValue: SpatialFilterValue) {
    const { field, value } = spatialFilterValue.layer;
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return ['==', ['get', field], ''];
      }
      return ['in', ['get', field], ['literal', value]];
    } else {
      return ['==', ['get', field], value];
    }
  }
  // Prepare layer filter expression
  // update layer status based on the layer filter
  refreshLayerFilter(mapObject: MapObject, layer: MapReferenceLayer) {
    const propertyFilter = layer.propertyFilter;
    const spatialFilter = layer.spatialFilter;
    layer.layerStyling.forEach(layerStyle => {
      const mapboxLayerId = layerStyle.id;
      const combinedFilter = this.combineFilters(
        layerStyle.filter,
        propertyFilter,
        spatialFilter
      );
      this.mapboxService.applyFilterToLayer(
        mapObject,
        mapboxLayerId,
        combinedFilter
      );
    });
  }
  combineFilters(
    styleFilter: any[],
    propertyFilter: PropertyFilter,
    spatialFilter: SpatialFilter
  ) {
    const styleFilterExpression = styleFilter || null;
    const propertyFilterExpression =
      propertyFilter && propertyFilter.on
        ? this.preparePropertyFilterExpression(propertyFilter.value)
        : null;
    const spatialFilterExpression =
      spatialFilter && spatialFilter.on
        ? this.prepareSpatialFilterExpression(spatialFilter.value)
        : null;
    let expression = [];
    if (styleFilterExpression) {
      expression.push(styleFilterExpression);
    }
    if (propertyFilterExpression) {
      expression.push(propertyFilterExpression);
    }
    if (spatialFilterExpression) {
      expression.push(spatialFilterExpression);
    }
    // all null!
    if (expression.length === 0) {
      return null;
    } else if (expression.length === 1) {
      return expression[0];
    } else {
      return ['all', ...expression];
    }
  }

  public getAllReferenceLayers(mapObject: MapObject): MapReferenceLayer[] {
    const layers: MapReferenceLayer[] = [];

    const checkSubCategories = (subCategories: MapReferenceLayerCategory[]) => {
      for (const subCat of subCategories) {
        for (const refLayer of subCat.layers) {
          layers.push(refLayer);
        }

        checkSubCategories(subCat.subCategories);
      }
    };

    if (mapObject && mapObject.referenceLayerCategories) {
      checkSubCategories(mapObject.referenceLayerCategories);
    }

    return layers;
  }

  getAllInitialisedLayers(mapObject: MapObject): MapReferenceLayer[] {
    return this.getAllReferenceLayers(mapObject).filter(
      layer => layer.initialised
    );
  }

  highlightFeature(
    mapObject: MapObject,
    layer: MapReferenceLayer,
    id: number | string,
    idColumn?: string
  ) {
    if (!id || !layer) {
      console.log('no id or layer');
      return;
    }
    const column = idColumn
      ? idColumn
      : layer.idColumn
        ? layer.idColumn
        : 'tile_id';
    for (const l of layer.layerStyling) {
      const existingLayer = mapObject.map.getLayer(l.id);
      if (existingLayer) {
        const existingFilterArray = existingLayer.filter;
        if (l.metadata && l.metadata['ee:type'] && existingFilterArray) {
          if (l.metadata['ee:type'] === 'default') {
            const newFilter = existingFilterArray
              ? ['all', ['!=', ['get', column], id], existingFilterArray]
              : ['!=', ['get', column], id];
            mapObject.map.setFilter(l.id, newFilter as FilterSpecification);
          } else if (l.metadata['ee:type'] === 'hover') {
            mapObject.map.setFilter(l.id, ['==', ['get', column], id]);
          }
        }
      }
    }
  }

  clearHighlightFeature(
    mapObject: MapObject,
    layer: MapReferenceLayer,
    idColumn?: string
  ) {
    if (!layer) {
      return;
    }
    const column = idColumn
      ? idColumn
      : layer.idColumn
        ? layer.idColumn
        : 'tile_id';
    for (const l of layer.layerStyling) {
      if (l.metadata && l.metadata['ee:type']) {
        const presetFilter = this.combineFilters(
          l.filter,
          layer.propertyFilter,
          layer.spatialFilter
        );
        if (l.metadata['ee:type'] === 'default') {
          mapObject.map.setFilter(l.id, presetFilter);
        } else if (l.metadata['ee:type'] === 'hover') {
          mapObject.map.setFilter(l.id, ['==', ['get', column], '']);
        }
      }
    }
  }

  showMapToolTip(
    mapObject: MapObject,
    tooltipText: string,
    displayName: string,
    featureProps: any,
    coordinates: any
  ) {
    this.mapTooltipService.showTooltip(
      mapObject,
      tooltipText,
      displayName,
      featureProps,
      coordinates
    );
  }

  clearMapToolTip(mapObject: MapObject) {
    this.mapTooltipService.hideTooltip(mapObject);
  }

  private getSublayerLabelFromId(id: string, type: string): string {
    id = id.replace(/\_/g, '-');

    // const markerHaloHoverIds = ['-halo-hover'];
    // const markerHoverIds = ['-marker-hover'];
    const markerIds = ['-marker', '-circle'];
    // const lineCaseHoverIds = ['-line-casing-hover', '-case-hover', '-line-base-hover', '-stroke-base-hover'];
    // const lineHoverIds = ['-line-hover', '-stroke-hover'];
    // const lineCaseIds = ['-line-case', '-case', '-outline', '-line-casing', '-base-stroke', '-line-base', '-base', '-stroke-base'];
    const lineIds = ['-stroke', '-line'];
    const fillIds = ['fill'];
    const labelIds = ['-label'];

    if (type === 'fill' && fillIds.filter(i => id.includes(i)).length > 0) {
      return 'Area';
      // } else if (type === 'circle' && (markerHaloHoverIds.filter(i => id.includes(i)).length > 0)) {
      //   return 'Circle Halo (Hover)';
      // } else if (type === 'circle' && (markerHoverIds.filter(i => id.includes(i)).length > 0)) {
      //   return 'Circle (Hover)';
    } else if (
      type === 'circle' &&
      markerIds.filter(i => id.includes(i)).length > 0
    ) {
      return 'Circle';
      // } else if (type === 'line' && (lineCaseHoverIds.filter(i => id.includes(i)).length > 0)) {
      //   return 'Outline (Hover)';
      // } else if (type === 'line' && (lineHoverIds.filter(i => id.includes(i)).length > 0)) {
      //   return 'Line (Hover)';
      // } else if (type === 'line' && (lineCaseIds.filter(i => id.includes(i)).length > 0)) {
      //   return 'Outline';
    } else if (
      type === 'line' &&
      lineIds.filter(i => id.includes(i)).length > 0
    ) {
      return 'Line';
    } else if (
      type === 'symbol' &&
      labelIds.filter(i => id.includes(i)).length > 0
    ) {
      return 'Label';
    }

    return id;
  }

  private getPropertyLabel(property: string): string {
    const colorProperties = [
      'fill-color',
      'circle-color',
      'line-color',
      'text-color'
    ];
    const opacityProperties = ['fill-opacity'];
    const sizeProperties = ['text-size'];

    if (colorProperties.includes(property)) {
      return 'Color';
    } else if (opacityProperties.includes(property)) {
      return 'Opacity';
    } else if (sizeProperties.includes(property)) {
      return 'Size';
    }
  }

  private checkAndAddPaletteItem(
    paletteGroup: MapLayerPaletteGroupData,
    layerName: string,
    sublayer: any,
    type: string,
    propertyParent: string,
    property: string
  ) {
    const colorProperties = [
      'fill-color',
      'circle-color',
      'line-color',
      'text-color'
    ];

    if (
      sublayer.type === type &&
      ((propertyParent &&
        sublayer[propertyParent] &&
        sublayer[propertyParent][property]) ||
        (!propertyParent && sublayer[property]))
    ) {
      let value = sublayer[propertyParent][property];

      if (colorProperties.includes(property)) {
        const color = tinycolor(value);
        value = color.isValid() ? color.toHexString() : value;
      }

      paletteGroup.paletteData.push({
        label: this.getSublayerLabelFromId(sublayer.id, type),
        type: type,
        propertyLabel: this.getPropertyLabel(property),
        propertyParent: propertyParent,
        property: property,
        value: value
      });
    }
  }

  getLayerPaletteData(mapObject: MapObject, layer: MapReferenceLayer) {
    // Check and return existing palette group if it exists
    const existingPaletteGroup = this.getPaletteGroupFromLocalStorage(
      layer.layerName
    );

    if (existingPaletteGroup) {
      return existingPaletteGroup;
    }

    // Generate new list
    const excludeIds = [
      '-halo-hover',
      '-marker-hover',
      '-line-casing-hover',
      '-case-hover',
      '-line-base-hover',
      '-stroke-base-hover',
      '-line-hover',
      '-stroke-hover',
      '-line-case',
      '-case',
      '-outline',
      '-line-casing',
      '-base-stroke',
      '-line-base',
      '-base',
      '-stroke-base',
      '-image-hover',
      '-label-hover'
    ];

    const paletteGroupList: MapLayerPaletteGroupData[] = [];

    if (layer && layer.layerStyling) {
      for (const sublayer of layer.layerStyling) {
        const cleanId = sublayer.id.replace(/\_/g, '-');

        if (!(excludeIds.filter(i => cleanId.includes(i)).length > 0)) {
          const paletteGroup: MapLayerPaletteGroupData = {
            layer: layer.layerName,
            subLayer: sublayer.id,
            paletteData: []
          };

          this.checkAndAddPaletteItem(
            paletteGroup,
            layer.layerName,
            sublayer,
            'fill',
            'paint',
            'fill-color'
          );
          this.checkAndAddPaletteItem(
            paletteGroup,
            layer.layerName,
            sublayer,
            'fill',
            'paint',
            'fill-opacity'
          );

          this.checkAndAddPaletteItem(
            paletteGroup,
            layer.layerName,
            sublayer,
            'circle',
            'paint',
            'circle-color'
          );

          this.checkAndAddPaletteItem(
            paletteGroup,
            layer.layerName,
            sublayer,
            'line',
            'paint',
            'line-color'
          );

          this.checkAndAddPaletteItem(
            paletteGroup,
            layer.layerName,
            sublayer,
            'symbol',
            'layout',
            'text-size'
          );
          this.checkAndAddPaletteItem(
            paletteGroup,
            layer.layerName,
            sublayer,
            'symbol',
            'paint',
            'text-color'
          );

          if (paletteGroup.paletteData.length) {
            paletteGroupList.push(paletteGroup);
          }
        }
      }
    }

    return paletteGroupList;
  }

  resetLayerStyling(
    mapObject: MapObject,
    layer: MapReferenceLayer,
    paletteGroup: MapLayerPaletteGroupData[]
  ) {
    if (layer && layer.originalLayerStyling) {
      const styling = layer.originalLayerStyling;

      paletteGroup.forEach(g => {
        const sublayer = styling.find(l => l.id === g.subLayer);

        // If can be inverted - reset both label and halo
        if (
          sublayer.metadata &&
          sublayer.metadata['ee:invert'] &&
          sublayer.layout &&
          sublayer.layout['text-field']
        ) {
          mapObject.map.setPaintProperty(
            sublayer.id,
            'text-color',
            sublayer['paint']['text-color']
          );
          mapObject.map.setPaintProperty(
            sublayer.id,
            'text-halo-color',
            sublayer['paint']['text-halo-color']
          );
        }

        if (g.paletteData && g.paletteData.length) {
          g.paletteData.forEach(i => {
            if (
              sublayer &&
              sublayer[i.propertyParent] &&
              sublayer[i.propertyParent][i.property]
            ) {
              if (i.propertyParent === 'paint') {
                mapObject.map.setPaintProperty(
                  g.subLayer,
                  i.property as keyof PaintSpecification,
                  sublayer[i.propertyParent][i.property]
                );
              } else if (i.propertyParent === 'layout') {
                mapObject.map.setLayoutProperty(
                  g.subLayer,
                  i.property as keyof LayoutSpecification,
                  sublayer[i.propertyParent][i.property]
                );

                // Reset min zoom
                if (
                  i.type === 'symbol' &&
                  layer.subLayerMinZoom &&
                  layer.subLayerMinZoom[g.subLayer]
                ) {
                  mapObject.map.setLayerZoomRange(
                    g.subLayer,
                    layer.subLayerMinZoom[g.subLayer],
                    22
                  );
                  layer.subLayerMinZoom[g.subLayer] = null;
                }
              }
            }
          });
        }

        // If can be inverted and inversion is enabled - re-invert after reset
        if (
          mapObject.invertedLabels &&
          sublayer.metadata &&
          sublayer.metadata['ee:invert'] &&
          sublayer.layout &&
          sublayer.layout['text-field']
        ) {
          const textColor = mapObject.map.getPaintProperty(
            sublayer.id,
            'text-color'
          );
          const textHaloColor = mapObject.map.getPaintProperty(
            sublayer.id,
            'text-halo-color'
          );

          mapObject.map.setPaintProperty(
            sublayer.id,
            'text-color',
            textHaloColor
          );
          mapObject.map.setPaintProperty(
            sublayer.id,
            'text-halo-color',
            textColor
          );
        }
      });

      layer.layerStyling = styling;
      layer.legendStyling = layer.originalLegendStyling;
      layer.originalLayerStyling = null;
      layer.originalLegendStyling = null;
      layer.stylingOverwritten = false;
      localStorage.setItem('ee-map-layer-palette-' + layer.layerName, null);
    }
  }

  updateLayerStyling(
    mapObject: MapObject,
    layer: MapReferenceLayer,
    paletteGroup: MapLayerPaletteGroupData[]
  ) {
    if (paletteGroup) {
      const styling = _.cloneDeep(layer.layerStyling);

      if (!layer.stylingOverwritten) {
        layer.originalLayerStyling = _.cloneDeep(layer.layerStyling);
        layer.originalLegendStyling = _.cloneDeep(layer.legendStyling);
        layer.stylingOverwritten = true;
      }

      // Save palette data to storage
      localStorage.setItem(
        'ee-map-layer-palette-' + layer.layerName,
        JSON.stringify(paletteGroup)
      );

      // Set new layer styling based on palette data
      paletteGroup.forEach(g => {
        const sublayer = styling.find(l => l.id === g.subLayer);
        if (g.paletteData && g.paletteData.length) {
          g.paletteData.forEach(i => {
            if (i.changed) {
              // patch for inverted

              if (
                sublayer &&
                sublayer[i.propertyParent] &&
                sublayer[i.propertyParent][i.property]
              ) {
                sublayer[i.propertyParent][i.property] = i.value;
              }

              if (i.propertyParent === 'paint') {
                mapObject.map.setPaintProperty(
                  g.subLayer,
                  i.property as keyof PaintSpecification,
                  i.value
                );
              } else if (i.propertyParent === 'layout') {
                mapObject.map.setLayoutProperty(
                  g.subLayer,
                  i.property as keyof LayoutSpecification,
                  i.value
                );

                // Check and override min zoom for any labels if applied
                if (i.type === 'symbol' && sublayer.minzoom) {
                  if (!layer.subLayerMinZoom) {
                    layer.subLayerMinZoom = {};
                  }
                  layer.subLayerMinZoom[g.subLayer] = sublayer.minzoom;

                  mapObject.map.setLayerZoomRange(g.subLayer, 0, 22);
                }
              }
            }
          });
        }
      });

      layer.layerStyling = styling;

      // Update legend styling based on the changes in palette data
      const findPaletteItem = (
        label: string,
        propertyLabel: string
      ): MapLayerPaletteData => {
        for (const group of paletteGroup) {
          if (group && group.paletteData) {
            for (const item of group.paletteData) {
              if (
                item.label === label &&
                item.propertyLabel === propertyLabel
              ) {
                return item;
              }
            }
          }
        }
      };

      const circleColor = findPaletteItem('Circle', 'Color');

      if (circleColor) {
        layer.legendStyling.fillColor = circleColor.value as string;
      } else {
        const areaColor = findPaletteItem('Area', 'Color');
        const areaOpacity = findPaletteItem('Area', 'Opacity');
        const lineColor = findPaletteItem('Line', 'Color');

        if (areaColor) {
          const color = tinycolor(areaColor.value as string);

          if (
            areaOpacity &&
            areaOpacity.value &&
            !isNaN(areaOpacity.value as any)
          ) {
            color.setAlpha(Number(areaOpacity.value));
          }

          layer.legendStyling.fillColor = color.isValid()
            ? color.toRgbString()
            : (areaColor.value as string);

          if (lineColor) {
            layer.legendStyling.strokeColor = lineColor.value as string;
          }
        } else {
          if (lineColor) {
            layer.legendStyling.fillColor = lineColor.value as string;
          }
        }
      }
    }
  }

  private getPaletteGroupFromLocalStorage(
    layerName: string
  ): MapLayerPaletteGroupData[] {
    const paletteGroupJSON = localStorage.getItem(
      'ee-map-layer-palette-' + layerName
    );
    return (
      paletteGroupJSON ? JSON.parse(paletteGroupJSON) : null
    ) as MapLayerPaletteGroupData[];
  }

  private checkLayerStylingOverwrites(
    mapObject: MapObject,
    layer: MapReferenceLayer
  ) {
    if (layer) {
      const paletteGroup = this.getPaletteGroupFromLocalStorage(
        layer.layerName
      );

      if (paletteGroup) {
        mapObject.map.once('idle', () => {
          this.updateLayerStyling(mapObject, layer, paletteGroup);
        });
      }
    }
  }
}
