import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from '../../interfaces/menu-item';
import { AppRoutes } from '../../enums/app-routes';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserEventService } from '../../services/user-event/user-event.service';
import { UserEvents } from '../../../shared/enums/user-events';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ee-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  // TO DO: Move all this stuff to MenuService
  menuItems: MenuItem[] = [
    {
      label: 'Admin',
      icon: 'cog',
      route: AppRoutes.ADMIN,
      hidden: true
    },
    {
      label: 'ERROR',
      icon: 'exclamation-triangle',
      route: AppRoutes.RESTRICTED_MODULE,
      hidden: true
    },
    {
      label: 'HUB',
      icon: 'home',
      route: AppRoutes.HUB,
      selected: true
    },
    {
      label: 'GAP',
      icon: 'map-marked-alt',
      route: AppRoutes.GAP
    },
    {
      label: 'VISION',
      icon: 'binoculars',
      route: AppRoutes.VISION
    },
    {
      label: 'PHOENIX',
      icon: 'jedi',
      route: AppRoutes.STORM
    },
    {
      label: 'Zhuri',
      icon: 'zhuri-logo-menu',
      path: environment.zhuriUrl,
      customIcon: true
    },
    {
      label: 'WASP',
      icon: 'wasp-menu',
      path: environment.waspUrl,
      customIcon: true
    },
    {
      label: 'PRO',
      icon: 'handshake',
      route: AppRoutes.PRO,
      comingSoon: true
      // }, {
      //   label: 'Master Download',
      //   icon: 'list',
      //   route: AppRoutes.MASTER_LIST,
    }
  ];

  constructor(
    private elRef: ElementRef,
    private router: Router,
    private userEventService: UserEventService
  ) {}

  ngOnInit() {
    this.getCurrentPath();
    this.watchRouterPath();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private getCurrentPath() {
    const menuItem = this.getMenuItemByUrl(this.router.url);
    if (menuItem) {
      this.setActiveMenuItem(menuItem);
    }
  }

  private watchRouterPath() {
    // Watch the current path to update the selected menu item
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe(val => {
      if (val instanceof NavigationEnd) {
        if (val.url) {
          const menuItem = this.getMenuItemByUrl(val.url);
          if (menuItem) {
            this.setActiveMenuItem(menuItem);
          }
        }
      }
    });
  }

  private getMenuItemByUrl(url: string): MenuItem {
    let menuItem: MenuItem = null;

    let pathSegments = url.split('/');

    if (pathSegments.length > 1) {
      let path = pathSegments[1];

      if (path.indexOf('?') > -1) {
        pathSegments = path.split('?');
        path = pathSegments[0];
      }

      for (const item of this.menuItems) {
        if (item.route === path) {
          menuItem = item;
          break;
        }
      }
    }

    return menuItem;
  }

  private setActiveMenuItem(currentItem: MenuItem) {
    if (currentItem.label !== 'ERROR') {
      // Record user event
      this.userEventService.logUserEvent({
        category: currentItem.label,
        event: UserEvents.MODULE_ACCESSED,
        metadata: {
          url: this.router.url
        }
      });
    }

    // Clear selected item
    for (const item of this.menuItems) {
      item.selected = false;
    }

    // Update top position of Menu Accent
    let index = this.menuItems.indexOf(currentItem);

    if (!currentItem.hidden) {
      index -= 2;
    } else if (currentItem.hidden && currentItem.label === 'ERROR') {
      index--;
    }

    this.elRef.nativeElement.querySelector('.menu-accent').style.top =
      index * 84 + 'px';

    // Set selected item
    currentItem.selected = true;
  }

  selectItem(currentItem) {
    // Record user event
    this.userEventService.logUserEvent({
      category: currentItem.label,
      event: UserEvents.MODULE_ACCESSED
    });

    if (currentItem.route) {
      // Navigate to route
      this.router.navigate([currentItem.route]);
    } else if (currentItem.path) {
      window.open(currentItem.path, '_blank');
    }
  }
}
