<div fxLayout="column" fxLayoutAlign="center center">
  <div *ngIf="isDecember()">
    <ee-app-snow></ee-app-snow>
  </div>
  <div
    class="bg-container"
    [ngStyle]="{
      'background-image': splashImageUrl
        ? 'url(' + splashImageUrl + ')'
        : 'none'
    }"
  >
    <div class="splash-info" *ngIf="splashSchool || splashBlurb">
      <div *ngIf="splashSchool" class="splash-school">{{ splashSchool }}</div>
      <div class="blurb" *ngIf="splashBlurb" [innerHTML]="splashBlurb"></div>
    </div>
  </div>
  <div
    class="info-section"
    fxLayout="column"
    fxLayoutAlign="center center"
    [perfectScrollbar]
  >
    <div class="logo-container" fxLayout="column" fxLayoutAlign="center center">
      <a href="/">
        <img src="assets/images/eagle-eye-logo.svg" alt="Eagle Eye Logo" />
      </a>
      <a href="/"><div class="title">EAGLE EYE</div></a>
    </div>

    <div class="tagline">
      <div class="tag">Living Innovation</div>
      <div class="blurb">
        Putting the student at the centre, Service Planning has implemented a
        map based platform for analysing and developing insights from multiple
        data sets across SINSW, DoE and external to the Department. Eagle Eye is
        intuitive, flexible and interactive; leveraging existing knowledge
        within SINSW.
      </div>
    </div>

    <div
      class="buttons"
      *ngIf="
        !maintenanceModeSettings?.maintenanceModeActive || maintenanceOverride
      "
    >
      <button mat-button class="primary" (click)="login()">
        <span>Login</span>
      </button>
      <button mat-button class="secondary" (click)="requestAccess()">
        <span>Request Access</span>
      </button>
    </div>

    <div
      class="maintenance-mode-message"
      *ngIf="maintenanceModeSettings?.maintenanceMode"
    >
      NOTE: Eagle Eye is
      <span
        *ngIf="
          maintenanceModeSettings.maintenanceStartDate &&
          maintenanceModeSettings.maintenanceStartDate
        "
      >
        {{
          maintenanceModeSettings?.maintenanceModeActive
            ? 'currently under maintenance from'
            : 'scheduled for maintenance between'
        }}
        <span class="bold">
          {{
            maintenanceModeSettings?.maintenanceStartDate
              | date: 'd MMMM y, h:mm a'
          }}
        </span>
        {{ maintenanceModeSettings?.maintenanceModeActive ? 'until' : 'and' }}
        <span class="bold">
          {{
            maintenanceModeSettings?.maintenanceEndDate
              | date: 'd MMMM y, h:mm a'
          }}
        </span>
      </span>
      <span
        *ngIf="
          !maintenanceModeSettings.maintenanceStartDate ||
          !maintenanceModeSettings.maintenanceStartDate
        "
      >
        currently under maintenance
      </span>
      and is not available for use.
    </div>

    <div fxFlex></div>

    <div class="footer">
      This is a private system. Data is approximate only. DoE cannot guarantee
      the accuracy of the data and does not accept responsibility for any
      consequences arising from its use. For internal use only. Unauthorised
      distribution is strictly forbidden.
      <br />
      <br />
      <strong>
        Eagle Eye v{{ appVersion }} &copy; SINSW
        {{ currentDate | date: 'yyyy' }}
      </strong>
    </div>
  </div>
</div>
