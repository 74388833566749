<div
  class="notification-menu-scroll-container"
  [perfectScrollbar]="{ suppressScrollX: true }"
  (scroll)="onScroll($event)"
  (wheel)="onWheel($event)"
>
  <div
    *ngIf="
      unreadNotificationData.length === 0 && readNotificationData.length === 0
    "
  >
    <button mat-menu-item>
      <div fxLayoutAlign="start">No notifications</div>
    </button>
  </div>

  <div *ngIf="unreadNotificationData.length > 0">
    <div class="header-name" fxLayout="row" fxLayoutAlign="start center">
      <mat-label class="menu-label">UNREAD</mat-label>
      <span
        class="count-badge unread"
        [matBadge]="unreadNotificationData?.length"
        matBadgeSize="small"
        matBadgeDisabled
      ></span>
      <div fxFlex></div>
      <div class="mark-link" (click)="markAllAsRead()">Mark all as read</div>
    </div>
    <mat-divider></mat-divider>
    <div
      class="notification-container"
      *ngFor="let notification of unreadNotificationData"
    >
      <button mat-menu-item (click)="performNotificationAction(notification)">
        <div class="notification-main" fxLayout="row">
          <div
            class="notification-icon-unread"
            [ngStyle]="{ color: notification.category.color }"
            (click)="
              readNotifications([notification.notificationId]);
              $event.stopPropagation()
            "
          >
            <mat-icon
              class="notification-unread-icon"
              fontSet="fas"
              [fontIcon]="notification.category.icon"
              *ngIf="activeNotification !== notification"
              (mouseover)="setActiveNotification(notification)"
              (mouseleave)="clearActiveNotification()"
            ></mat-icon>
            <mat-icon
              class="notification-unread-icon"
              fontSet="fas"
              fontIcon="fa-check"
              *ngIf="activeNotification === notification"
              (mouseover)="setActiveNotification(notification)"
              (mouseleave)="clearActiveNotification()"
            ></mat-icon>
          </div>
          <div class="notification-content" fxLayout="column">
            <div fxLayout="row" class="notification-content-columns">
              <div
                class="notification-title"
                [ngStyle]="
                  activeNotification !== notification
                    ? { color: notification.category.color }
                    : null
                "
              >
                {{ notification.title | titlecase }}
              </div>
              <div fxFlex></div>
              <div class="notification-time">
                {{ notification.displayDate | timeAgo }}
              </div>
            </div>
            <div
              class="summary"
              *ngIf="notification.summary"
              fxLayoutAlign="start"
              [ngClass]="{ hover: activeNotification === notification }"
            >
              {{ notification.summary }}
            </div>
            <div
              class="view-detail"
              *ngIf="
                notification.type === UserNotificationTypeEnum.NEWS &&
                notification.detail
              "
              fxLayoutAlign="start"
              [ngClass]="{ hover: activeNotification === notification }"
            >
              <strong>View Details</strong>
            </div>
          </div>
        </div>
      </button>
      <mat-divider></mat-divider>
    </div>
  </div>

  <div *ngIf="readNotificationData.length > 0">
    <div class="header-name" fxLayout="row" fxLayoutAlign="start center">
      <mat-label class="menu-label">READ</mat-label>
      <span
        class="count-badge"
        [matBadge]="readNotificationData?.length"
        matBadgeSize="small"
        matBadgeDisabled
      ></span>
      <div fxFlex></div>
      <mat-checkbox
        class="hide-checkbox"
        [(ngModel)]="hideMaintenanceNotifications"
        (click)="$event.stopPropagation()"
        (change)="filterReadNotifications()"
      >
        Hide maintenance
      </mat-checkbox>
    </div>
    <mat-divider></mat-divider>
    <ng-container
      *ngFor="let notification of readNotificationData; last as last"
    >
      <div class="notification-container" *ngIf="!notification.hidden">
        <button mat-menu-item (click)="performNotificationAction(notification)">
          <div class="notification-main" fxLayout="row">
            <div
              class="notification-icon-read"
              [ngStyle]="{ color: notification.category.color }"
            >
              <mat-icon
                class="notification-read-icon"
                fontSet="fas"
                [fontIcon]="notification.category.icon"
              ></mat-icon>
            </div>
            <div class="notification-content" fxLayout="column">
              <div fxLayout="row" class="notification-content-columns">
                <div
                  class="notification-title"
                  [ngStyle]="{ color: notification.category.color }"
                >
                  {{ notification.title | titlecase }}
                </div>
                <div fxFlex></div>
                <div class="notification-time">
                  {{ notification.displayDate | timeAgo }}
                </div>
              </div>
              <div
                class="summary"
                *ngIf="notification.summary"
                fxLayoutAlign="start"
              >
                {{ notification.summary }}
              </div>
              <div
                class="view-detail"
                *ngIf="
                  notification.type === UserNotificationTypeEnum.NEWS &&
                  notification.detail
                "
                fxLayoutAlign="start"
              >
                <strong>View Details</strong>
              </div>
            </div>
          </div>
        </button>
        <mat-divider *ngIf="!last"></mat-divider>
      </div>
    </ng-container>
  </div>

  <div
    class="scroll-bubble"
    [ngClass]="{ tease: teaseUpdate && !updating, updating: updating }"
  >
    <mat-progress-spinner
      mode="indeterminate"
      class="notification-update-spinner"
      *ngIf="updating"
    ></mat-progress-spinner>
  </div>
</div>
