export enum HubRoutes {
  NSW = 'nsw',
  LGA = 'lga',
  FED = 'fed-region',
  SED = 'sed-region',
  DET = 'det-region',
  CLUSTER = 'school-community-group', //Change #12120
  SCHOOL = 'school',
  OPERATIONAL_DIRECTORATE = 'school-performance-directorate',
  PRINCIPAL_NETWORK = 'principal-network',
  DPIE_REGION = 'dpie-region',
  CUSTOM_REF = 'custom-ref',
  SCHOOL_CATCHMENTS = 'school_catchments',
  CUSTOM = 'custom',
  REACHABILITY = 'reachability'
}
