import { Injectable } from '@angular/core';
import { PlannedArea } from '../../interfaces/planned-area';
import { PlannedAreaAnnotation } from '../../interfaces/planned-area-annotation';
import { ApiDeleteResponse } from '../../../core/interfaces/api-delete-response';
import { PlannedAreaFieldValues } from '../../interfaces/planned-area-field-values';
import { PlannedAreaFieldDefinitions } from '../../interfaces/planned-area-field-definitions';
import { environment } from '../../../../environments/environment';
import { PlannedAreaAnnotationTypes } from '../../interfaces/planned-area-annotation-types';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PlannedAreaComment } from '../../interfaces/planned-area-comment';
import { FileExportType } from '../../../shared/enums/file-export-type';
import { PlannedAreaOptionDefinitions } from '../../interfaces/planned-area-option-definitions';
import { PlannedAreaDetails } from '../../interfaces/planned-area-details';

@Injectable({
  providedIn: 'root'
})
export class GapApiCommsService {
  private gapUrl =
    environment.backendServer.mainPath + environment.backendServer.plannedAreas;

  constructor(private http: HttpClient) {}

  // Planned areas calls

  getAllPlannedAreas(): Observable<PlannedArea[]> {
    const url = this.gapUrl + '/areas';
    return this.http.get<PlannedArea[]>(url);
  }

  getPlannedArea(paId: number): Observable<PlannedAreaDetails> {
    const url = this.gapUrl + '/areas/' + paId;
    return this.http.get<PlannedAreaDetails>(url);
  }

  postPlannedArea(
    plannedAreaData: PlannedAreaDetails
  ): Observable<PlannedAreaDetails> {
    const url = this.gapUrl + '/areas';
    return this.http.post<PlannedAreaDetails>(url, plannedAreaData);
  }

  putPlannedArea(
    paId: number,
    plannedAreaData: PlannedAreaDetails
  ): Observable<PlannedAreaDetails> {
    const url = this.gapUrl + '/areas/' + paId;
    return this.http.put<PlannedAreaDetails>(url, plannedAreaData);
  }

  deletePlannedArea(paId: number): Observable<ApiDeleteResponse> {
    const url = this.gapUrl + '/areas/' + paId;
    return this.http.delete<ApiDeleteResponse>(url);
  }

  getPlannedAreaAnnotation(paId: number): Observable<PlannedAreaAnnotation[]> {
    const url = this.gapUrl + '/areas/' + paId + '/annotations';
    return this.http.get<PlannedAreaAnnotation[]>(url);
  }

  postPlannedAreaAnnotation(
    paId: number,
    annotationData: PlannedAreaAnnotation
  ): Observable<PlannedAreaAnnotation> {
    const url = this.gapUrl + '/areas/' + paId + '/annotations';
    return this.http.post<PlannedAreaAnnotation>(url, annotationData);
  }

  putPlannedAreaAnnotation(
    paId: number,
    annotId: number,
    annotationData: PlannedAreaAnnotation
  ): Observable<PlannedAreaAnnotation> {
    const url = this.gapUrl + '/areas/' + paId + '/annotations/' + annotId;
    return this.http.put<PlannedAreaAnnotation>(url, annotationData);
  }

  deletePlannedAreaAnnotation(
    paId: number,
    annotId: number
  ): Observable<ApiDeleteResponse> {
    const url = this.gapUrl + '/areas/' + paId + '/annotations/' + annotId;
    return this.http.delete<ApiDeleteResponse>(url);
  }

  getPlannedAreaComments(paId: number): Observable<PlannedAreaComment[]> {
    const url = this.gapUrl + '/areas/' + paId + '/comments';
    return this.http.get<PlannedAreaComment[]>(url);
  }

  postPlannedAreaComment(
    paId: number,
    comment: string
  ): Observable<PlannedAreaComment> {
    const url = this.gapUrl + '/areas/' + paId + '/comments';
    return this.http.post<PlannedAreaComment>(url, {
      comment: comment
    });
  }

  putPlannedAreaComment(
    paId: number,
    commentId: number,
    commentData: PlannedAreaComment
  ): Observable<PlannedAreaComment> {
    const url = this.gapUrl + '/areas/' + paId + '/comments/' + commentId;
    return this.http.put<PlannedAreaComment>(url, commentData);
  }

  deletePlannedAreaComment(
    paId: number,
    commentId: number
  ): Observable<ApiDeleteResponse> {
    const url = this.gapUrl + '/areas/' + paId + '/comments/' + commentId;
    return this.http.delete<ApiDeleteResponse>(url);
  }

  getOptionsDefinitions(): Observable<PlannedAreaOptionDefinitions> {
    const url = this.gapUrl + '/defs/options';
    return this.http.get<PlannedAreaOptionDefinitions>(url);
  }

  getPlannedAreaAnnotationTypes(): Observable<PlannedAreaAnnotationTypes[]> {
    const url = this.gapUrl + '/defs/mapAnnotations';
    return this.http.get<PlannedAreaAnnotationTypes[]>(url);
  }

  getFileExport(type: FileExportType, paId: number): Observable<Blob> {
    const url = this.gapUrl + `/areas/${paId}/export/${type}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  exportSpreadsheet(paId: number): Observable<Blob> {
    const url = this.gapUrl + `/areas/${paId}/export/spreadsheet`;
    return this.http.get(url, { responseType: 'blob' });
  }
}
