import { Injectable } from '@angular/core';
import introJs from 'intro.js/intro.js';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  intro: introJs;
  enabled = false;
  tempEnabled = false;

  constructor(private router: Router) {
    this.intro = introJs();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.enabled) {
          this.tempEnabled = true;
          setTimeout(() => this.disableHints());
        }
      } else if (event instanceof NavigationEnd) {
        if (this.tempEnabled) {
          this.tempEnabled = false;
          setTimeout(() => this.enableHints());
        }
      }
    });

    this.intro.setOptions({
      hints: [
        {
          element: '.map-legend-content',
          hint: this.generateHintHTML(
            'Legend Panel',
            'fa-question',
            'legend-panel'
          ),
          hintPosition: 'top-middle'
        },
        {
          element: '.map-controls',
          hint: this.generateHintHTML('Map controls', 'fa-question', 'zoom'),
          hintPosition: 'middle-middle'
        },
        {
          element: '.toolbar-reference-button',
          hint: this.generateHintHTML(
            'Reference Layers',
            'fa-layer-group',
            'reference-layer-panel'
          ),
          hintPosition: 'middle-middle'
        },
        {
          element: '.toolbar-inspector-button',
          hint: this.generateHintHTML(
            'Map information tool',
            'fa-crosshairs',
            'inspector-tool'
          ),
          hintPosition: 'middle-middle'
        },
        {
          element: '.toolbar-line-button',
          hint: this.generateHintHTML(
            'Line measuring tool',
            'fa-ruler',
            'line-measuring-tool'
          ),
          hintPosition: 'middle-middle'
        },
        {
          element: '.toolbar-area-button',
          hint: this.generateHintHTML(
            'Area measuring tool',
            'fa-draw-square',
            'area-measuring-tool'
          ),
          hintPosition: 'middle-middle'
        },
        {
          element: '.toolbar-radius-button',
          hint: this.generateHintHTML(
            'Radius measuring tool',
            'fa-draw-circle',
            'radius-measuring-tool'
          ),
          hintPosition: 'middle-middle'
        },
        {
          element: '.toolbar-travel-button',
          hint: this.generateHintHTML(
            'Travel distance / time measuring tool',
            'fa-route',
            'travel-distance-measuring-tool'
          ),
          hintPosition: 'middle-middle'
        },
        {
          element: '.toolbar-3d-button',
          hint: this.generateHintHTML(
            '3D School Visualization',
            'fa-cube',
            '3D-building-view'
          ),
          hintPosition: 'middle-middle'
        },
        {
          element: '.toolbar-sv-button',
          hint: this.generateHintHTML(
            'Street View',
            'fa-street-view',
            'street-view'
          ),
          hintPosition: 'middle-middle'
        },
        {
          element: '.add-planned-area-button',
          hint: this.generateHintHTML(
            'Add a new planned area',
            'fa-image',
            'gap-drawing-tool'
          ),
          hintPosition: 'middle-middle'
        },
        {
          element: '.toolbar-annotation-button',
          hint: this.generateHintHTML(
            'Add annotation',
            'fa-image',
            'gap-annotation-tool'
          ),
          hintPosition: 'middle-middle'
        },
        {
          element: '.toolbar-export-button',
          hint: this.generateHintHTML(
            'Export map image',
            'fa-image',
            'export-map-image'
          ),
          hintPosition: 'middle-middle'
        },
        {
          element: '.menu-items',
          hint: this.generateHintHTML(
            'Menu Items',
            'fa-question',
            'left-hand-menu'
          ),
          hintPosition: 'middle-middle'
        },
        {
          element: '.search',
          hint: this.generateHintHTML('Search', 'fa-search', 'search'),
          hintPosition: 'middle-middle'
        }
      ]
    });

    this.intro.onhintclose(stepId => {
      // Stupid library removes hints after they are opened - re-add them back if hints are enabled
      if (this.enabled) {
        this.intro.showHint(stepId);
      }
    });
  }

  generateHintHTML(title: string, icon: string, gifImage: string) {
    return `
      <div class="help-title">
        <i class="fa ${icon}"></i> ${title}
      </div>
      <div class="help-gif">
        <img src="assets/images/help-gif/${gifImage}.gif">
      </div>
    `;
  }

  toggleHints() {
    if (this.enabled) {
      this.disableHints();
    } else {
      this.enableHints();
    }
  }

  private enableHints() {
    this.enabled = true;
    this.intro.addHints();
    this.intro.showHints();
    this.intro.refresh();
  }

  private disableHints() {
    this.enabled = false;
    this.intro.hideHints();
    this.intro.removeHints();
  }

  // ?
  public refreshHints() {
    if (this.enabled) {
      this.disableHints();
      this.enableHints();
    }
  }
}
