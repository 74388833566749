import {
  CUSTOM_ELEMENTS_SCHEMA,
  ModuleWithProviders,
  NgModule
} from '@angular/core';
import { MapSearchComponent } from './components/map-search/map-search.component';
import { MapComponent } from './components/map/map.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';

import { MaterialModule } from './modules/material.module';
import { MapGroupComponent } from './components/map-group/map-group.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TooltipDirective } from './directives/tooltip/tooltip.directive';
import { MapMeasurePanelComponent } from './components/map-measure-panel/map-measure-panel.component';
import { StreetViewComponent } from './components/street-view/street-view.component';
import { MapControlsComponent } from './components/map-controls/map-controls.component';
import { MapToolbarComponent } from './components/map-toolbar/map-toolbar.component';
import { MapReferenceLayerPanelComponent } from './components/map-reference-layer-panel/map-reference-layer-panel.component';
import { MapBasemapPanelComponent } from './components/map-basemap-panel/map-basemap-panel.component';
import { InstructionsComponent } from './components/instructions/instructions.component';
import { MapLegendComponent } from './components/map-legend/map-legend.component';
import { DragulaModule } from 'ng2-dragula';
import { NotificationPanelService } from './services/notification-panel/notification-panel.service';
import { MapboxSdkService } from './services/mapbox-sdk/mapbox-sdk.service';
import { MapSearchService } from './services/map-search/map-search.service';
import { MapToolbarService } from './services/map-toolbar/map-toolbar.service';
import { MapBasemapService } from './services/map-basemap/map-basemap.service';
import { MapControlsService } from './services/map-controls/map-controls.service';
import { StreetViewService } from './services/street-view/street-view.service';
import { InstructionsService } from './services/instructions/instructions.service';
import { MapReferenceLayersService } from './services/map-reference-layers/map-reference-layers.service';
import { MapImageExportService } from './services/map-image-export/map-image-export.service';
import { MapMeasureToolsService } from './services/map-measure-tools/map-measure-tools.service';
import { MapboxService } from './services/mapbox/mapbox.service';
import { MapInspectorPanelComponent } from './components/map-inspector-panel/map-inspector-panel.component';
import { RouterModule } from '@angular/router';
import { MapStatsComponent } from './components/map-stats/map-stats.component';
import { SafeHtmlPipe } from './pipes/safe-html-pipe';
import { ScrollSpyDirective } from './directives/scroll-spy/scroll-spy.directive';
import { MapTooltipComponent } from './components/map-tooltip/map-tooltip.component';
import { CdkVirtualScrollViewportResizePatchDirective } from './directives/cdk-virtual-scroll-viewport-resize-patch/cdk-virtual-scroll-viewport-resize-patch.directive';
import { MapDateSelectorComponent } from './components/map-date-selector/map-date-selector.component';
import { MapReachabilityPanelComponent } from './components/map-reachability-panel/map-reachability-panel.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TimeAgoPipe } from './pipes/time-ago-pipe';
import { RelativeDatePipe } from './pipes/relative-date-pipe';
import { CurrencyFormatterDirective } from './directives/currency-formatter/currency-formatter.directive';
import { MapAdvancedSearchComponent } from './components/map-advanced-search/map-advanced-search.component';
import { MapDataImportUploadDialogComponent } from './components/map-data-import-upload-dialog/map-data-import-upload-dialog.component';
import { MapDataImportSelectionDialogComponent } from './components/map-data-import-selection-dialog/map-data-import-selection-dialog.component';
import { MapExportDialogComponent } from './components/map-export-dialog/map-export-dialog.component';
import { MapPaletteDialogComponent } from './components/map-palette-dialog/map-palette-dialog.component';
import { MapFavoriteLayerResolverService } from '../core/resolvers/map-favorite-layer-resolver/map-favorite-layer-resolver.service';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {};

const MODULES = [
  CommonModule,
  MaterialModule,
  FlexLayoutModule,
  FormsModule,
  EditorModule,
  CodemirrorModule,
  ReactiveFormsModule,
  PerfectScrollbarModule,
  DragulaModule,
  RouterModule
];

const SHARED_SERVICES = [
  MapboxService,
  NotificationPanelService,
  MapSearchService,
  MapboxSdkService,
  MapToolbarService,
  MapBasemapService,
  MapReferenceLayersService,
  MapFavoriteLayerResolverService,
  MapControlsService,
  StreetViewService,
  InstructionsService,
  MapImageExportService,
  MapMeasureToolsService
];

@NgModule({
  declarations: [
    MapComponent,
    MapSearchComponent,
    MapReferenceLayerPanelComponent,
    MapGroupComponent,
    TooltipDirective,
    MapMeasurePanelComponent,
    StreetViewComponent,
    MapToolbarComponent,
    MapControlsComponent,
    MapBasemapPanelComponent,
    InstructionsComponent,
    MapLegendComponent,
    MapInspectorPanelComponent,
    MapStatsComponent,
    SafeHtmlPipe,
    TimeAgoPipe,
    RelativeDatePipe,
    ScrollSpyDirective,
    CdkVirtualScrollViewportResizePatchDirective,
    MapTooltipComponent,
    MapDateSelectorComponent,
    MapReachabilityPanelComponent,
    CurrencyFormatterDirective,
    MapAdvancedSearchComponent,
    MapDataImportUploadDialogComponent,
    MapDataImportSelectionDialogComponent,
    MapExportDialogComponent,
    MapPaletteDialogComponent
  ],
  imports: MODULES,
  exports: [
    ...MODULES,
    MapInspectorPanelComponent,
    MapGroupComponent,
    StreetViewComponent,
    InstructionsComponent,
    TooltipDirective,
    ScrollSpyDirective,
    SafeHtmlPipe,
    TimeAgoPipe,
    RelativeDatePipe,
    CdkVirtualScrollViewportResizePatchDirective,
    CurrencyFormatterDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ...SHARED_SERVICES,
        {
          provide: PERFECT_SCROLLBAR_CONFIG,
          useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
      ]
    };
  }
}
