import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { MapboxService } from '../../services/mapbox/mapbox.service';
import { MapObject } from '../../interfaces/map-object';
import { Subject } from 'rxjs';
import { MapBasemap } from '../../interfaces/map-basemap';
import { takeUntil } from 'rxjs/operators';
import { MapBasemapResolverService } from '../../../core/resolvers/map-basemap-resolver/map-basemap-resolver.service';
import { MapBasemapName } from '../../enums/map-basemap-name';

@Component({
  selector: 'ee-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() mapId: string;
  @Input() showAttribution = true;
  @Input() interactive = true;
  @Input() mapControlsEnabled = true;
  @Input() basemapName?: MapBasemapName = null;
  @Output() createCallback = new EventEmitter();
  mapObject: MapObject = null;
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private mapboxService: MapboxService,
    private mapBasemapDataService: MapBasemapResolverService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.onBasemapDataReady();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private onBasemapDataReady() {
    this.mapBasemapDataService
      .onBasemapDataReady()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        if (result) {
          // Get style
          let basemap: MapBasemap = null;
          if (!this.basemapName) {
            basemap = this.mapBasemapDataService.getDefaultBasemap();
          } else {
            basemap = this.mapBasemapDataService.getBasemapByName(
              this.basemapName
            );
          }

          // Wait till dom is ready
          setTimeout(() => {
            this.mapObject = this.mapboxService.createMap(
              this.mapId,
              this.showAttribution,
              basemap,
              this.interactive,
              mapObject => {
                this.createCallback.emit(mapObject);
              }
            );
          });
        }
      });
  }
}
