import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth/auth.guard';
import { AppRoutes } from './enums/app-routes';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { MapBasemapResolverService } from './resolvers/map-basemap-resolver/map-basemap-resolver.service';
import { MapReferenceLayerResolverService } from './resolvers/map-reference-layer-resolver/map-reference-layer-resolver.service';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { SchoolDatesResolverService } from './resolvers/school-dates-resolver/school-dates-resolver.service';
import { MaintenanceGuard } from './guards/maintenance/maintenance.guard';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { CurrentUserResolverService } from './resolvers/current-user-resolver/current-user-resolver.service';
import { OtpConfigResolverService } from './resolvers/otp-config-resolver/otp-config-resolver.service';
import { UnsubscribePageComponent } from './components/unsubscribe-page/unsubscribe-page.component';
import { RestrictedPageComponent } from './components/restricted-page/restricted-page.component';
import { ShapeDownloadsResolverService } from './resolvers/shape-downloads-resolver/shape-downloads-resolver.service';
import { UserAgreementGuard } from './guards/user-agreement/user-agreement.guard';
import { DocumentLinksResolverService } from './resolvers/document-links-resolver/document-links-resolver.service';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { MapFavoriteLayerResolverService } from './resolvers/map-favorite-layer-resolver/map-favorite-layer-resolver.service';
import { AdminSchoolMasterListComponent } from '../admin/components/admin-school-master-list/admin-school-master-list.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, MaintenanceGuard, UserAgreementGuard],
    component: MainPageComponent,
    resolve: {
      mapBasemaps: MapBasemapResolverService,
      mapReferenceLayers: MapReferenceLayerResolverService,
      loadFavLayers: MapFavoriteLayerResolverService,
      schoolDates: SchoolDatesResolverService,
      documentLinks: DocumentLinksResolverService,
      currentUser: CurrentUserResolverService,
      otpConfig: OtpConfigResolverService,
      shapeDownloads: ShapeDownloadsResolverService
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: AppRoutes.HUB
      },
      {
        path: AppRoutes.HUB,
        loadChildren: () => import('../hub/hub-module').then(m => m.HubModule)
      },
      {
        path: AppRoutes.GAP,
        loadChildren: () =>
          import('../planned-areas/planned-areas.module').then(
            m => m.PlannedAreasModule
          )
      },
      {
        path: AppRoutes.VISION,
        loadChildren: () =>
          import('../vision/vision.module').then(m => m.VisionModule)
      },
      {
        path: AppRoutes.STORM,
        loadChildren: () =>
          import('../storm/storm.module').then(m => m.StormModule)
      },
      {
        path: AppRoutes.TAM,
        component: ComingSoonComponent
      },
      {
        path: AppRoutes.PRO,
        component: ComingSoonComponent
      },
      {
        path: AppRoutes.MEDIA,
        component: ComingSoonComponent
      },
      {
        path: AppRoutes.ADMIN,
        loadChildren: () =>
          import('../admin/admin-module').then(m => m.AdminModule)
      },
      {
        path: AppRoutes.RESTRICTED_MODULE,
        component: RestrictedPageComponent,
        data: {
          module: true
        }
      },
      {
        path: AppRoutes.ERROR,
        component: ErrorPageComponent
      },
      {
        path: AppRoutes.MASTER_LIST,
        component: AdminSchoolMasterListComponent
      }
    ]
  },
  {
    path: AppRoutes.LOGIN,
    component: LoginPageComponent
  },
  {
    path: AppRoutes.UNSUBSCRIBE,
    component: UnsubscribePageComponent
  },
  {
    path: AppRoutes.MAINTENANCE,
    component: MaintenanceComponent
  },
  {
    path: AppRoutes.RESTRICTED,
    component: RestrictedPageComponent
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class CoreRoutingModule {}
