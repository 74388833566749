import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IconLoaderService {
  // Automatically load svg icons from folder
  private icons = [
    'annot-community',
    'annot-development',
    'annot-dwellings',
    'annot-environment',
    'annot-general',
    'annot-political',
    'annot-school-primary',
    'annot-school-secondary',
    'map-location',
    'sv-marker',
    'interactive-layer-icon',
    '3d-mode',
    'school',
    'solar',
    'new-school',
    'upgrade-school',
    'household-size',
    'cooler-classrooms',
    'minor-works',
    'tafe',
    'baby',
    'zhuri-logo',
    'zhuri-logo-menu',
    'wasp-menu',
    'fire',
    'flood',
    'warning',
    'star',
    'roadworks',
    'bolt',
    'sun-haze',
    'faucet-drip',
    'vpa',
    'health',
    'building',
    'house',
    'lightbulb-on',
    'running'
  ];

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  load() {
    for (const icon of this.icons) {
      this.iconRegistry.addSvgIcon(
        icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(
          `${environment.assetsFolder}${icon}.svg`
        )
      );
    }
  }
}
