import { Injectable } from '@angular/core';
import { MapReferenceLayer } from '../../interfaces/map-reference-layer';
import { MapReferenceLayerDefs } from '../../enums/map-reference-layer-defs';
import { UserEventCategories } from '../../enums/user-event-categories';
import { UserEvents } from '../../enums/user-events';
import { MapObject } from '../../interfaces/map-object';
import { MapReferenceLayersService } from '../map-reference-layers/map-reference-layers.service';
import { UserEventService } from '../../../core/services/user-event/user-event.service';
import { MapboxService } from '../mapbox/mapbox.service';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/core/enums/app-routes';
import { HubRoutes } from 'src/app/hub/enums/hub-routes';
import { HubMappingService } from 'src/app/hub/services/hub-mapping/hub-mapping.service';

@Injectable({
  providedIn: 'root'
})
export class Map3dViewService {
  private enabled = false;
  private schools3dLayer: MapReferenceLayer | null = null;

  constructor(
    private mapReferenceLayerService: MapReferenceLayersService,
    private userEventService: UserEventService,
    private mapboxService: MapboxService,
    private hubMappingService: HubMappingService,
    private router: Router
  ) {}

  private get3dLayer(mapObject: MapObject): MapReferenceLayer {
    if (this.schools3dLayer) {
      return this.schools3dLayer;
    }
    this.schools3dLayer = this.mapReferenceLayerService.getLayerByLayerName(
      mapObject,
      MapReferenceLayerDefs.SCHOOLS_3D
    );
    return this.schools3dLayer;
  }

  applyFilterBasedOnView(mapObject: MapObject, layer: MapReferenceLayer) {
    // apply filter
    // a dumb way to discover views... a better way??
    const url = this.router.url;
    const [_, app, view, indicator] = url.split('/');
    if (app === AppRoutes.HUB) {
      if (view === HubRoutes.SCHOOL) {
        const schoolId = Number(indicator);
        if (schoolId) {
          // school view: the filter should be based on the school id
          this.mapReferenceLayerService.applyPropertyFilter(mapObject, layer, {
            field: layer.idColumn,
            value: schoolId,
            operator: '=='
          });
        }
      } else {
        // other views
        this.mapReferenceLayerService.applySpatialPropertyFilter(
          mapObject,
          layer,
          this.hubMappingService.getSpatialFilterSetValue()
        );
      }
    }
  }

  is3dViewEnabled() {
    return this.enabled;
  }

  add3dLayer(mapObject: MapObject) {
    const refLayer = this.get3dLayer(mapObject);
    // add 3d layer and apply filter
    this.mapReferenceLayerService.addVectorLayer(mapObject, refLayer);
    this.applyFilterBasedOnView(mapObject, refLayer);
  }

  remove3dLayer(mapObject: MapObject) {
    const refLayer = this.mapReferenceLayerService.getLayerByLayerName(
      mapObject,
      MapReferenceLayerDefs.SCHOOLS_3D
    );
    if (refLayer && refLayer.enabled) {
      this.mapReferenceLayerService.removeVectorLayer(mapObject, refLayer);
    }
  }

  hide3dLayer(mapObject: MapObject) {
    const refLayer = this.mapReferenceLayerService.getLayerByLayerName(
      mapObject,
      MapReferenceLayerDefs.SCHOOLS_3D
    );
    this.mapReferenceLayerService.hideLayer(mapObject, refLayer);
  }

  show3dLayer(mapObject: MapObject) {
    const refLayer = this.mapReferenceLayerService.getLayerByLayerName(
      mapObject,
      MapReferenceLayerDefs.SCHOOLS_3D
    );
    this.mapReferenceLayerService.showLayer(mapObject, refLayer);
  }

  reset3dView(mapObject: MapObject) {
    this.mapboxService.toggle3d(mapObject, false);
    this.enabled = false;
    this.remove3dLayer(mapObject);
  }

  toggle3dView(mapObject: MapObject) {
    this.enabled = !this.enabled;

    this.userEventService.logUserEvent({
      category: UserEventCategories.TOOLBAR,
      event: this.enabled
        ? UserEvents.SCHOOLS_3D_VIZ_ON
        : UserEvents.SCHOOLS_3D_VIZ_OFF
    });

    if (this.enabled) {
      mapObject.map.easeTo({ pitch: 60 });
      this.mapboxService.toggle3d(mapObject, true);
      this.add3dLayer(mapObject);
    } else {
      mapObject.map.easeTo({ pitch: 0 });
      this.mapboxService.toggle3d(mapObject, false);
      this.remove3dLayer(mapObject);
    }
  }
}
