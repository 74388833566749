<div class="container map-control-panel">
  <button mat-button (click)="zoomIn()">
    <mat-icon fontSet="fas" fontIcon="fa-plus"></mat-icon>
  </button>
  <button mat-button (click)="zoomOut()">
    <mat-icon fontSet="fas" fontIcon="fa-minus"></mat-icon>
  </button>
  <button mat-button (click)="resetMapView()" class="location-arrow">
    <mat-icon
      [svgIcon]="'map-location'"
      [ngStyle]="{ transform: 'rotate(' + mapRotation + 'deg)' }"
    ></mat-icon>
  </button>
</div>
