import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { MapInspectorService } from '../../services/map-inspector/map-inspector.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MapObject } from '../../interfaces/map-object';
import { GeoreferenceResults } from '../../interfaces/georeference-results';
import { AppRoutes } from '../../../core/enums/app-routes';
import { HubRoutes } from '../../../hub/enums/hub-routes';
import { NotificationPanelService } from '../../services/notification-panel/notification-panel.service';
import { NotificationPopupType } from 'src/app/core/enums/notification-popup-type';

@Component({
  selector: 'ee-map-inspector-panel',
  templateUrl: './map-inspector-panel.component.html',
  styleUrls: ['./map-inspector-panel.component.scss']
})
export class MapInspectorPanelComponent
  implements OnInit, OnDestroy, OnChanges
{
  AppRoutesEnum = AppRoutes;
  HubRoutesEnum = HubRoutes;

  @Input() mapObject: MapObject;

  georefResults: GeoreferenceResults = null;
  visible = false;
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private mapInspectorService: MapInspectorService,
    private notificationPanelService: NotificationPanelService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.close();
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mapObject && this.mapObject) {
      this.watchInspectorPanelVisibility();
      this.watchGeoreferenceResults();
    }
  }

  private watchInspectorPanelVisibility() {
    this.mapObject.mapInspectorObject.onVisibilityChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(visible => {
        this.visible = visible;
        if (this.visible) {
          this.georefResults = null;
        }
      });
  }

  private watchGeoreferenceResults() {
    this.mapObject.mapInspectorObject.onGeoreferenceUpdate
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        this.georefResults = result;
      });
  }

  copyToClipboard = async () => {
    try {
      const coordinates =
        this.georefResults.coordinates[1].toFixed(4).toString() +
        ',' +
        this.georefResults.coordinates[0].toFixed(4).toString();
      await navigator.clipboard.writeText(coordinates);
      this.showCopiedNotificationPopup();
    } catch (err) {
      console.error('Failed to copy!', err);
    }
  };

  private showCopiedNotificationPopup() {
    this.notificationPanelService.showNotification({
      type: NotificationPopupType.SUCCESS,
      title: 'Coordinates Copied',
      message: `Coordinates copied to clipboard`
    });
  }

  close() {
    this.mapInspectorService.disable(this.mapObject);
  }
}
