import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { ApiCommsService } from '../../services/api-comms/api-comms.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SchoolDatesResolverService implements Resolve<Date[]> {
  private onSchoolDatesReadySubject: BehaviorSubject<Date[]> =
    new BehaviorSubject<Date[]>(null);

  constructor(private apiCommsService: ApiCommsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Date[]> {
    return this.apiCommsService.getSchoolDates().pipe(
      map((schoolDatesStr: string[]) => {
        let schoolDates: Date[] = null;
        if (schoolDatesStr) {
          schoolDates = [];
          schoolDatesStr.forEach(date => {
            schoolDates.push(new Date(date));
          });
        }
        return schoolDates;
      }),
      tap(schoolDates => {
        this.onSchoolDatesReadySubject.next(schoolDates);
      })
    );
  }

  onSchoolDatesReady(): Observable<Date[]> {
    return this.onSchoolDatesReadySubject;
  }
}
