import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { AuthService } from '../auth/auth.service';
import { ApiCommsService } from '../api-comms/api-comms.service';

@Injectable({
  providedIn: 'root'
})
export class UserAgreementService {
  constructor(
    private confirmDialog: ConfirmationDialogService,
    private authService: AuthService,
    private apiCommsService: ApiCommsService
  ) {}

  showUserTocDialog(skipAgree?: boolean): Observable<any> {
    return this.confirmDialog.showDialog(
      'Terms of Use',
      `<b>Terms of use</b><br>
        Eagle Eye by School Infrastructure NSW presents a range of internal and external data for analytical purpose. The tool also \
        provides school information, such as the local enrolment area, contact details, whether there is a preschool attached, outside \
        of school hours care information and so on.<br><br> \
        By using Eagle Eye you are accepting these terms of use. They take effect from the date you first use Eagle Eye We may at any \
        time revise these terms without notice. Continued use of Eagle Eye after a change has been made is your acceptance of the change. \
        <br><br> \
        <b>Using Eagle Eye</b><br>
        You agree to use Eagle Eye for lawful purposes and in a manner that does not infringe upon; or restrict or inhibit, the use \
        and enjoyment of Eagle Eye, by any third party.<br><br> \
        Such restriction or inhibition includes, without limitation, any of the following: \
        <ul> \
          <li>conduct which is unlawful</li> \
          <li>conduct which may harass or cause distress or inconvenience to any person</li> \
          <li>the transmission of obscene or offensive content</li> \
          <li>disruption of normal flow of dialogue within this website.</li> \
        </ul> \
        <b>Using Eagle Eye content</b><br> \
        Every effort is taken to ensure that all information presented by Eagle Eye is up-to-date. Due to the evolving nature of school \
        enrolment areas and school information, no responsibility can be taken by the NSW Department of Education, or any of its \
        associated departments, if information is relied upon; for example, but not limited to, real estate purchases or rentals where \
        Eagle Eye is used as a reference source.<br><br> \
        We do not warrant or represent that the functions contained in the material available on Eagle Eye will be uninterrupted or \
        error free, that defects will be corrected, or that this tool or the server that makes it available are free of viruses or \
        represent the full functionality, accuracy or reliability of the materials.<br><br> \
        <b>Other government services</b><br> \
        When using Eagle Eye you may review data that is delivered by another government department or external agency. Each of these \
        services will have its own unique terms and conditions. Users of any service should ensure they have read the relevant terms and \
        conditions before completing that transaction.<br><br> \
        <b>Google services</b><br> \
        Eagle Eye uses tools provided by Google to perform certain functions. Users of Eagle Eye are thereby bound by Google's Terms
        of Service.<br><br> \
        <b>Disclaimer</b><br> \
        While the department has attempted to make the information on Eagle Eye as accurate as possible, the information is provided in \
        good faith without any express or implied warranty. The department attempts to ensure that the content is current; however, we do \
        not guarantee its currency or accuracy.<br><br>
        The department does not accept responsibility for any loss or damage occasioned by use of the information contained on Eagle Eye. \
        In particular, the department excludes liability for any lost profit, lost opportunity, lost revenue, lost data, losses resulting \
        from security failure or computer viruses, or any indirect or consequential loss, resulting from your use of Eagle Eye or its \
        content.<br><br>
        For all other types of loss, our total liability to you (including because of our negligence) resulting from your use of \
        Eagle Eye or its content is limited, in total, to $1. Your use of Eagle Eye, and your reliance on its content, is entirely at \
        your own risk.<br><br>
        Eagle Eye may contain links to other websites, over which the department has no control. Those links are provided for your \
        convenience only, and the department is not liable for the content on those websites.<br><br>
        No data transmission over the internet can be guaranteed as totally secure. Every effort will be made to protect such \
        information, but we do not warrant and cannot ensure the security of any information you transmit to us.<br><br> \
        Accordingly, any information you transmit to us is transmitted at your own risk.<br><br>
        Eagle Eye presents a range of data for analytical purpose. There are strict data privacy and governance stipulations that pertain \
        to this data. This data may not be shared, published, communicated in any form without written consent from School \
        Infrastructure NSW.<br><br>
        By downloading any data from Eagle Eye you are confirming your acceptance of the above terms and conditions. User activity is \
        tracked across Eagle Eye and you consent to your download activity being included in periodical reporting of usage.<br><br>
        School Infrastructure NSW recommends that users exercise their own skill and care with respect to their use of this application \
        and the data contained herein and that users carefully evaluate the accuracy, currency, completeness and relevance of the material \
        for their purposes.<br><br>
        This data is not a substitute for independent professional advice and users should obtain any appropriate professional advice \
        relevant to their particular circumstances.`,
      !skipAgree ? 'Agree' : 'OK',
      null,
      true
    );
  }

  showAndUpdateUserDialog() {
    this.showUserTocDialog().subscribe(val => {
      if (val) {
        this.apiCommsService
          .updateUserTocAgreement()
          .pipe(
            catchError(() => {
              // If the agreement fails - logout
              this.authService.logout();
              return new Observable<any>(null);
            })
          )
          .subscribe(data => {
            if (!data) {
              this.authService.logout();
            }
          });
      } else {
        this.authService.logout();
      }
    });
  }
}
