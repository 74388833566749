import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Observable, Subject, Subscription, zip } from 'rxjs';
import {
  CoordinatesResult,
  LocationResult,
  MapReferenceSearchResult,
  MapSearchResult
} from '../../interfaces/map-search-result';
import { MapboxService } from '../mapbox/mapbox.service';
import { MapObject } from '../../interfaces/map-object';
import { first, take } from 'rxjs/operators';
import { ApiCommsService } from '../../../core/services/api-comms/api-comms.service';
import { MapImage } from '../../interfaces/map-image';
import { MapboxSdkService } from '../mapbox-sdk/mapbox-sdk.service';
import { ReferenceSearchGeometry } from '../../interfaces/reference-search-geometry';
import { MapSearchType } from '../../enums/map-search-type';
import { MapSearchItem } from '../../enums/map-search-item';
import { MapReferenceLayersService } from '../map-reference-layers/map-reference-layers.service';
import { TileySearchQuery } from '../../interfaces/tiley-search-query';
import { TileyService } from '../tiley/tiley.service';
import { MapLayerActionType } from '../../enums/map-layer-action-type';
import { MapSearchResultItem } from '../../interfaces/map-search-result-item';
import Layer = mapboxgl.Layer;
import { MapReferenceLayerDefs } from '../../enums/map-reference-layer-defs';

@Injectable({
  providedIn: 'root'
})
export class MapSearchService {
  private currentSearchValue: string = null;
  private searchSubscription: Subscription = null;
  private onMapResultsLoadingSubject: Subject<boolean> = new Subject<boolean>();
  private onMapResultsSubject: Subject<any> = new Subject<any>();
  private onAdvancedSearchVisibleSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private advancedSearchVisible = false;

  private readonly mapSearchMarkerSourceId = 'mapSearchMarkerResults';
  private readonly mapSearchMarkerLayers: Layer[] = [
    {
      id: 'map-search-marker-point',
      type: 'symbol',
      layout: {
        'icon-image': 'map-search-marker',
        'icon-size': 1
      }
    }
  ];
  private readonly mapSearchMarkerImages: MapImage[] = [
    {
      id: 'map-search-marker',
      url: environment.assetsFolder + 'map-search-marker.png'
    }
  ];

  private readonly searchItems: TileySearchQuery[] = [
    {
      id: MapSearchItem.GOV_SCHOOL,
      tag: MapSearchType.GOV_SCHOOL
    },
    {
      id: MapSearchItem.NEW_SCHOOL,
      tag: MapSearchType.NEW_SCHOOL
    },
    {
      id: MapSearchItem.UPGRADE_SCHOOL,
      tag: MapSearchType.UPGRADE_SCHOOL
    },
    {
      id: MapSearchItem.NON_GOV_SCHOOL,
      tag: MapSearchType.NON_GOV_SCHOOL
    },
    {
      id: MapSearchItem.CLUSTER,
      tag: MapSearchType.CLUSTER
    },
    {
      id: MapSearchItem.CATCHMENT,
      tag: MapSearchType.CATCHMENT
    },
    {
      id: MapSearchItem.PRINCIPAL_NETWORKS,
      tag: MapSearchType.PRINCIPAL_NETWORKS
    },
    {
      id: MapSearchItem.OPERATIONAL_DIRECTORATES,
      tag: MapSearchType.OPERATIONAL_DIRECTORATES
    },
    {
      id: MapSearchItem.DPIE_REGIONS,
      tag: MapSearchType.DPIE_REGIONS
    },
    {
      id: MapSearchItem.LGA,
      tag: MapSearchType.LGA
    },
    {
      id: MapSearchItem.STATE_ELECTORATE_DISTRICTS,
      tag: MapSearchType.STATE_ELECTORATE_DISTRICTS
    },
    {
      id: MapSearchItem.DET_REGIONS,
      tag: MapSearchType.DET_REGIONS
    },
    {
      id: MapSearchItem.PLANNED_AREA_PRECINT,
      tag: MapSearchType.PLANNED_AREA_PRECINT
    },
    // the following items are not selected by default
    {
      id: MapSearchItem.CLOSED_GOV_SCHOOL,
      tag: MapSearchType.CLOSED_GOV_SCHOOL
    },
    {
      id: MapSearchItem.SA1_BOUNDARIES,
      tag: MapSearchType.SA1_BOUNDARIES
    },
    {
      id: MapSearchItem.SA2_BOUNDARIES,
      tag: MapSearchType.SA2_BOUNDARIES
    },
    {
      id: MapSearchItem.SA3_BOUNDARIES,
      tag: MapSearchType.SA3_BOUNDARIES
    },
    {
      id: MapSearchItem.SA4_BOUNDARIES,
      tag: MapSearchType.SA4_BOUNDARIES
    },
    {
      id: MapSearchItem.MESH_BLOCKS,
      tag: MapSearchType.MESH_BLOCKS
    },
    {
      id: MapSearchItem.CADASTRE_LOTS,
      tag: MapSearchType.CADASTRE_LOTS
    }
    // these two items are always on and invisible to users
    // {
    //   id: MapSearchItem.LOCATION,
    //   tag: MapSearchType.LOCATION
    // },
    // {
    //   id: MapSearchItem.COORDINATES,
    //   tag: MapSearchType.COORDINATES
    // }
  ];

  // get the right catchment layer
  getCatchmentLayer(result: MapReferenceSearchResult) {
    const catchmentType = result['catchment_type'];
    const isFutureCatchment = result['is_future_catchment'];
    if (isFutureCatchment) {
      switch (catchmentType) {
        case 'PRIMARY':
          return MapReferenceLayerDefs.PRIMARY_CATCHMENT_PROPOSED;
        case 'CENTRAL_PRIMARY':
          return MapReferenceLayerDefs.PRIMARY_CENTRAL_CATCHMENT_PROPOSED;
        case 'CENTRAL_HIGH':
          return MapReferenceLayerDefs.SECONDARY_CENTRAL_CATCHMENT_PROPOSED;
        case 'HIGH_GIRLS':
          return MapReferenceLayerDefs.SECONDARY_GIRLS_CATCHMENT_PROPOSED;
        case 'HIGH_BOYS':
          return MapReferenceLayerDefs.SECONDARY_BOYS_CATCHMENT_PROPOSED;
        case 'HIGH_COED':
          return MapReferenceLayerDefs.SECONDARY_COED_CATCHMENT_PROPOSED;
        case 'INFANTS':
          return MapReferenceLayerDefs.INFANTS_CATCHMENT_PROPOSED;
        default:
          return MapReferenceLayerDefs.SCHOOL_CATCHMENTS_PROPOSED;
      }
    } else {
      switch (catchmentType) {
        case 'PRIMARY':
          return MapReferenceLayerDefs.PRIMARY_CATCHMENT;
        case 'CENTRAL_PRIMARY':
          return MapReferenceLayerDefs.PRIMARY_CENTRAL_CATCHMENT;
        case 'CENTRAL_HIGH':
          return MapReferenceLayerDefs.SECONDARY_CENTRAL_CATCHMENT;
        case 'HIGH_GIRLS':
          return MapReferenceLayerDefs.SECONDARY_GIRLS_CATCHMENT;
        case 'HIGH_BOYS':
          return MapReferenceLayerDefs.SECONDARY_BOYS_CATCHMENT;
        case 'HIGH_COED':
          return MapReferenceLayerDefs.SECONDARY_COED_CATCHMENT;
        case 'INFANTS':
          return MapReferenceLayerDefs.INFANTS_CATCHMENT;
        default:
          return MapReferenceLayerDefs.SCHOOL_CATCHMENTS;
      }
    }
  }

  // hard coded - first 13 items are selectable to use tiley
  private selectedSearchItems: TileySearchQuery[] = this.searchItems.filter(
    (_, index) => index < 13
  );

  constructor(
    private mapboxService: MapboxService,
    private mapboxSdkService: MapboxSdkService,
    private tileyService: TileyService,
    private mapReferenceLayerService: MapReferenceLayersService
  ) {}

  mapboxSearch(searchValue: string): Observable<MapSearchResult[]> {
    return this.mapboxSdkService.geocode(searchValue);
  }

  referenceSearch(searchValue: string): Observable<any> {
    return this.tileyService.performSearch(
      searchValue,
      10,
      this.selectedSearchItems
    );
  }

  getCoordinateResult(searchValue: string): MapSearchResult {
    if (searchValue) {
      const result =
        /(^[-+]?(?:[1-8]?\d(?:\.\d+)?|90(?:\.0+)?))\s*[,\s]\s*([-+]?(?:180(?:\.0+)?|(?:(?:1[0-7]\d)|(?:[1-9]?\d))(?:\.\d+)?))$/.exec(
          searchValue
        );

      if (!result) {
        return null;
      } else {
        return {
          id: null,
          item: MapSearchItem.COORDINATES,
          type: 'coordinates',
          center: [Number(result[2]), Number(result[1])],
          geometry: {
            type: 'Point',
            coordinates: [Number(result[2]), Number(result[1])]
          },
          result: `${result[1]}, ${result[2]}`
        };
      }
    }
  }

  search(searchValue: string): void {
    this.currentSearchValue = searchValue;

    if (searchValue) {
      // Cancel existing subscription if exists
      if (this.searchSubscription) {
        this.searchSubscription.unsubscribe();
        this.searchSubscription = null;
      }

      this.onMapResultsLoadingSubject.next(true);

      const coordinateResult = this.getCoordinateResult(searchValue);

      this.searchSubscription = zip(
        this.mapboxSearch(searchValue),
        this.referenceSearch(searchValue)
      )
        .pipe(take(1))
        .subscribe(([mapboxResults, tileyResults]) => {
          // Combine reference and location results together
          if (mapboxResults || tileyResults || coordinateResult) {
            tileyResults = tileyResults ? tileyResults : [];

            if (mapboxResults && mapboxResults.length > 0) {
              mapboxResults = mapboxResults.map(result => ({
                ...result,
                item: MapSearchItem.LOCATION
              }));
              tileyResults.push({
                item: MapSearchItem.LOCATION,
                results: mapboxResults
              });
            }

            if (coordinateResult) {
              coordinateResult.item = MapSearchItem.COORDINATES;
              tileyResults.push({
                item: MapSearchItem.COORDINATES,
                results: [coordinateResult]
              });
            }

            this.onMapResultsSubject.next(tileyResults);
          } else {
            this.onMapResultsSubject.next(null);
          }
        });
    } else {
      this.onMapResultsSubject.next(null);
    }
  }

  clearSearch(): void {
    this.currentSearchValue = null;
  }

  onSearchLoading(): Observable<boolean> {
    return this.onMapResultsLoadingSubject;
  }

  onSearchComplete(): Observable<MapSearchResultItem[]> {
    return this.onMapResultsSubject;
  }

  selectSearchResult(mapObject: MapObject, result: MapSearchResult) {
    // map behaviour
    this.clearSearchResultLayer(mapObject);

    mapObject.currentSearchResult = result;
    if (result.type === 'location' || result.type === 'coordinates') {
      // If search result is a Mapbox location
      this.showLocationResult(mapObject, result);
    } else {
      // If search result is from the reference datasets
      if (mapObject && mapObject.map) {
        this.showReferenceResult(mapObject, result);
      }
    }
  }

  private showLocationResult(
    mapObject: MapObject,
    result: LocationResult | CoordinatesResult
  ) {
    if (result['boundingBox']) {
      this.mapboxService.fitToBounds(
        mapObject,
        result['boundingBox'],
        result.center
      );
    } else {
      this.mapboxService.flyTo(mapObject, result.center, 16);
    }

    const mapSearchData = [
      {
        geometry: result.geometry
      }
    ];
    this.mapboxService.loadImages(mapObject, this.mapSearchMarkerImages, () => {
      this.mapboxService.updateGeoJsonDataLayer(
        mapObject,
        this.mapSearchMarkerSourceId,
        mapSearchData,
        null,
        this.mapSearchMarkerLayers
      );
    });
  }

  private showReferenceResult(
    mapObject: MapObject,
    result: MapReferenceSearchResult
  ) {
    // Only show the layer (if its off) or change the filter once the map navigation has finished
    if (mapObject && mapObject.map) {
      // catchment layer - figure out which layer to show
      let layerName = result.layer;
      if (layerName === 'school_catchments') {
        layerName = this.getCatchmentLayer(result);
      }
      const layer = this.mapReferenceLayerService.getLayerByLayerName(
        mapObject,
        layerName
      );
      // If a layer was identified - NO filter and add to the map as search action type layer
      if (layer) {
        const isExistingLayer = layer.progammaticEnabled || layer.userEnabled;
        // if it is an existing layer, it will temporarily display all data instead of some filtered data
        if (isExistingLayer) {
          layer.searchEnabled = true;
          this.mapReferenceLayerService.togglePropertyFilter(
            mapObject,
            layer,
            'off'
          );
          this.mapReferenceLayerService.toggleSpatialFilter(
            mapObject,
            layer,
            'off'
          );
          // otherwise just add it to the map
        } else {
          this.mapReferenceLayerService.addVectorLayer(
            mapObject,
            layer,
            MapLayerActionType.SEARCH
          );
        }
        // highlight the feature (only support interactive layers)
        this.mapReferenceLayerService.highlightFeature(
          mapObject,
          layer,
          result.id
        );
        // });
        this.tileyService
          .getBoundingBox(
            layer.datasetName,
            layer.idColumn,
            result.id as string
          )
          .pipe(first())
          .subscribe((response: ReferenceSearchGeometry) => {
            if (response && response.boundingBox) {
              if (response.boundingBox.type === 'Point') {
                const coords = (response.boundingBox as GeoJSON.Point)
                  .coordinates;
                this.mapboxService.flyTo(mapObject, [coords[0], coords[1]], 16);
              } else {
                this.mapboxService.fitToViewFeatures(mapObject.map, [
                  { geometry: response.boundingBox }
                ]); // need to fix this
              }
            }
          });
      }
    }
  }

  clearSearchResultLayer(mapObject: MapObject) {
    if (mapObject.currentSearchResult) {
      if (mapObject.currentSearchResult.type === 'location') {
        this.mapboxService.updateGeoJsonDataLayer(
          mapObject,
          this.mapSearchMarkerSourceId,
          [],
          null,
          this.mapSearchMarkerLayers
        );
      } else {
        if (mapObject.currentSearchResult.type === 'reference') {
          const layer = this.mapReferenceLayerService.getLayerByLayerName(
            mapObject,
            mapObject.currentSearchResult.layer
          );

          if (layer) {
            this.mapReferenceLayerService.clearHighlightFeature(
              mapObject,
              layer
            );
            const isExistingLayer =
              layer.progammaticEnabled || layer.userEnabled;
            if (isExistingLayer) {
              layer.searchEnabled = true;
              this.mapReferenceLayerService.togglePropertyFilter(
                mapObject,
                layer,
                'on'
              );
              this.mapReferenceLayerService.toggleSpatialFilter(
                mapObject,
                layer,
                'on'
              );
            } else {
              this.mapReferenceLayerService.removeVectorLayer(mapObject, layer);
            }
          }
        }
      }
      mapObject.currentSearchResult = null;
    }
  }

  forceClearSearch(mapObject: MapObject) {
    if (mapObject.onForceSearchClear) {
      mapObject.onForceSearchClear.next(true);
    }
  }

  getSearchItems(): TileySearchQuery[] {
    return this.searchItems;
  }

  getSelectedSearchItems(): TileySearchQuery[] {
    return this.selectedSearchItems;
  }

  setSelectedSearchItems(items: TileySearchQuery[]) {
    this.selectedSearchItems = items;

    if (this.currentSearchValue) {
      this.search(this.currentSearchValue);
    }
  }

  openAdvancedSearch(): void {
    this.setAdvancedSearchVisibility(true);
  }

  closeAdvancedSearch(): void {
    this.setAdvancedSearchVisibility(false);
  }

  getAdvancedSearchVisibility(): boolean {
    return this.advancedSearchVisible;
  }

  private setAdvancedSearchVisibility(visible: boolean): void {
    this.advancedSearchVisible = visible;
    this.onAdvancedSearchVisibleSubject.next(visible);
  }

  onAdvancedSearchVisibilityChange(): Observable<boolean> {
    return this.onAdvancedSearchVisibleSubject;
  }
}
