import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { NotificationPollingMessage } from '../../../core/interfaces/notification-polling-message';
import { catchError, startWith, switchMap, tap } from 'rxjs/operators';
import { AdminApiCommsService } from '../../../admin/services/admin-api-comms/admin-api-comms.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationPollingService {
  private notificationDataUpdateSubject: BehaviorSubject<
    NotificationPollingMessage[]
  > = new BehaviorSubject<NotificationPollingMessage[]>(null);
  private notificationListAddUpdateSubject: BehaviorSubject<
    NotificationPollingMessage[]
  > = new BehaviorSubject<NotificationPollingMessage[]>(null);

  private pollingSubscription: Subscription = null;
  private readonly pollingInterval = 60000; // 1 min

  constructor(private adminApiComms: AdminApiCommsService) {}

  getUserNotifications(
    offset?: number
  ): Observable<NotificationPollingMessage[]> {
    return this.adminApiComms.getUserNotifications(offset).pipe(
      tap(data => {
        if (offset) {
          this.notificationListAddUpdateSubject.next(data);
        } else {
          this.notificationDataUpdateSubject.next(data);
        }
      }),
      catchError(error => {
        console.log(error);
        return new Observable<NotificationPollingMessage[]>(null);
      })
    );
  }

  onUpdate(): BehaviorSubject<NotificationPollingMessage[]> {
    return this.notificationDataUpdateSubject;
  }

  onListDataUpdate(): BehaviorSubject<NotificationPollingMessage[]> {
    return this.notificationListAddUpdateSubject;
  }

  startPolling() {
    this.pollingSubscription = interval(this.pollingInterval)
      .pipe(
        startWith(0),
        switchMap(() => this.getUserNotifications())
      )
      .subscribe();
  }

  stopPolling() {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
      this.pollingSubscription = null;
    }
  }
}
