import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {
  constructor(public dialog: MatDialog) {}

  showDialog(
    title: string,
    message: string,
    affirmLabel: string,
    confirmButtonClass?: string,
    wide?: boolean
  ): Observable<any> {
    // Wait until DOM is ready
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: !wide ? '340px' : '640px',
      disableClose: true,
      data: {
        title: title,
        message: message,
        affirmLabel: affirmLabel,
        confirmButtonClass: confirmButtonClass
      }
    });

    return dialogRef.afterClosed();
  }
}
