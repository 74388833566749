import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private routerOutletActive = false;

  constructor() {}

  setRouterOutletActive() {
    this.routerOutletActive = true;
  }

  isRouterOutletActive() {
    return this.routerOutletActive;
  }
}
