export enum MapSearchType {
  LOCATION = 'Location',
  COORDINATES = 'Coordinates',
  LGA = 'Local Government Area',
  GOV_SCHOOL = 'Government School',
  NON_GOV_SCHOOL = 'Non-Government School',
  NEW_SCHOOL = 'New School',
  UPGRADE_SCHOOL = 'Upgrade to Existing School',
  CATCHMENT = 'School Catchment',
  CLUSTER = 'School Community Group (SCG)', //Change #12120
  PLANNED_AREA_PRECINT = 'Planned Area Precinct',
  STATE_ELECTORATE_DISTRICTS = 'State Electorate Districts',
  DET_REGIONS = 'AMU Regions',
  SA1_BOUNDARIES = 'SA1 Boundaries',
  SA2_BOUNDARIES = 'SA2 Boundaries',
  SA3_BOUNDARIES = 'SA3 Boundaries',
  SA4_BOUNDARIES = 'SA4 Boundaries',
  OPERATIONAL_DIRECTORATES = 'Public Schools Directorate', //Change #13107
  PRINCIPAL_NETWORKS = 'Principal Networks',
  DPIE_REGIONS = 'DPHI Regions',
  MESH_BLOCKS = 'Mesh Blocks',
  CADASTRE_LOTS = 'Cadastre (DP/Lots)',
  CLOSED_GOV_SCHOOL = 'Closed/Recess Government School'
}
