import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { MapBasemapService } from '../../services/map-basemap/map-basemap.service';
import { MapObject } from '../../interfaces/map-object';
import { MapboxService } from '../../services/mapbox/mapbox.service';

@Component({
  selector: 'ee-map-date-selector',
  templateUrl: './map-date-selector.component.html',
  styleUrls: ['./map-date-selector.component.scss']
})
export class MapDateSelectorComponent implements OnInit, OnChanges {
  @Input() mapObject: MapObject;

  selectedDate: string = null;
  imageDates: string[] = null;
  prevDisabled = false;
  nextDisabled = false;

  constructor(
    private baseMapService: MapBasemapService,
    private mapBoxService: MapboxService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mapObject && this.mapObject) {
      this.onNearMapChanges();
      this.onImageryDatesReady();
    }
  }

  private onImageryDatesReady() {
    this.mapObject.onNearmapImageryDates.subscribe(res => {
      // This gives the distinct dates list
      this.imageDates = [...new Set(res)];
      this.imageDates.unshift('Latest Image');

      // This is to set the initial selected date which corresponds to the latest image date available
      if (!this.selectedDate) {
        this.selectedDate = this.imageDates[0];
      }

      // This is to set the initial arrow statuses
      this.updateButtonStatuses();

      // If there is no image date matching in the list we will fetch the latest image until the selected date
      if (this.selectedDate && !this.imageDates.includes(this.selectedDate)) {
        this.getTheLatestImageDateFromList(this.selectedDate);
      }
    });
  }

  private onNearMapChanges() {
    this.mapObject.onBasemapChange.subscribe((response: boolean) => {
      if (response) {
        setTimeout(() => {
          if (this.imageDates && this.imageDates.length > 1) {
            // Reset selected date in component and for map object
            this.selectedDate = this.imageDates[0];
            // Get the exact latest image and the date set when base map is changed instead of displaying current date from mapbox service
            this.updateButtonStatuses();
            this.setMapObjectImageDate();
          }
        });
      }
    });
  }

  updateSelectedImageDate() {
    this.setMapObjectImageDate();
    this.updateButtonStatuses();
    this.mapBoxService.setBasemap(
      this.mapObject,
      this.mapObject.currentBasemap,
      this.selectedDate !== 'Latest Image' ? this.selectedDate : null
    );
  }

  getTheLatestImageDateFromList(date) {
    const dates = this.imageDates;
    const finalDateList = dates.filter(item => {
      return this.getMinDate(new Date(item), new Date(date));
    });
    // The below is to get the first date from the list and then fetch the image as and when you scroll
    if (finalDateList.length > 0) {
      this.selectedDate = finalDateList[0];
      this.updateSelectedImageDate();
    } else {
      // This is a scenario where the user drags to a point where there are no images available below certain date
      // Set it to the latest from the list in this case.
      this.selectedDate = this.imageDates[0];
      this.updateSelectedImageDate();
    }
  }

  getMinDate(date1, date2) {
    if (date1 <= date2) {
      return date1;
    }
  }

  previousImageInList() {
    this.selectedDate =
      this.imageDates[this.imageDates.indexOf(this.selectedDate) + 1];
    this.updateSelectedImageDate();
  }

  nextImageInList() {
    this.selectedDate =
      this.imageDates[this.imageDates.indexOf(this.selectedDate) - 1];
    this.updateSelectedImageDate();
  }

  updateButtonStatuses() {
    this.prevDisabled =
      this.imageDates.indexOf(this.selectedDate) + 1 >
      this.imageDates.length - 1;
    this.nextDisabled = this.imageDates.indexOf(this.selectedDate) < 1;
  }

  setMapObjectImageDate() {
    this.mapObject.selectedImageDate =
      this.selectedDate !== 'Latest Image' ? this.selectedDate : null;
  }
}
