import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MapLayerPaletteGroupData } from '../../interfaces/map-layer-palette-group-data';
import { MapLayerPaletteData } from '../../interfaces/map-layer-palette-data';

@Component({
  selector: 'ee-map-palette-dialog',
  templateUrl: './map-palette-dialog.component.html',
  styleUrls: ['./map-palette-dialog.component.scss']
})
export class MapPaletteDialogComponent implements OnInit {
  private colorProperties = [
    'fill-color',
    'circle-color',
    'line-color',
    'text-color',
    'text-halo-color'
  ];
  private smallNumberProperties = ['fill-opacity'];
  private labelNumberProperties = ['text-size'];

  valueChanged = false;

  constructor(
    private dialogRef: MatDialogRef<MapPaletteDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      paletteGroup: MapLayerPaletteGroupData[];
      stylingOverwritten: boolean;
      layerName: string;
    }
  ) {}

  ngOnInit(): void {
    if (this.data && this.data.paletteGroup) {
      this.data.paletteGroup.forEach(g => {
        if (g && g.paletteData) {
          g.paletteData.forEach(i => {
            i.inputName = g.subLayer + '-' + i.type + '-' + i.property;
          });
        }
      });
    }
  }

  close() {
    this.dialogRef.close();
  }

  reset() {
    this.dialogRef.close(-1);
  }

  apply() {
    this.dialogRef.close(this.data.paletteGroup);
  }

  isColorProperty(property: string) {
    return this.colorProperties.includes(property);
  }

  isSmallNumberProperty(property: string) {
    return this.smallNumberProperties.includes(property);
  }

  isLabelNumberProperty(property: string) {
    return this.labelNumberProperties.includes(property);
  }

  valueHasChanged(item: MapLayerPaletteData) {
    this.valueChanged = true;
    item.changed = true;
  }
}
