import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiCommsService } from '../../services/api-comms/api-comms.service';
import { tap } from 'rxjs/operators';
import { OtpConfig } from '../../../shared/interfaces/otp-config';

@Injectable({
  providedIn: 'root'
})
export class OtpConfigResolverService implements Resolve<OtpConfig> {
  private onOtpConfigUpdate: BehaviorSubject<OtpConfig> =
    new BehaviorSubject<OtpConfig>(null);

  constructor(private apiCommsService: ApiCommsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<OtpConfig> {
    return this.apiCommsService.getOtpConfig().pipe(
      tap(config => {
        this.onOtpConfigUpdate.next(config);
      })
    );
  }

  onUpdate(): Observable<OtpConfig> {
    return this.onOtpConfigUpdate;
  }
}
