import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { NotificationPopupType } from '../../enums/notification-popup-type';
import { NotificationMessage } from '../../interfaces/notification-message';
import { NotificationPanelService } from '../../../shared/services/notification-panel/notification-panel.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ee-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss']
})
export class NotificationPanelComponent implements OnInit, OnDestroy {
  notificationMessage: NotificationMessage = {
    type: NotificationPopupType.INFO,
    title: 'Notification'
  };

  visible = false;
  currentTimeout = null;

  private readonly NOTIFICATION_VISIBILITY_TIME = 10000;
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private notificationPanelService: NotificationPanelService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.watchNotification();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  watchNotification() {
    this.notificationPanelService
      .onNotification()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(notificationMessage => {
        this.notificationMessage = notificationMessage;
        this.showNotification();
      });
  }

  private showNotification() {
    if (this.visible) {
      // If existing notification is visible - hide it first before showing the new notification
      this.visible = false;
      setTimeout(() => {
        if (this.currentTimeout) {
          clearTimeout(this.currentTimeout);
          this.currentTimeout = null;
        }
        this.showNotification();
      }, 500);
    } else {
      this.visible = true;
      this.changeDetectorRef.detectChanges();

      // Only auto-hide non-error messages
      if (this.notificationMessage.type !== NotificationPopupType.ERROR) {
        this.currentTimeout = setTimeout(() => {
          this.visible = false;
        }, this.NOTIFICATION_VISIBILITY_TIME);
      }
    }
  }

  close() {
    this.visible = false;
  }
}
