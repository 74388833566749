export enum UserNotificationType {
  VISION_USER_ASSIGNMENT = 'VISION_USER_ASSIGNMENT',
  VISION_REVIEW_ASSIGNMENT = 'VISION_REVIEW_ASSIGNMENT',
  VISION_ENDORSEMENT_ASSIGNMENT = 'VISION_ENDORSEMENT_ASSIGNMENT',
  VISION_STATUS_DRAFT = 'VISION_STATUS_DRAFT',
  VISION_STATUS_FINALISED = 'VISION_STATUS_FINALISED',
  VISION_REVIEW_COMMENT = 'VISION_REVIEW_COMMENT',
  NEWS = 'NEWS',
  SCHOOL_ALERT = 'SCHOOL_ALERT'
}
