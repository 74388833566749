import { Injectable } from '@angular/core';
import { MapReferenceLayerCategory } from '../../../shared/interfaces/map-reference-layer-category';
import { ApiCommsService } from '../../services/api-comms/api-comms.service';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { CurrentUserResolverService } from '../current-user-resolver/current-user-resolver.service';
import { MapReferenceLayer } from 'src/app/shared/interfaces/map-reference-layer';

@Injectable({
  providedIn: 'root'
})
export class MapFavoriteLayerResolverService implements Resolve<number[]> {
  private refLayersDataSubject: BehaviorSubject<number[]> = new BehaviorSubject<
    number[]
  >([]);

  constructor(
    private apiCommsService: ApiCommsService,
    private currentUserResolverService: CurrentUserResolverService
  ) {}

  resolve(): Observable<number[]> {
    return this.currentUserResolverService.getCurrentUserData().pipe(
      switchMap(user => {
        if (user && user.userId) {
          return this.apiCommsService.getFavorites(user.userId).pipe(
            tap(favorites => {
              this.refLayersDataSubject.next(favorites);
            })
          );
        } else {
          this.refLayersDataSubject.next([]);
          return [];
        }
      })
    );
  }

  getSubject(): BehaviorSubject<number[]> {
    return this.refLayersDataSubject;
  }
}
