import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { PlannedArea } from '../../interfaces/planned-area';

@Injectable({
  providedIn: 'root'
})
export class PlannedAreaListService {
  private hoveredPlannedArea: number = null;
  private onHoveredPlannedAreaChangeSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(null);
  private filteredPlannedAreasListSubject: Subject<PlannedArea[]> = new Subject<
    PlannedArea[]
  >();

  constructor() {}

  setHoveredPlannedArea(paId: number) {
    this.hoveredPlannedArea = paId;
    this.onHoveredPlannedAreaChangeSubject.next(this.hoveredPlannedArea);
  }

  clearHoveredPlannedArea() {
    this.hoveredPlannedArea = null;
    this.onHoveredPlannedAreaChangeSubject.next(null);
  }

  filterPlannedAreasList(areas: PlannedArea[]) {
    this.filteredPlannedAreasListSubject.next(areas);
  }

  onHoveredPlannedAreaChange() {
    return this.onHoveredPlannedAreaChangeSubject;
  }

  onFilteredPlannedAreaListChange() {
    return this.filteredPlannedAreasListSubject;
  }
}
