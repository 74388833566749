import { Injectable } from '@angular/core';
import { LngLatBounds } from 'mapbox-gl';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MapObject } from '../../interfaces/map-object';

@Injectable({
  providedIn: 'root'
})
export class MapBasemapService {
  constructor(private http: HttpClient) {}

  getBasemapImageryDate(
    provider: string,
    bounds: LngLatBounds,
    mapObject?: MapObject
  ): Observable<Date> {
    switch (provider) {
      case 'Nearmap':
        return this.getNearmapBasemapImageryDate(bounds, mapObject);
      case 'SIX Maps':
        return this.getSixMapsBasemapImageryDate(bounds);
    }
  }

  private getNearmapBasemapImageryDate(
    bounds: LngLatBounds,
    mapObject?: MapObject
  ): Observable<Date> {
    const url =
      `https://api.nearmap.com/coverage/v2/poly/` +
      `${bounds.getNorthWest().lng},${bounds.getNorthWest().lat},${bounds.getNorthEast().lng},${bounds.getNorthEast().lat},` +
      `${bounds.getSouthEast().lng},${bounds.getSouthEast().lat},${bounds.getSouthWest().lng},${bounds.getSouthWest().lat},` +
      `${bounds.getNorthWest().lng},${bounds.getNorthWest().lat}` +
      `?apiKey=${environment.nearmapsApiKey}&limit=10000&fields=id,captureDate`;

    return this.http.get<any>(url).pipe(
      map(response => {
        let imageryDates = [];
        let selectedDate: Date = null;

        if (response && response.surveys && response.surveys.length > 0) {
          imageryDates = response.surveys.map(image => {
            return image.captureDate;
          });

          /* When the user has already selected a date we have to respect the selection and
            hence we will return the selectedDate from mapObject else return the last photo time
             */
          if (mapObject.selectedImageDate) {
            selectedDate = new Date(mapObject.selectedImageDate);
          } else {
            selectedDate = new Date(imageryDates[0]);
          }
        }

        mapObject.onNearmapImageryDates.next(imageryDates);

        return selectedDate;
      }),
      catchError(() => {
        return new Observable<Date>(null);
      })
    );
  }

  private getSixMapsBasemapImageryDate(bounds: LngLatBounds): Observable<Date> {
    const url =
      `https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Imagery/MapServer/0/query?geometry=` +
      `${bounds.getNorthWest().lng},${bounds.getNorthWest().lat},${bounds.getNorthEast().lng},${bounds.getNorthEast().lat},` +
      `${bounds.getSouthEast().lng},${bounds.getSouthEast().lat},${bounds.getSouthWest().lng},${bounds.getSouthWest().lat},` +
      `${bounds.getNorthWest().lng},${bounds.getNorthWest().lat}` +
      `&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&outFields=BlockStartDate+&returnGeometry=false` +
      `&returnTrueCurves=false&outSR=4326&returnIdsOnly=false&returnCountOnly=false&orderByFields=BlockStartDate+DESC&returnZ=false` +
      `&returnM=false&returnDistinctValues=false&resultRecordCount=20&returnExtentsOnly=false&f=pjson`;

    return this.http.get<any>(url).pipe(
      map(response => {
        if (response && response.features && response.features.length > 0) {
          return new Date(response.features[0].attributes['BlockStartDate']);
        } else {
          return null;
        }
      })
    );
  }
}
