import { DecimalPipe } from '@angular/common';

export function parseLength(len: number): string {
  const originalLength = len;

  if (len < 1) {
    len = len * 1000;
  }

  let displayLength = new DecimalPipe('en-US').transform(len);

  if (originalLength < 1) {
    displayLength += ' m';
  } else {
    displayLength += ' km';
  }

  return displayLength;
}

// GOOD
// Area in metres squared
export function parseArea(pArea: number): string {
  const originalArea = pArea;

  if (pArea >= 10000 && pArea < 1000000) {
    pArea = pArea / 10000;
  } else if (pArea >= 1000000) {
    pArea = pArea / 1000000;
  }

  let displayArea = new DecimalPipe('en-US').transform(pArea);

  if (originalArea < 10000) {
    displayArea += ' m²';
  } else if (originalArea < 1000000) {
    displayArea += ' ha';
  } else {
    displayArea += ' km²';
  }

  return displayArea;
}

// GOOD
export function parseDuration(duration: number): string {
  let result = '';

  const hours = Math.floor(duration / 3600);
  const mins = Math.floor((duration % 3600) / 60);
  const seconds = Math.round((duration % 3600) % 60);

  if (hours > 0) {
    result = hours + ' h';
  }

  if (mins > 0) {
    if (result !== '') {
      result += ' ';
    }
    result += mins + ' min';
  }

  if (seconds > 0) {
    if (result !== '') {
      result += ' ';
    }
    result += seconds + ' s';
  }

  return result;
}
