<div class="bg-container"></div>
<div class="content">
  <div class="logo-container">
    <a href="../..">
      <img src="../../../../assets/images/eagle-eye-logo.svg" />
      <div class="title">EAGLE EYE</div>
    </a>
  </div>
  <div class="help-container">
    <a href="mailto:sci@det.nsw.edu.au?subject=%Email%unsubscribe%20enquiry">
      Need help?
    </a>
  </div>
  <div class="main-content">
    <h2>You're Unsubscribed!</h2>
    <p>
      We are sorry to see you go.
      <br />
      <br />
      <img [src]="gifUrl" *ngIf="gifUrl" class="gif" />
      <br />
      <br />
      We will no longer send you email notifications, but you may occasionally
      receive important emails from us.
    </p>
  </div>
</div>
