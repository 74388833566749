<div>
  <button mat-icon-button class="close-x-button" (click)="close(false)">
    <mat-icon fontSet="far" fontIcon="fa-times"></mat-icon>
  </button>

  <h1 mat-dialog-title>{{ data.title }}</h1>

  <div
    mat-dialog-content
    [innerHTML]="data.message"
    [perfectScrollbar]="{ suppressScrollX: true, suppressScrollY: true }"
  ></div>

  <div mat-dialog-actions fxLayoutAlign="end end">
    <button mat-raised-button (click)="close(false)">Cancel</button>
    <button
      mat-raised-button
      class="key-action"
      mat-dialog-close="true"
      [ngClass]="data.confirmButtonClass"
    >
      {{ data.affirmLabel }}
    </button>
  </div>
</div>
