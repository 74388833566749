<div>
  <div class="logo-container">
    <img src="../assets/images/eagle-eye-logo.svg" />
    <div class="title">EAGLE EYE</div>
  </div>

  <button mat-icon-button class="close-x-button" (click)="close()">
    <mat-icon fontSet="far" fontIcon="fa-times"></mat-icon>
  </button>
  <h1 mat-dialog-title [ngStyle]="{ color: data?.category?.color }">
    <mat-icon
      fontSet="fas"
      [fontIcon]="data?.category?.icon"
      *ngIf="data?.category?.icon"
    ></mat-icon>
    {{ data?.title ? (data.title | titlecase) : 'News' }}
  </h1>

  <div mat-dialog-content [perfectScrollbar]="{ suppressScrollX: true }">
    <div
      [innerHTML]="data?.detail | safeHtml"
      class="notification-content"
    ></div>
  </div>

  <div mat-dialog-actions fxLayoutAlign="end end">
    <button mat-raised-button class="primary" (click)="close()">Close</button>
  </div>
</div>
