import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { MapControlsService } from '../../services/map-controls/map-controls.service';
import { MapObject } from '../../interfaces/map-object';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'ee-map-controls',
  templateUrl: './map-controls.component.html',
  styleUrls: ['./map-controls.component.scss']
})
export class MapControlsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() mapObject: MapObject;
  mapRotation = 0;
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  private initialised = false;

  constructor(private mapControlsService: MapControlsService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mapObject && this.mapObject && !this.initialised) {
      this.initialised = true;
      this.watchMapRotation();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private watchMapRotation() {
    this.mapObject.onRotateChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(rotation => {
        this.mapRotation = -1 * rotation;
      });
  }

  zoomIn() {
    this.mapControlsService.zoomIn(this.mapObject);
  }

  zoomOut() {
    this.mapControlsService.zoomOut(this.mapObject);
  }

  resetMapView() {
    this.mapControlsService.resetMapView(this.mapObject);
  }
}
