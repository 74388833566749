import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutes } from '../../enums/app-routes';
import { Router } from '@angular/router';
import { UserRoles } from '../../enums/user-roles';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private requestedUrl: string = null;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router,
    @Inject(DOCUMENT) private document: any,
    public dialog: MatDialog
  ) {}

  getCsrfToken(): string {
    return this.cookieService.get('XSRF-TOKEN');
  }

  // Checks whether an authentication token exists and whether it is valid
  checkAuthenticated(requestedUrl?: string): Observable<boolean> {
    const url =
      environment.backendServer.mainPath + environment.backendServer.loginCheck;

    // Temporarily store requested URL
    if (requestedUrl) {
      this.requestedUrl = requestedUrl;
    }

    return this.http.post<any>(url, {}).pipe(
      map(response => {
        return response && response.valid;
      })
    );
  }

  redirectToLoginPage() {
    this.router.navigateByUrl(
      this.router.createUrlTree([AppRoutes.LOGIN], {
        queryParams: {
          url: this.requestedUrl
        },
        queryParamsHandling: 'merge'
      })
    );
  }

  redirectToRestrictedPage() {
    this.router.navigate([AppRoutes.RESTRICTED_MODULE]);
  }

  redirectToSSOLoginPage() {
    const loginUrl =
      environment.backendServer.mainPath + environment.backendServer.login;
    const requestedUrl =
      environment.frontEndPath +
      `${this.requestedUrl ? `/#${this.requestedUrl}` : ''}`;
    const redirectUrl =
      loginUrl +
      '?redirectPath=' +
      encodeURIComponent(requestedUrl) +
      '&sw=' +
      window.screen.width +
      '&sh=' +
      window.screen.height;

    this.document.location.href = redirectUrl;
  }

  logout() {
    this.dialog.closeAll();
    const url =
      environment.backendServer.mainPath + environment.backendServer.logout;
    this.http.get<any>(url).subscribe(() => {
      this.router.navigate([AppRoutes.LOGIN]);
    });
  }

  checkUserRole(userRole: UserRoles): boolean {
    const permissions = JSON.parse(this.cookieService.get('permissions'));
    return permissions[userRole];
  }

  getUserFullName(): string {
    const givenName = this.cookieService.get('givenName');
    const lastName = this.cookieService.get('lastName');
    return `${givenName} ${lastName}`;
  }

  getUserFirstName(): string {
    return this.cookieService.get('givenName');
  }

  getUserLastName(): string {
    return this.cookieService.get('lastName');
  }

  getUsername(): string {
    return this.cookieService.get('username');
  }

  getUserEmail(): string {
    return this.cookieService.get('username') + '@det.nsw.edu.au';
  }
}
