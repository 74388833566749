import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { TileySearchQuery } from '../../interfaces/tiley-search-query';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Geometry } from 'geojson';
import { Observable } from 'rxjs';
import { GeoreferenceResults } from '../../interfaces/georeference-results';
import { ApiResponse } from '../../../core/interfaces/api-response';
import {
  BoundingBoxPayload,
  ReferenceSearchGeometry
} from '../../interfaces/reference-search-geometry';
import { TileyTilesetRequest } from '../../interfaces/tiley-tileset-request';
import { TileyTilesetResponse } from '../../interfaces/tiley-tileset-response';
import { MapSearchResultItem } from '../../interfaces/map-search-result-item';

@Injectable({
  providedIn: 'root'
})
export class TileyService {
  constructor(private http: HttpClient) {}

  // a wrapper to getTileyUrl
  getTileyUrl(endpoint: string, queryParameters?: Record<string, string>) {
    const baseUrl = environment.tileyNextServer;
    // the token can be overridden by the queryParameters
    const searchParams = new URLSearchParams({
      token: environment.tileyNextApiKey,
      ...queryParameters
    }).toString();
    return `${baseUrl}${endpoint}?${searchParams}`;
  }

  // the baseLayerId can be number, string, number[] or string[]
  getFeatureIdsByLayer(
    baseData: string,
    baseLayerColumn: string,
    baseLayerId: string | number | string[] | number[],
    queryData: string,
    queryColumn?: string
  ) {
    if (Array.isArray(baseLayerId)) {
      baseLayerId = baseLayerId.join(',');
    } else {
      baseLayerId = `${baseLayerId}`;
    }
    let payload = {
      base_data: baseData,
      base_column: baseLayerColumn,
      base_value: baseLayerId,
      query_column: queryColumn || 'tile_id',
      query_data: queryData
    };
    const url = this.getTileyUrl('/api/getFeatureIdsByLayer');
    return this.http
      .post<ApiResponse<{ column: string; values: any[] }>>(url, payload)
      .pipe(
        map(result => {
          return result && result.success && result.data ? result.data : null;
        })
      );
  }

  getFeatureIdsByGeometry(
    geometry: Geometry,
    queryLayer: string,
    queryColumn?: string
  ) {
    let payload = {
      query_column: queryColumn || 'tile_id',
      query_data: queryLayer,
      geometry: geometry
    };
    const url = this.getTileyUrl('/api/getFeatureIdsByGeometry');
    return this.http
      .post<ApiResponse<{ column: string; values: any[] }>>(url, payload)
      .pipe(
        map(result => {
          console.log('result', result);
          return result && result.success && result.data ? result.data : null;
        })
      );
  }

  getFeatureGeometry(
    dataset: string,
    column: string,
    id: string | number | string[] | number[],
    extraColumns?: Array<{ [key: string]: string }>
  ) {
    let payload = {
      column,
      dataset,
      id
    };
    if (extraColumns) {
      payload['extra_columns'] = extraColumns;
    }
    const url = this.getTileyUrl('/api/getFeatureGeometry');
    return this.http
      .post<ApiResponse<{ column: string; values: any[] }>>(url, payload)
      .pipe(
        map(result => {
          if (result && result.success && result.data) {
            const geojson = result.data[0].result;
            return geojson;
          }
        })
      );
  }

  performSearch(
    keyword: string,
    limit: number,
    items: TileySearchQuery[]
  ): Observable<MapSearchResultItem[]> {
    // if there are no items, return an empty observable
    if (items.length === 0) {
      return new Observable<MapSearchResultItem[]>(observer => {
        observer.next([]);
        observer.complete();
      });
    }
    const itemNames = items.map(item => item.id).join(',');
    const searchQuery = {
      token: environment.tileyNextApiKey,
      keyword: keyword,
      limit: `${limit}`,
      items: itemNames
    };
    const url = this.getTileyUrl('/api/search', searchQuery);

    return this.http.get<ApiResponse<MapSearchResultItem[]>>(url).pipe(
      map(result => {
        return result && result.success && result.data ? result.data : null;
      })
    );
  }

  getBoundingBox(
    dataset: string,
    idColumn: string,
    id: string | number
  ): Observable<ReferenceSearchGeometry> {
    const url = this.getTileyUrl(`/api/bbox/${dataset}/${id}`, { idColumn });
    return this.http.get<ApiResponse<ReferenceSearchGeometry>>(url).pipe(
      map(result => {
        return result && result.success && result.data ? result.data : null;
      })
    );
  }

  getMultiBoundingBox(
    payload: BoundingBoxPayload | BoundingBoxPayload[]
  ): Observable<ReferenceSearchGeometry> {
    const url = this.getTileyUrl(`/api/bbox`);
    return this.http
      .post<ApiResponse<ReferenceSearchGeometry>>(url, payload)
      .pipe(
        map(result => {
          console.log('result', result);
          return result && result.success && result.data ? result.data : null;
        })
      );
  }

  doGeoreference(
    geometry: Geometry,
    exclude: string[] = []
  ): Observable<GeoreferenceResults> {
    // const geom = JSON.stringify(geometry);
    const url = this.getTileyUrl('/api/doGeoreference');

    return this.http
      .post<any>(url, {
        geometry,
        ...(exclude.length > 0 && { exclude })
      })
      .pipe(
        map(result => {
          return result && result.success && result.data ? result.data : null;
        })
      );
  }

  query(query: string): Observable<any> {
    const url = this.getTileyUrl('/api/query');

    return this.http.post<any>(url, {
      q: query
    });
  }
}
