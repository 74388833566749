import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MapBasemapName } from '../../enums/map-basemap-name';
import { MapToolbarOptions } from '../../interfaces/map-toolbar-options';
import { MapObject } from '../../interfaces/map-object';
import { MapDataImportMappingService } from '../../services/map-data-import-mapping/map-data-import-mapping.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Feature } from 'geojson';

@Component({
  selector: 'ee-map-data-import-selection-dialog',
  templateUrl: './map-data-import-selection-dialog.component.html',
  styleUrls: ['./map-data-import-selection-dialog.component.scss']
})
export class MapDataImportSelectionDialogComponent
  implements OnInit, OnDestroy
{
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  selectedFeature: Feature = null;

  mapReady = false;
  MapBasemapNameEnum = MapBasemapName;

  mapResizeTimeout = null;

  toolbarOptions: MapToolbarOptions = {
    hubHome: false,
    gapAdd: false,
    gapAnnotation: false,
    inspectorTool: false,
    lineMeasureTool: false,
    areaMeasureTool: false,
    radiusMeasureTool: false,
    travelTools: false,
    threeDMode: false,
    streetView: false,
    mapExport: false,
    visionCancelButton: false,
    referenceLayers: false,
    basemaps: false
  };

  constructor(
    public dialogRef: MatDialogRef<MapDataImportSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mapDataImportMappingService: MapDataImportMappingService
  ) {}

  ngOnInit(): void {
    this.watchSelectedFeatureChange();
  }

  ngOnDestroy(): void {
    this.mapDataImportMappingService.clearMapData();
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    if (this.mapResizeTimeout) {
      clearTimeout(this.mapResizeTimeout);
    }
  }

  private watchSelectedFeatureChange() {
    this.mapDataImportMappingService
      .onSelectedFeatureChange()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(feature => {
        this.selectedFeature = feature;
      });
  }

  close() {
    this.dialogRef.close();
  }

  onMapCreated(mapObject: MapObject) {
    this.mapReady = true;
    this.mapDataImportMappingService.setMapObject(mapObject);
    this.mapDataImportMappingService.setMapData(this.data);
    this.mapResizeTimeout = setTimeout(() => {
      if (mapObject && mapObject.map) {
        mapObject.map.resize();
      }
    });
  }

  import() {
    this.dialogRef.close(this.selectedFeature);
  }
}
