import {
  ApplicationRef,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { TooltipComponent } from '../../components/tooltip/tooltip.component';
import { ComponentRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TooltipDirection } from '../../enums/tooltip-direction';

@Directive({
  selector: '[eeTooltip]'
})
export class TooltipDirective implements OnInit, OnChanges {
  @Input() tooltipText = '';
  @Input() tooltipClass = null;
  @Input() tooltipDirection = TooltipDirection.LEFT;
  @Input() tooltipEnabled = true;
  @Input() fixedOnClick = false;
  @Input() dontShowOnClick = false;
  private componentRef: ComponentRef<TooltipComponent>;
  private touchStart = false;

  constructor(
    private el: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.componentRef = this.componentFactoryResolver
        .resolveComponentFactory(TooltipComponent)
        .create(this.injector);

      this.componentRef.instance.body = this.tooltipText;

      if (this.tooltipClass) {
        this.componentRef.instance.tooltipClass = `${this.tooltipClass} ${this.tooltipDirection === TooltipDirection.LEFT ? 'left' : 'right'}`;
      }

      if (this.tooltipDirection) {
        this.componentRef.instance.tooltipDirection = this.tooltipDirection;
      }

      this.appRef.attachView(this.componentRef.hostView);

      this.document.body.appendChild(
        this.componentRef.instance.el.nativeElement
      );
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.tooltipText && this.componentRef) {
        setTimeout(() => (this.componentRef.instance.body = this.tooltipText));
      }
      if (changes.tooltipClass && this.componentRef) {
        setTimeout(
          () => (this.componentRef.instance.tooltipClass = this.tooltipClass)
        );
      }
      // if (changes.hasOwnProperty('tooltipEnabled') && !changes.tooltipEnabled) {
      //   console.log('tooltip has been disabled');
      // }
    }
  }

  updatePosition() {
    const boundingBox = this.el.nativeElement.getBoundingClientRect();

    const elementHeight = this.el.nativeElement.clientHeight;

    const left =
      this.tooltipDirection === TooltipDirection.LEFT
        ? boundingBox.left
        : boundingBox.right;

    if (this.componentRef && this.componentRef.instance) {
      this.componentRef.instance.setPosition(
        boundingBox.top,
        left,
        this.tooltipDirection,
        elementHeight
      );
    }
  }

  private getVisibility() {
    return this.componentRef && this.componentRef.instance.getVisibility();
  }

  @HostListener('click', ['$event']) onClick($event) {
    if (this.fixedOnClick) {
      $event.preventDefault();
      $event.stopPropagation();
      $event.cancelBubble = true;
      this.componentRef.instance.toggleFixed();
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    if (this.fixedOnClick && this.getVisibility()) {
      const tooltipElement = this.componentRef.instance.el.nativeElement;

      if (!tooltipElement.contains(event.target)) {
        this.componentRef.instance.visible = false;
      }
    }
  }

  @HostListener('touchstart') onTouchStart() {
    console.log('touch start');
    this.touchStart = true;
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.touchStart) {
      this.showTooltip();
    } else {
      this.touchStart = false;
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (
      !this.fixedOnClick ||
      (this.getVisibility() && !this.componentRef.instance.fixed)
    ) {
      this.hideTooltip();
    }
  }

  @HostListener('mousedown') onMouseDown() {
    if (!this.getVisibility() && !this.dontShowOnClick) {
      this.showTooltip();
    } else {
      this.hideTooltip();
    }
  }

  private showTooltip() {
    if (this.tooltipEnabled) {
      this.updatePosition();
      if (this.componentRef) {
        this.componentRef.instance.show();
      }
    }
  }

  private hideTooltip() {
    if (this.componentRef) {
      this.componentRef.instance.hide();
    }
  }
}
