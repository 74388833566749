import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { MapSearchService } from '../../services/map-search/map-search.service';
import { MapObject } from '../../interfaces/map-object';
import { MapboxService } from '../../services/mapbox/mapbox.service';
import { StreetViewObject } from '../../interfaces/street-view-object';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MapReferenceLayersService } from '../../services/map-reference-layers/map-reference-layers.service';
import { MapToolbarOptions } from '../../interfaces/map-toolbar-options';
import { MapTooltipService } from '../../services/map-tooltip/map-tooltip.service';
import { MapBasemapName } from '../../enums/map-basemap-name';

@Component({
  selector: 'ee-map-group',
  templateUrl: './map-group.component.html',
  styleUrls: ['./map-group.component.scss']
})
export class MapGroupComponent implements OnInit, OnDestroy {
  @Input() mapId: string = null;
  @Input() basemapName: MapBasemapName = null;
  @Input() toolbarOptions: MapToolbarOptions = null;
  @Input() scrollZoomEnabled = true;
  @Input() mapSearchBarEnabled = true;
  @Input() mapToolbarEnabled = true;
  @Input() mapControlsEnabled = true;
  @Input() mapLegendEnabled = true;
  @Input() mapLegendTooltipEnabled = true;
  @Input() mapDateSelectorEnabled = true;
  @Input() extraClass = '';
  @Output() createCallback = new EventEmitter();
  @Output() toolClick?: EventEmitter<string> = new EventEmitter();
  mapObject: MapObject = null;
  streetViewObject: StreetViewObject = null;
  streetViewEnabled = false;
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private mapboxService: MapboxService,
    private mapSearchService: MapSearchService,
    private mapTooltipService: MapTooltipService,
    private mapReferenceLayerService: MapReferenceLayersService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    this.mapObject = null;
  }

  onMapCreate(mapObject: MapObject) {
    this.mapObject = mapObject;

    if (!this.scrollZoomEnabled) {
      this.mapObject.map.scrollZoom.disable();
    }

    this.mapReferenceLayerService.assignReferenceLayersToMapObject(mapObject);
    this.mapTooltipService.createTooltip(mapObject);
    this.createCallback.emit(mapObject);
  }

  onStreetViewCreated(streetViewObject: StreetViewObject) {
    setTimeout(() => {
      this.streetViewObject = streetViewObject;

      if (this.streetViewObject.onEnabled) {
        this.streetViewObject.onEnabled
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(enabled => {
            this.streetViewEnabled = enabled;
          });
      }
    });
  }

  onToolClickEvent(tool: string) {
    this.toolClick.emit(tool);
  }
}
