<div class="container">
  <div class="main-container" fxLayout="column">
    <!-- Header Section -->
    <div class="heading-container" fxLayout="column">
      <div class="heading" fxLayout="column">
        <div class="home-button">
          <button mat-button class="back-button">
            <mat-icon
              class="icon"
              fontSet="fas"
              fontIcon="fa-chevron-left"
            ></mat-icon>
            BACK
          </button>
        </div>
        <h3>School Master List</h3>
      </div>
    </div>

    <!-- Filter Panel -->
    <div class="filter-panel" fxLayout="column" fxLayoutGap="16px">
      <!-- Top Panel: Select Columns, Clear Filters, and Download -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
      >
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
          <!-- Select Columns -->
          <mat-form-field class="dropdown-field" floatLabel="never">
            <mat-label>{{ getColumnsPlaceholder() }}</mat-label>
            <mat-select
              #columnSelect
              panelClass="custom-panel"
              [(value)]="selectedDisplayedColumns"
              multiple
              (selectionChange)="updateDisplayedColumns()"
            >
              <div class="all-columns-container" fxLayout="row">
                <mat-checkbox
                  [checked]="isAllColumnsSelected()"
                  (change)="toggleAllColumns($event.checked)"
                >
                  All Columns
                </mat-checkbox>
                <input
                  type="text"
                  class="filter-box"
                  placeholder="Search Columns..."
                  [(ngModel)]="filterText"
                />
                <button
                  mat-icon-button
                  *ngIf="filterText"
                  (click)="filterText = ''"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <mat-option class="custom-option">
                <div class="categories-container">
                  <table class="categories-table">
                    <thead>
                      <tr>
                        <th
                          *ngFor="let category of categorizedColumns"
                          [hidden]="
                            getFilteredColumns(category.columns).length === 0
                          "
                        >
                          {{ category.category }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let rowIndex of getMaxRowIndex()">
                        <td
                          *ngFor="let category of categorizedColumns"
                          [hidden]="
                            getFilteredColumns(category.columns).length === 0
                          "
                        >
                          <div
                            *ngIf="
                              getFilteredColumns(category.columns)[rowIndex]
                            "
                          >
                            <mat-checkbox
                              [checked]="
                                displayedColumns.includes(
                                  getFilteredColumns(category.columns)[rowIndex]
                                    .key
                                )
                              "
                              (change)="
                                onColumnToggle(
                                  getFilteredColumns(category.columns)[rowIndex]
                                    .key,
                                  $event.checked
                                )
                              "
                            >
                              {{
                                getFilteredColumns(category.columns)[rowIndex]
                                  .displayName
                              }}
                            </mat-checkbox>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Clear Filters Button -->
          <button
            mat-raised-button
            class="key-action green"
            (click)="resetFilters()"
            [disabled]="
              filterState.length === 0 &&
              displayedColumns.length === allColumns.length
            "
          >
            <!-- [disabled]="filterState.length === 0"> -->
            Reset Filters
          </button>
        </div>

        <!-- Download Button class="download-button"> -->
        <button
          mat-raised-button
          class="key-action"
          (click)="downloadFilteredList()"
          [disabled]="filterState.length === 0"
        >
          <mat-icon fontSet="fas" fontIcon="fa-download"></mat-icon>
          Download
        </button>
      </div>

      <!-- Filters Section: Column, Operator, Values -->
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <!-- Column Selector -->
        <mat-form-field class="dropdown-field" floatLabel="never">
          <mat-label>Column</mat-label>
          <mat-select
            panelClass="my-panel"
            [(value)]="selectedColumn"
            (selectionChange)="onColumnSelect(selectedColumn)"
          >
            <div class="filter-box-container">
              <input
                type="text"
                placeholder="Search Columns..."
                [(ngModel)]="columnFilterText"
              />
            </div>
            <mat-option
              *ngFor="let column of getFilterColumns(allColumns)"
              [value]="column.key"
            >
              {{ column.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Operator Selector -->
        <mat-form-field class="dropdown-field" floatLabel="never">
          <mat-label>Operator</mat-label>
          <mat-select
            [(value)]="selectedOperator"
            (selectionChange)="onOperatorChange()"
          >
            <mat-option *ngFor="let operator of operators" [value]="operator">
              {{ operator }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Values Selector -->
        <ng-container *ngIf="selectedOperator === 'Between'; else normalValues">
          <div class="between-container">
            <mat-form-field class="between-field">
              <input
                matInput
                type="number"
                [(ngModel)]="betweenValues[0]"
                placeholder="Min value"
                class="between-input"
              />
            </mat-form-field>
            <span class="between-separator">-</span>
            <mat-form-field class="between-field">
              <input
                matInput
                type="number"
                [(ngModel)]="betweenValues[1]"
                placeholder="Max value"
                class="between-input"
              />
            </mat-form-field>
          </div>
        </ng-container>

        <!-- Normal dropdown for other operators -->
        <ng-template #normalValues>
          <mat-form-field class="dropdown-field" floatLabel="never">
            <mat-label>Values</mat-label>
            <mat-select [(value)]="selectedValues" multiple>
              <div class="filter-box-container">
                <input
                  type="text"
                  placeholder="Search Values..."
                  [(ngModel)]="valueFilterText"
                />
              </div>
              <mat-option
                *ngFor="let value of getFilteredValues(columnValues)"
                [value]="value"
              >
                {{ value === '(Blanks)' ? '(Blanks)' : value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-template>

        <!-- Apply and Reset Filter Buttons -->
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
          <button mat-raised-button class="key-action" (click)="applyFilter()">
            Apply Filter
          </button>
          <button
            mat-raised-button
            class="key-action green"
            (click)="resetFilter()"
          >
            Clear
          </button>
        </div>
      </div>

      <!-- Filter Chips Section -->
      <div class="filter-criteria-container" *ngIf="filterState.length > 0">
        <mat-chip-list>
          <mat-chip
            *ngFor="let filter of filterState"
            [removable]="true"
            (removed)="removeFilter(filter)"
          >
            {{ getDisplayName(filter.column) }} {{ filter.operator }}
            {{ filter.values.join(', ') }}
            <mat-icon matChipRemove>close</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>

    <!-- Table Section -->
    <div
      class="table-container school-master-list-table"
      *ngIf="allColumns && allColumns.length > 0"
    >
      <!-- <cdk-virtual-scroll-viewport itemSize="50" class="table-scroll-container"> -->
      <div class="table-scroll-container">
        <table
          class="default"
          mat-table
          [dataSource]="dataSource"
          matSort
          (matSortChange)="updateSort($event)"
        >
          <ng-container
            *ngFor="let column of allColumns"
            [matColumnDef]="column.key"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              [mat-sort-header]="column.key"
              class="header-cell"
            >
              {{ column.displayName }}
              <!-- <button mat-icon-button class="filter-button" (click)="onColumnSelect(column.key); $event.stopPropagation()"> -->
              <button
                *ngIf="column.key !== 'description'"
                mat-icon-button
                class="filter-button"
                (click)="onColumnSelect(column.key); $event.stopPropagation()"
              >
                <mat-icon fontSet="fas" fontIcon="fa-filter"></mat-icon>
              </button>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [ngClass]="{ 'description-column': column.key === 'description' }"
            >
              {{ element[column.key] }}
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <!-- </cdk-virtual-scroll-viewport> -->
      <mat-paginator
        [length]="totalItems"
        [pageSize]="itemsPerPage"
        [pageSizeOptions]="[25, 50, 100]"
        (page)="changePage($event)"
      ></mat-paginator>
    </div>
  </div>
</div>
