<div class="container" [ngClass]="{ hidden: !visible }">
  <div class="content">
    <div class="heading">
      <h3>Advanced Search</h3>
      <button mat-icon-button class="close-x-button" (click)="close()">
        <mat-icon fontSet="far" fontIcon="fa-times"></mat-icon>
      </button>
    </div>

    <mat-selection-list
      [(ngModel)]="selectedOptions"
      (selectionChange)="onSelectionChange()"
      class="advanced-search"
      [perfectScrollbar]
      #searchItemsList
    >
      <mat-list-option *ngFor="let item of searchItems" [value]="item">
        {{ item.tag }}
      </mat-list-option>
    </mat-selection-list>

    <div class="buttons-container">
      <button
        mat-button
        (click)="selectAll()"
        class="simple-blue-button"
        [disabled]="searchItems.length === selectedOptions.length"
      >
        Select All
      </button>
      <button
        mat-button
        (click)="clearAll()"
        class="simple-blue-button"
        [disabled]="selectedOptions.length === 0"
      >
        Clear All
      </button>
    </div>
  </div>
</div>
