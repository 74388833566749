import packageJson from './../../package.json';

export const environment = {
  appVersion: packageJson.version,
  production: true,
  environment: 'PROD',
  preview: false,
  mapboxAccessToken:
    'pk.eyJ1Ijoic2luc3ctc2NpIiwiYSI6ImNqbG5oeXgxYjFqOTkza3BhaWFlZjloMXoifQ.IRI-P0nGufmlZs-02W-ZLQ',
  nearmapsApiKey: 'ODJjOWYzYmQtZGY3NS00NTY4LTllYmMtZTA5NjU4MzlhODM1',
  googleApiKey: 'AIzaSyCUE3uZGc9UXrPSN69GEnsmKKKmUChk5m4',
  giphyApiKey: 'A149xhCis6XdFOktzTsGDABf0m0ybZSO',
  frontEndPath: 'https://eagleeye.schoolinfrastructure.nsw.gov.au',
  backendServer: {
    mainPath: 'https://eagleeye.schoolinfrastructure.nsw.gov.au/api',
    login: '/login',
    logout: '/login/logout',
    loginCheck: '/login/check',
    eagleEye: '/eagle-eye',
    plannedAreas: '/gap',
    hub: '/hub',
    admin: '/admin',
    vision: '/vision',
    storm: '/storm',
    downloads: '/downloads',
    notifications: '/notifications',
    imageUpload: '/image/upload',
    pdfExportUrl: '/pdf/export',
    maintenance: '/admin/maintenance',
    schoolAlerts: '/admin/school-alerts',
    hubViews: {
      schoolCluster: '/school-community-group',
      detRegion: '/det-region',
      lga: '/lga',
      sedRegion: '/sed-region',
      fedRegion: '/fed-region'
    },
    transport: '/transport',
    isochrone: '/otp/isochrone'
  },
  assetsFolder: 'assets/images/icons/',
  tileyNextServer: 'https://tiley.education.nsw.gov.au',
  tileyNextApiKey: '9e265a4f-2043-4061-a0a7-4431d59b68d2',
  zhuriUrl: 'https://zhuri.education.nsw.gov.au',
  waspUrl: 'https://wasp.education.nsw.gov.au',
  azureBlobImageContainerUrl:
    'https://eagleeyestorageprod.z8.web.core.windows.net/blob-images',
  tReignProjectUrl: 'https://amdapps.det.nsw.edu.au/fcrs/project',
  visionSnapshotTemplateId: 3
};
