import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { PlannedAreaAnnotation } from '../../interfaces/planned-area-annotation';
import { GapApiCommsService } from '../../services/gap-api-comms/gap-api-comms.service';

@Injectable({
  providedIn: 'root'
})
export class PlannedAreaAnnotationResolverService
  implements Resolve<PlannedAreaAnnotation[]>
{
  private plannedAreasAnnotationsSubject: BehaviorSubject<
    PlannedAreaAnnotation[]
  >;

  constructor(private gapApiComms: GapApiCommsService) {
    this.plannedAreasAnnotationsSubject = new BehaviorSubject<
      PlannedAreaAnnotation[]
    >(null);
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<PlannedAreaAnnotation[]> {
    return this.gapApiComms.getPlannedAreaAnnotation(route.params.paId).pipe(
      tap(data => {
        this.plannedAreasAnnotationsSubject.next(data);
      }),
      catchError(error => {
        console.log(error);
        return new Observable<PlannedAreaAnnotation[]>(null);
      })
    );
  }

  getSubject(): BehaviorSubject<PlannedAreaAnnotation[]> {
    return this.plannedAreasAnnotationsSubject;
  }
}
