export enum TransportTripModeClass {
  TRAIN = 1,
  METRO = 2,
  LIGHT_RAIL = 4,
  BUS = 5,
  COACH = 7,
  FERRY = 9,
  SCHOOL_BUS = 11,
  WALKING_A = 99,
  WALKING_B = 100,
  TAXI = 1000
}
