import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MapDataImportUploadDialogComponent } from '../../components/map-data-import-upload-dialog/map-data-import-upload-dialog.component';
import { MapDataImportService } from '../map-data-import/map-data-import.service';

@Injectable({
  providedIn: 'root'
})
export class MapDataImportUploadDialogService {
  constructor(
    public dialog: MatDialog,
    private mapDataImportService: MapDataImportService
  ) {}

  showDialog() {
    const dialogRef = this.dialog.open(MapDataImportUploadDialogComponent, {
      width: '340px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.mapDataImportService.showDialog(result);
      }
    });
  }
}
