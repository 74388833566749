import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapStatsService {
  private visible = false;
  private visibleSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(this.visible);

  constructor() {}

  toggleVisibility(AdminEditLayerComponent: boolean = false) {
    if (AdminEditLayerComponent) {
      this.visible = true;
    } else {
      this.visible = !this.visible;
    }
    this.visibleSubject.next(this.visible);
  }

  resetVisibility() {
    this.visible = false;
    this.visibleSubject.next(this.visible);
  }

  onVisibilityChange() {
    return this.visibleSubject;
  }
}
