<div class="map-panel" fxLayout="row" *ngIf="visible">
  <mat-icon
    fontSet="fas"
    fontIcon="fa-info-circle"
    class="info-tooltip reachability"
    eeTooltip
    [tooltipText]="reachabilityTooltipText"
    tooltipClass="school-view-info-tooltip"
  ></mat-icon>
  <mat-tab-group class="measure-panel">
    <mat-tab label="Reachability">
      <div class="tab-content" [perfectScrollbar]>
        <div class="data">
          <div class="data-row" fxLayout="column">
            <div fxLayout="row" class="data-row">
              <!--              <mat-form-field class="field measure-panel timing">-->
              <!--                <mat-select [(value)]="mapObject?.mapReachabilityObject?.otpOptions.timing"-->
              <!--                            (selectionChange)="updateOtpOptions()">-->
              <!--                  <mat-option [value]="OtpTimingOptionsEnum.ARRIVING">Arriving</mat-option>-->
              <!--                  <mat-option [value]="OtpTimingOptionsEnum.DEPARTING">Departing</mat-option>-->
              <!--                </mat-select>-->
              <!--              </mat-form-field>-->

              <mat-form-field class="field measure-panel period">
                <mat-select
                  [(value)]="
                    mapObject?.mapReachabilityObject?.otpOptions.period
                  "
                  (selectionChange)="updateOtpOptions()"
                >
                  <mat-option [value]="OtpTripPeriodEnum.AM">AM</mat-option>
                  <mat-option [value]="OtpTripPeriodEnum.PM">PM</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxLayout="row" class="data-row">
              <mat-form-field class="field measure-panel mode">
                <mat-select
                  multiple
                  panelClass="measure-panel-modes"
                  [(ngModel)]="mapObject?.mapReachabilityObject.otpOptions.mode"
                  (selectionChange)="updateOtpOptions()"
                >
                  <mat-option
                    *ngFor="let mode of reachabilityModeOptions"
                    [value]="mode.value"
                    [disabled]="mode.disabled"
                  >
                    <img *ngIf="mode.image" [src]="mode.image" />
                    <mat-icon
                      *ngIf="mode.icon"
                      fontSet="far"
                      [fontIcon]="mode.icon"
                    ></mat-icon>
                    <span>{{ mode.label }}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" class="data-row">
              <div class="otp-value">
                {{
                  mapObject?.mapReachabilityObject?.otpOptions.time
                    ? mapObject.mapReachabilityObject.otpOptions.time
                    : '-'
                }}
                min
              </div>
              <mat-slider
                [(value)]="mapObject?.mapReachabilityObject?.otpOptions.time"
                [min]="5"
                [max]="45"
                [step]="5"
                class="otp-slider"
                (change)="updateOtpOptions()"
              ></mat-slider>
            </div>
            <div fxLayout="row" class="data-row">
              <div class="legend" fxLayout="row">
                <div
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  class="tile color-1"
                  (click)="setTime(5)"
                  [ngClass]="{
                    enabled:
                      mapObject?.mapReachabilityObject?.otpOptions.time > 0
                  }"
                >
                  5
                </div>
                <div
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  class="tile color-2"
                  (click)="setTime(10)"
                  [ngClass]="{
                    enabled:
                      mapObject?.mapReachabilityObject?.otpOptions.time > 5
                  }"
                >
                  10
                </div>
                <div
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  class="tile color-3"
                  (click)="setTime(15)"
                  [ngClass]="{
                    enabled:
                      mapObject?.mapReachabilityObject?.otpOptions.time > 10
                  }"
                >
                  15
                </div>
                <div
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  class="tile color-4"
                  (click)="setTime(20)"
                  [ngClass]="{
                    enabled:
                      mapObject?.mapReachabilityObject?.otpOptions.time > 15
                  }"
                >
                  20
                </div>
                <div
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  class="tile color-5"
                  (click)="setTime(30)"
                  [ngClass]="{
                    enabled:
                      mapObject?.mapReachabilityObject?.otpOptions.time > 20
                  }"
                >
                  30
                </div>
                <div
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  class="tile color-6"
                  (click)="setTime(45)"
                  [ngClass]="{
                    enabled:
                      mapObject?.mapReachabilityObject?.otpOptions.time > 30
                  }"
                >
                  45
                </div>
              </div>
            </div>
            <div
              fxLayout="row"
              class="data-row extra-margin fail-message"
              *ngIf="showNoResultMessage"
            >
              <mat-icon
                fontSet="fas"
                fontIcon="fa-exclamation-triangle"
              ></mat-icon>
              <span>There are no results available.</span>
            </div>
            <div fxLayout="row" class="data-row" *ngIf="hasResult">
              <mat-accordion>
                <mat-expansion-panel
                  [disabled]="!hasResult"
                  [(expanded)]="analysisExpanded"
                  (afterExpand)="updateAnalysis()"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Breakdown Analysis
                      <mat-icon
                        fontSet="fas"
                        fontIcon="fa-info-circle"
                        class="info-tooltip analysis"
                        eeTooltip
                        [tooltipText]="analysisTooltipText"
                        tooltipClass="school-view-info-tooltip"
                      ></mat-icon>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div>
                    <div>
                      <mat-form-field class="field measure-panel grades">
                        <mat-select
                          multiple
                          [(ngModel)]="selectedReachabilityGrades"
                          (selectionChange)="updateReachabilityAnalysisChart()"
                        >
                          <mat-option
                            *ngFor="let grade of reachabilityGrades"
                            [value]="grade"
                          >
                            <span>{{ grade }}</span>
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <div fxFlex></div>

                      <button
                        mat-icon-button
                        class="action-button"
                        [matMenuTriggerFor]="reachabilityExportMenu"
                        *ngIf="hasResult"
                        eeTooltip
                        tooltipText="Export"
                      >
                        <mat-icon
                          fontSet="fas"
                          fontIcon="fa-download"
                        ></mat-icon>
                      </button>

                      <mat-menu #reachabilityExportMenu class="gap-menu">
                        <button
                          mat-menu-item
                          (click)="exportReachabilityAnalysisChart()"
                        >
                          <mat-icon
                            fontSet="far"
                            fontIcon="fa-file-chart-line"
                          ></mat-icon>
                          <span>Export Chart</span>
                        </button>
                        <button
                          mat-menu-item
                          (click)="exportReachabilityAnalysisData()"
                        >
                          <mat-icon
                            fontSet="far"
                            fontIcon="fa-file-excel"
                          ></mat-icon>
                          <span>Export Spreadsheet</span>
                        </button>
                      </mat-menu>
                    </div>
                    <div fxLayout="column" class="data-row">
                      <div
                        id="reachability-analysis-chart"
                        class="reachability-chart"
                        *ngIf="hasResult"
                      ></div>
                      <div *ngIf="!hasResult" class="no-data">
                        No data available
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div fxLayout="row" fxLayoutAlign="end end" class="data-row">
              <button
                mat-button
                class="simple-blue-button"
                (click)="updateOtpResults()"
                [disabled]="
                  !mapObject?.mapReachabilityObject?.currentLocation ||
                  !otpOptionsHasChanged
                "
              >
                UPDATE
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div fxLayout="column" class="icon-container">
    <button mat-icon-button (click)="close()" class="close">
      <mat-icon fontSet="fal" fontIcon="fa-times"></mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="close()"
      eeTooltip
      tooltipText="Delete measurement"
      tooltipClass="toolbar-tooltips"
      [tooltipDirection]="'left'"
    >
      <mat-icon fontSet="fal" fontIcon="fa-trash-alt"></mat-icon>
    </button>
    <button
      mat-icon-button
      [disabled]="!hasResult"
      [matMenuTriggerFor]="reachabilityDownloadMenu"
      eeTooltip
      tooltipText="Download Geometry"
      tooltipClass="toolbar-tooltips"
      [tooltipDirection]="'left'"
    >
      <mat-icon fontSet="fal" fontIcon="fa-download"></mat-icon>
    </button>

    <mat-menu #reachabilityDownloadMenu="matMenu" class="user-menu">
      <button mat-menu-item (click)="downloadGeoJson()">
        <mat-icon fontSet="far" fontIcon="fa-file-alt"></mat-icon>
        <span>GeoJSON</span>
      </button>
      <button mat-menu-item (click)="downloadKml()">
        <mat-icon fontSet="far" fontIcon="fa-file-code"></mat-icon>
        <span>KML</span>
      </button>
    </mat-menu>

    <button
      mat-icon-button
      (click)="analyseInHub()"
      *ngIf="mapObject?.mapReachabilityObject?.currentLocation"
      eeTooltip
      tooltipText="Analyse in HUB"
      tooltipClass="toolbar-tooltips"
      [tooltipDirection]="'left'"
    >
      <mat-icon fontSet="fal" fontIcon="fa-magic"></mat-icon>
    </button>
  </div>
</div>
