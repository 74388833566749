<div
  class="map-container"
  [ngClass]="{ fullscreen: mapObject && mapObject.isFullscreen }"
>
  <ee-map
    [class]="'map' + (extraClass ? ' ' + extraClass : '')"
    [basemapName]="basemapName"
    [mapId]="mapId"
    [mapControlsEnabled]="mapControlsEnabled"
    (createCallback)="onMapCreate($event)"
    [ngClass]="{ 'street-view-mode': streetViewEnabled }"
  ></ee-map>
  <ee-street-view
    class="street-view"
    [streetViewId]="mapId + '-sv'"
    (createCallback)="onStreetViewCreated($event)"
    [ngClass]="{ visible: streetViewEnabled }"
  ></ee-street-view>

  <ee-map-toolbar
    [class]="'toolbar' + (extraClass ? ' ' + extraClass : '')"
    [mapObject]="mapObject"
    *ngIf="mapToolbarEnabled"
    [toolbarOptions]="toolbarOptions"
    [streetViewObject]="streetViewObject"
    (toolClick)="onToolClickEvent($event)"
  ></ee-map-toolbar>
  <ee-map-search
    class="search"
    [extraClass]="extraClass"
    [mapObject]="mapObject"
    *ngIf="mapSearchBarEnabled"
  ></ee-map-search>
  <ee-map-advanced-search
    class="advanced-search"
    *ngIf="mapSearchBarEnabled"
  ></ee-map-advanced-search>
  <ee-map-measure-panel
    class="measure-panel"
    [mapObject]="mapObject"
  ></ee-map-measure-panel>
  <ee-map-inspector-panel
    class="inspector-panel"
    [mapObject]="mapObject"
  ></ee-map-inspector-panel>
  <ee-map-reachability-panel
    class="reachability-panel"
    [mapObject]="mapObject"
  ></ee-map-reachability-panel>
  <ee-instructions
    class="instructions-panel"
    [ngClass]="{ 'instructions-panel-above-street-view': streetViewEnabled }"
  ></ee-instructions>
  <ee-map-legend
    [class]="'map-legend' + (extraClass ? ' ' + extraClass : '')"
    *ngIf="mapLegendEnabled"
    [mapObject]="mapObject"
    [mapLegendTooltipEnabled]="mapLegendTooltipEnabled"
    [mapId]="mapId"
    [ngClass]="{ 'map-legend-above-street-view': streetViewEnabled }"
  ></ee-map-legend>
  <ee-map-date-selector
    [class]="'map-date-selector'"
    [mapObject]="mapObject"
    *ngIf="mapDateSelectorEnabled"
  ></ee-map-date-selector>
  <mat-progress-spinner
    class="map-loading"
    mode="indeterminate"
    *ngIf="
      mapObject && mapObject.layersLoading && mapObject.layersLoading.length > 0
    "
  ></mat-progress-spinner>
</div>
