<div>
  <div class="sv-container" [hidden]="!imageAvailable">
    <div [id]="streetViewId" class="sv-image"></div>
  </div>
  <div
    class="no-image-available"
    [ngClass]="{ hide: imageAvailable }"
    fxFlex
    fxLayoutAlign="center center"
  >
    There is no image available at this location.
  </div>
</div>
